import React from "react";

export interface InputInterface {
  type: "text" | "email" | "password";
  label: string;
  id: string;
  placeholder: string;
  name: string; // name has to be the same as formik value key
  value: string;
  onChange: (e: React.ChangeEvent) => void;
  error: string;
}

interface InputProps {
  item: InputInterface;
  className?: any;
}
function Input({ item, className }: InputProps) {
  const { type, label, name, value, id, onChange, error, placeholder } = item;
  return (
    <div className={className}>
      <label htmlFor={id}>{label}</label>
      <input
        type={type}
        placeholder={placeholder}
        name={name}
        id={id}
        value={value}
        onChange={onChange}
      />
      <p className="error">{error}</p>
    </div>
  );
}

export default Input;
