import React from "react";
import manyicon from "../../../assets/images/home/many-icon.png";
import tryicon from "../../../assets/images/home/try-icon.png";
import duel from "../../../assets/images/home/duel.png";
import tournament from "../../../assets/images/home/tournament.png";
import newtournament from "../../../assets/images/home/new-tournament.png";
import MobileAppLink from "./MobileAppLink";
import "../../home/home.css";

interface Content {
  game2: string;
  para2: string;
}

interface PopularMobileGameBoxProps {
  index: string;
  imageSrc: string;
  count: string;
  content: Content;
  playstoreURL: string;
  appstoreURL: string;
}

function PopularMobileGameBox({ index, imageSrc, count = "100", content, playstoreURL, appstoreURL }: PopularMobileGameBoxProps) {
  return (
    <div className={`col-md-3 popular-${index}`}>
      <div className="popular-box">
        <div className="game-img">
          <img
            src={imageSrc}
            alt="pool"
          />
          <div className="try trynew">
            <img
              src={tryicon}
              alt=""
            />
            <MobileAppLink
              playstoreURL={playstoreURL}
              appstoreURL={appstoreURL}
            />
          </div>
        </div>
        <div className="many-players">
          <img
            src={manyicon}
            alt=""
          />
          {count}
        </div>

        <div className="game-action mobile-app-tile">
          <div className="duel-action newduel-action">
            <img
              src={duel}
              alt=""
            />
            <MobileAppLink
              playstoreURL={playstoreURL}
              appstoreURL={appstoreURL}
            />
          </div>
          <div className="tournament-action newtournament">
            <img
              src={tournament}
              alt=""
            />
            <MobileAppLink
              playstoreURL={playstoreURL}
              appstoreURL={appstoreURL}
            />
          </div>
          <div className="newtournament-action">
            <img
              src={newtournament}
              alt=""
            />
            <MobileAppLink
              playstoreURL={playstoreURL}
              appstoreURL={appstoreURL}
            />
          </div>
        </div>

        <div className="game-info">
          <h4>{content && content.game2}</h4>
          <p>{content && content.para2}</p>
        </div>
      </div>
    </div>
  );
}

export default PopularMobileGameBox;
