import React from "react";
import Header from "../header/header";
import Footer from "../footer/footer";

export default function Layout({ children }) {
  return (
    <div className="main-wrapper">
      <Header />
      <main>
        <div className="main_content">{children}</div>
      </main>
      <Footer />
    </div>
  );
}
