/* eslint-disable no-return-assign */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import Modal from "react-bootstrap/Modal";
import footerlogo from "../../assets/images/header/footer-logonew.png";
import payment from "../../assets/images/home/payment.png";
import chat from "../../assets/images/home/chat-icon.png";
import UserAdminChat from "../userchat/userchat";
import { cmsInstance } from "../../axios/axiosconfig";

import appstore from "../../assets/images/header/app-store.svg";
import googleplay from "../../assets/images/header/google-play.svg";

import sponser1 from "../../assets/images/sponsers/alocha.png";
import sponser2 from "../../assets/images/sponsers/cb.png";
import sponser3 from "../../assets/images/sponsers/ex.png";
import sponser4 from "../../assets/images/sponsers/fly.png";
import sponser5 from "../../assets/images/sponsers/gg.png";
import sponser6 from "../../assets/images/sponsers/itp.png";
import sponser7 from "../../assets/images/sponsers/pp.png";
import sponser8 from "../../assets/images/sponsers/star.png";
import { server } from "../../config/config";

function Footer() {
  const { i18n } = useTranslation();
  const [pageContent, setPageContent] = useState({});

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getContent = async () => {
    try {
      // const page = window.location.pathname.replace('/cms/', '');
      const page = "/footer";
      const { language } = i18n;
      const {
        data: { content, msg },
        status,
      } = await cmsInstance.get(`/content${page}/${language}`);

      if (status === 200) {
        setPageContent(content);
      } else {
        NotificationManager.error(msg, "Message", 10000);
      }
    } catch (error) {
      console.error("Error", error.message);
    }
  };

  const onLanguageChanged = () => {
    getContent();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getContent();
    i18n.on("languageChanged", onLanguageChanged);
  }, []);

  // const settings = {
  //   dots: true,
  //   infinite: false,
  //   speed: 500,
  //   autoplay: true,
  //   slidesToShow: 6,
  //   slidesToScroll: 6,
  //   initialSlide: 0,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 5,
  //         slidesToScroll: 5,
  //         infinite: true,
  //         dots: true,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3,
  //         initialSlide: 3,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //       },
  //     },
  //   ],
  // };

  return (
    <footer>
      <div className="sponsors-container">
        <div className="container">
          <div className="sponsors-box">
            <Slider>
              <div className="sponser-item">
                <img
                  src={sponser1}
                  alt="Sponser"
                />
              </div>
              <div className="sponser-item">
                <img
                  src={sponser2}
                  alt="Sponser"
                />
              </div>
              <div className="sponser-item">
                <img
                  src={sponser3}
                  alt="Sponser"
                />
              </div>
              <div className="sponser-item">
                <img
                  src={sponser4}
                  alt="Sponser"
                />
              </div>
              <div className="sponser-item">
                <img
                  src={sponser5}
                  alt="Sponser"
                />
              </div>
              <div className="sponser-item">
                <img
                  src={sponser7}
                  alt="Sponser"
                />
              </div>
              <div className="sponser-item">
                <img
                  src={sponser6}
                  alt="Sponser"
                />
              </div>
              <div className="sponser-item">
                <img
                  src={sponser8}
                  alt="Sponser"
                />
              </div>
            </Slider>
          </div>
        </div>
      </div>

      <div className="top-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="footer-one">
                <img
                  src={footerlogo}
                  alt=""
                />
                <p>{pageContent.text1}</p>

                <div className="play-store">
                  <ul>
                    <a href="https://testflight.apple.com/join/URzIgwRg">
                      <img
                        src={appstore}
                        alt=""
                      />
                    </a>
                    <a href={`${server}/user/appDownload`}>
                      <img
                        src={googleplay}
                        alt=""
                      />
                    </a>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <h4>{pageContent.socialmedia}</h4>
              <ul className="footer-list">
                <li>
                  <a
                    href="https://www.facebook.com/Day-of-Duel-101086868042585"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {pageContent.link1}
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/dayofduel"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {pageContent.link2}
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/dayofduel/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {pageContent.link3}
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCnpovMBD9Y8iagQw84R0_ng"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {pageContent.link4}
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-md-2">
              <h4>{pageContent.howto}</h4>
              <ul className="footer-list">
                <li>
                  <Link to="/">{pageContent.link5}</Link>
                </li>
                <li>
                  <Link to="/tournament">{pageContent.link6}</Link>
                </li>
                <li>
                  <Link to="/faqs">{pageContent.link7}</Link>
                </li>
                <li>
                  <Link to="/help">{pageContent.link8}</Link>
                </li>
                <li>
                  <Link to="/payment">{pageContent.link9}</Link>
                </li>
              </ul>
            </div>

            <div className="col-md-2">
              <h4>{pageContent.legalnotes}</h4>
              <ul className="footer-list">
                <li>
                  <Link to="/legal">{pageContent.link10}</Link>
                </li>
                <li>
                  <Link to="/terms">{pageContent.link11}</Link>
                </li>
                <li>
                  <Link to="/privacy">{pageContent.link12}</Link>
                </li>
                <li>
                  <Link to="/transactionsecurity">{pageContent.link13}</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-2">
              <h4>{pageContent.about}</h4>
              <ul className="footer-list">
                <li>
                  <Link to="/about">{pageContent.link14}</Link>
                </li>
                <li>
                  <Link to="/ourteam">{pageContent.link15}</Link>
                </li>
                <li>
                  <Link to="/ourvision">{pageContent.link16}</Link>
                </li>
                <li>
                  <Link to="/responsiblegaming">{pageContent.link17}</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="copy-right">
                {/* <span>{new Date().toLocaleString()}</span> */}
                {/* <p>{content.copyright")}</p> */}
                <p>{pageContent.copyright}</p>
                <img
                  className="payment"
                  src={payment}
                  alt="Payment"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {localStorage.getItem("token") && (
        <button
          type="button"
          className="chat-icon"
          onClick={handleShow}
        >
          <img
            src={chat}
            alt="Chat"
          />
        </button>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="dod-popup chatbox-popup"
        backdropClassName="chatbox-overlay"
      >
        <Modal.Body>
          <Modal.Header closeButton />
          <UserAdminChat />
        </Modal.Body>
      </Modal>
    </footer>
  );
}

export default Footer;
