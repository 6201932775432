enum Routes {
  Home = "/",
  Login = "/login",
  Profile = "/profile",
  NotificationPage = "/notificationPage",
  Transactions = "/transactions",
  Kyc = "/kyc",
  CreateTournament = "/createtournament",
  HowItWorks = "/howitworks",
  Tournament = "/tournament",
  SeriesGame = "/seriesGame",
}

export default Routes;
