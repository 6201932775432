import io from "socket.io-client";
import { server } from "./config";

const token = localStorage.getItem("token");
const socket = io(server, {
  transports: ["websocket"],
  rejectUnauthorized: false,
  query: `token=${token}`,
});

export default socket;
