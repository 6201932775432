import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./withdraw.css";
import { NotificationManager } from "react-notifications";
import { Form, Button, InputGroup } from "react-bootstrap";
import { userInstance, cmsInstance } from "../../axios/axiosconfig";
import { convertAmountToOtherCurrencies } from "../../function/function";
import Routes from "../../config/routes";

function WithdrawMoney({ currency }) {
  const history = useHistory();
  const [amount, setAmount] = useState(null);
  const [wallet, SetWallet] = useState(null);
  const [walletType, setWalletType] = useState("");
  const [kycstatus, setKycStatus] = useState(false);
  const [upload, setUpload] = useState(false);
  const [pageContent, setPageContent] = useState({});

  const getContent = async () => {
    try {
      // const page = window.location.pathname.replace('/cms/', '');
      const page = "/withdrawmoney";
      const language = localStorage.getItem("activeLanguage") ? localStorage.getItem("activeLanguage") : localStorage.getItem("i18nextLng");
      const {
        data: { content, msg },
        status,
      } = await cmsInstance.get(`/content${page}/${language}`);
      if (status === 200) {
        setPageContent(content);
      } else {
        NotificationManager.error(msg, "Message", 10000);
      }
    } catch (error) {
      console.error("Error", error.message);
    }
  };

  useEffect(() => {
    const getUserProfile = async () => {
      const response = await userInstance.get("/getprofile");
      if (response.data.code === 200) {
        const res = await userInstance.get(`/getKyc/${response.data.profile_data._id}`);

        if (res.data.code === 200) {
          setKycStatus(res.data.data.status);
          setUpload(true);
        } else {
          setUpload(false);
        }
        if (response.data.profile_data.deposit[0].isActive) {
          setWalletType("deposit");
          SetWallet(response.data.profile_data.deposit[0].USD);
        } else {
          SetWallet(response.data.profile_data.bonus[0].USD);
          setWalletType("bonus");
        }
      }
    };
    getUserProfile();
    getContent();
  }, []);

  const payment = async (e) => {
    e.preventDefault();
    const result = await convertAmountToOtherCurrencies(amount, currency);
    if (wallet < result || result === null) {
      return NotificationManager.error(pageContent.notification1, "Message", 5000);
    }
    if (walletType === "bonus" && wallet >= result && result > 0) {
      history.push(Routes.SeriesGame);
    } else {
      const payload = {
        amount: parseFloat(result.toFixed(2)),
      };
      const res = await userInstance.post("/requestWithdrawal", payload);

      if (res.data.code === 200) {
        NotificationManager.success(pageContent.notification3, "Message", 5000);
        setAmount("");
      } else {
        NotificationManager.error(pageContent.notification2, "Message", 5000);
        setAmount("");
      }
    }
  };
  const handlechange = (e) => {
    setAmount(e.target.value);
  };
  return (
    <div className="withdraw">
      <div className="container">
        <div className="withdraw-content">
          <h2>{pageContent.withdraw_money}</h2>
          {upload ? (
            kycstatus ? (
              <Form onSubmit={payment}>
                <Form.Group>
                  <Form.Label htmlFor="Amount">{pageContent.Amount}</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="number"
                      autocomplete="off"
                      className="withdraw-in"
                      defaultValue={amount}
                      id="Amount"
                      onChange={handlechange}
                      min={1}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text id="basic-addon2">{currency}</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>

                  <div className="text-center">
                    <Button
                      type="submit"
                      className="red-btn"
                    >
                      {pageContent.Withdraw}
                    </Button>
                  </div>
                </Form.Group>
              </Form>
            ) : (
              <h5 className="kyc-text">{pageContent.status1}</h5>
            )
          ) : (
            <h5 className="kyc-text">{pageContent.status2}</h5>
          )}
        </div>
      </div>
    </div>
  );
}

export default WithdrawMoney;
