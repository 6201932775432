import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import { NotificationManager } from "react-notifications";
import countryList from "react-select-country-list";
import { Alert, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { userInstance } from "../../axios/axiosconfig";
import Google from "../google";
import Facebook from "../facebook";
import { getPageContent } from "../../helpers/content";
import SelectInputStyled from "../common/formComponents/selectInput/SelectInput.style";
import CheckBoxStyled from "../common/formComponents/checkBox/CheckBox.style";
import ButtonStyled from "../common/button/Button.style";
import RegistrationSuccess from "../registrationSuccess/registrationSuccess";
import InputStyled from "../common/formComponents/input/Input.style";
import { InputInterface } from "../common/formComponents/input/Input";
import { SelectInterface } from "../common/formComponents/selectInput/SelectInput";
import { CheckBoxInterface } from "../common/formComponents/checkBox/CheckBox";
import PageContent from "./pageContent.type";

interface SignupFormProps {
  className?: string;
}

export default function SignupForm({ className }: SignupFormProps) {
  const [pageContent, setPageContent] = useState<PageContent>();
  const [registrationSuccessShow, setRegistrationSuccessShow] = useState(false);
  const { i18n } = useTranslation();

  const onLanguageChanged = () => {
    const page = "/signup";
    getPageContent(i18n.language, page).then((data) => setPageContent(data));
  };

  const handleRegistrationSuccessClose = () => {
    setRegistrationSuccessShow(false);
    window.location.href = "/";
  };

  const onFormSubmit = async (values: any) => {
    const response = await userInstance.post("/signup", values);
    const statusCode = response.data.code;
    if (statusCode === 200) {
      const v = document.getElementById("scroll");
      v?.scrollIntoView();
      if (window.location.pathname !== "/") {
        setRegistrationSuccessShow(true);
      }
      return;
    }
    if (statusCode === 400) {
      console.error(pageContent?.notification2);
    }
    if (statusCode === 500) {
      NotificationManager.error(pageContent?.notification1, "Message", 10000);
    }
  };

  const passwordRegExpString = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8})";
  const passwordRegExp = new RegExp(passwordRegExpString);
  const yupRegisterPasswordValidation = Yup.string().required(pageContent?.errPassword1).min(8, pageContent?.errPassword).max(24, pageContent?.errPassword).matches(passwordRegExp, pageContent?.errPassword);

  const validationSchema = Yup.object().shape({
    nickName: Yup.string().min(3, pageContent?.errName).required(pageContent?.errName),
    firstName: Yup.string().min(3, pageContent?.gamerErr).required(pageContent?.errFirstName),
    password: yupRegisterPasswordValidation,
    repeatPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required(pageContent?.errConf),
    email: Yup.string().email(pageContent?.errEmail1).required(pageContent?.errEmail2),
    country: Yup.string().required(pageContent?.errCountry),
    terms1: Yup.boolean().isTrue(pageContent?.errTerm),
    terms2: Yup.boolean().isTrue(pageContent?.errTerm),
    terms3: Yup.boolean().isTrue(pageContent?.errTerm),
    terms4: Yup.boolean().isTrue(pageContent?.errTerm),
  });

  const formik = useFormik({
    initialValues: {
      nickName: "",
      firstName: "",
      password: "",
      repeatPassword: "",
      email: "",
      country: "",
      terms1: false,
      terms2: false,
      terms3: false,
      terms4: false,
      inviter: "",
      msg: "",
      msg_status: false,
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      onFormSubmit(values);
    },
  });

  const setInviter = (inviter: string | null | (string | null)[]) => {
    if (!inviter) return;
    if (typeof inviter === "string") return formik.setFieldValue("inviter", inviter);
    inviter.forEach((item) => {
      if (item) {
        return formik.setFieldValue("inviter", item);
      }
    });
  };

  useEffect(() => {
    const inviter = queryString.parse(window.location.search);
    const page = "/signup";
    setInviter(inviter.ref);
    getPageContent(i18n.language, page).then((data) => setPageContent(data));
    i18n.on("languageChanged", onLanguageChanged);
  }, []);

  const formInputs: (InputInterface | SelectInterface | CheckBoxInterface)[] = [
    {
      type: "text",
      label: pageContent?.gamename || "",
      id: "signup-gamename",
      placeholder: pageContent?.gamenameplace || "",
      name: "nickName",
      value: formik.values.nickName,
      onChange: formik.handleChange,
      error: formik.errors.nickName || "",
    },
    {
      type: "text",
      label: pageContent?.name || "",
      id: "signup-name",
      placeholder: pageContent?.nameplace || "",
      name: "firstName",
      value: formik.values.firstName,
      onChange: formik.handleChange,
      error: formik.errors.firstName || "",
    },
    {
      type: "password",
      label: pageContent?.password || "",
      id: "signup-password",
      placeholder: pageContent?.passwordplace || "",
      name: "password",
      value: formik.values.password,
      onChange: formik.handleChange,
      error: formik.errors.password || "",
    },
    {
      type: "password",
      label: pageContent?.confirmpassword || "",
      id: "signup-confirmpassword",
      placeholder: pageContent?.confirmplace || "",
      name: "repeatPassword",
      value: formik.values.repeatPassword,
      onChange: formik.handleChange,
      error: formik.errors.repeatPassword || "",
    },
    {
      type: "email",
      label: pageContent?.email || "",
      id: "signup-email",
      placeholder: pageContent?.emailplace || "",
      name: "email",
      value: formik.values.email,
      onChange: formik.handleChange,
      error: formik.errors.email || "",
    },
    {
      type: "select",
      label: pageContent?.country || "",
      id: "signup-country",
      placeholder: "",
      name: "country",
      value: formik.values.country,
      onChange: (option) => formik.setFieldValue("country", option.value),
      options: countryList().getData(),
      error: formik.errors.country || "",
    },
    {
      type: "checkbox",
      label: pageContent?.para1 || "",
      id: "signup-terms1",
      name: "terms1",
      value: formik.values.terms1,
      onChange: formik.handleChange,
      error: formik.errors.terms1 || "",
    },
    {
      type: "checkbox",
      label: pageContent?.para2 || "",
      id: "signup-terms2",
      name: "terms2",
      value: formik.values.terms2,
      onChange: formik.handleChange,
      error: formik.errors.terms2 || "",
    },
    {
      type: "checkbox",
      label: pageContent?.para3 || "",
      id: "signup-terms3",
      name: "terms3",
      value: formik.values.terms3,
      onChange: formik.handleChange,
      error: formik.errors.terms3 || "",
    },
    {
      type: "checkbox",
      label: pageContent?.para6 || "",
      id: "signup-terms4",
      name: "terms4",
      value: formik.values.terms4,
      onChange: formik.handleChange,
      error: formik.errors.terms4 || "",
    },
  ];

  const renderFormField = (input: InputInterface | SelectInterface | CheckBoxInterface) => {
    if (input.type === "select")
      return (
        <SelectInputStyled
          item={input}
          key={input.id}
        />
      );
    if (input.type === "checkbox")
      return (
        <CheckBoxStyled
          item={input}
          key={input.id}
        />
      );
    return (
      <InputStyled
        item={input}
        key={input.id}
      />
    );
  };

  if (!pageContent) return <div>Not found</div>;
  return (
    <div className={className}>
      <h1>{pageContent.getaccount}</h1>
      {formik.values.msg_status && (
        <Alert variant="success">
          <strong id="sign">{pageContent.success}</strong>
          {formik.values.msg}
        </Alert>
      )}
      <div className="social-login">
        <span className="loginBtn loginBtn--facebook">
          <Facebook btntext={pageContent.fbbtntext} />
        </span>
        <span className="loginBtn loginBtn--google">
          <Google btnText={pageContent.ggbtntext} />
        </span>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-row">{formInputs.slice(0, 2).map((input) => renderFormField(input))}</div>
        <div className="form-row">{formInputs.slice(2, 4).map((input) => renderFormField(input))}</div>
        <div className="form-row">{formInputs.slice(4, 6).map((input) => renderFormField(input))}</div>
        <div className="form-checkboxes">{formInputs.slice(6, 10).map((input) => renderFormField(input))}</div>
        <ButtonStyled
          type="submit"
          label={pageContent.sign_up}
        />
      </form>
      <Modal
        dialogClassName="dod-popup"
        show={registrationSuccessShow}
        onHide={handleRegistrationSuccessClose}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <RegistrationSuccess handleRegistrationSuccessClose={handleRegistrationSuccessClose} />
        </Modal.Body>
      </Modal>
    </div>
  );
}
