import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import Layout from "../layout";
import mac from "../../assets/images/home/mac.png";
import playstation from "../../assets/images/home/playstation.png";
import { cmsInstance } from "../../axios/axiosconfig";
import ContentBox from "../common/ContentBox";

function OurTeam() {
  const { i18n } = useTranslation();
  const [pageContent, setPageContent] = useState({});

  const getContent = async () => {
    try {
      const page = "/ourteam";
      const { language } = i18n;
      const {
        data: { content, msg },
        status,
      } = await cmsInstance.get(`/content${page}/${language}`);
      if (status === 200) {
        setPageContent(content);
      } else {
        NotificationManager.error(msg, "Message", 10000);
      }
    } catch (error) {
      console.error("Error", error.message);
    }
  };

  const onLanguageChanged = () => {
    getContent();
  };
  useEffect(() => {
    getContent();
    i18n.on("languageChanged", onLanguageChanged);
  }, []);

  return (
    <Layout>
      <div className="default-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-page team-title">
                <ul className="breadcrum">
                  <li>
                    <Link to="/">{pageContent.home}</Link>
                  </li>
                  <li>{pageContent.ourteam}</li>
                </ul>
                <h2>{pageContent.ourteam}</h2>
              </div>
            </div>
          </div>

          <ContentBox
            header={pageContent.ourteam}
            paragraph={pageContent.para2}
            imageSrc={mac}
          />
          <ContentBox
            header={pageContent.ourteam}
            paragraph={pageContent.para3}
            imageSrc={playstation}
            reversed
          />
        </div>
      </div>
    </Layout>
  );
}

export default OurTeam;
