const baseTheme = {
  colors: {
    white: "#fff",
    red: "#e00033",
    lightGrey: "#d0d0d0",
    grey: "#999",
  },
  fonts: {
    poppins: "Poppins",
    roboto: "Roboto-Regular",
    robotoBold: "Roboto-Bold",
    molot: "Molot",
  },
  fontSizes: {
    button: "12px",
    input: "14px",
    h1: "35px",
    p: "13px",
  },
};

export default baseTheme;
