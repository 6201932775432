import React, { useEffect, useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { NotificationManager } from "react-notifications";
import { getParamByParam } from "iso-country-currency";
import { useTranslation } from "react-i18next";
import { Badge, Dropdown, Modal } from "react-bootstrap";
import "./header.css";
import logo from "../../assets/images/header/logonew.png";
import money from "../../assets/images/header/add-money-icon.png";
import { convertToOtherCurrencies } from "../../function/function";
import { userInstance, cmsInstance } from "../../axios/axiosconfig";
import LogoutButton from "./LogoutButton";
import staricon from "../../assets/images/header/star-icon.png";
import socket from "../../config/socket";
import Login from "../login/login";
import Signup from "../signup/Signup.style";
import WithdrawMoney from "../withdrawMoney/withdrawMoney";
import InviteFriends from "../choooseduel/invitefriends";
import Deposit from "../deposit/deposit";
import ChangePassword from "../changepassword/changepassword";
import RegistrationSuccess from "../registrationSuccess/registrationSuccess";
import friend from "../../assets/images/home/user-profile.png";
import { server } from "../../config/config";
import Routes from "../../config/routes";

function Header() {
  const history = useHistory();
  const [pageContent, setPageContent] = useState({});
  const [user, setUser] = useState([]);
  const [pic, setPic] = useState(null);
  const [eligible, setEligible] = useState(false);
  const [deposit, setDeposit] = useState(false);
  const [unread, setUnread] = useState(null);
  const banned = null;
  const [depositAmount, setDepositAmount] = useState(null);
  const [bonusAmt, setBonusAmt] = useState(null);
  const [showButton, setShowButton] = useState(false);
  const [language, setlanguages] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [currencyConvert, setCurrencyConvert] = useState(0);
  const [bonusConvert, setBonusConvert] = useState(0);
  const { i18n } = useTranslation();

  const getContent = async () => {
    try {
      const page = "/header";
      const languageFromLocalStorage = localStorage.getItem("activeLanguage") ? localStorage.getItem("activeLanguage") : localStorage.getItem("i18nextLng");
      const {
        data: { content, msg },
        status,
      } = await cmsInstance.get(`/content${page}/${languageFromLocalStorage}`);
      if (status === 200) {
        setPageContent(content);
      } else {
        NotificationManager.error(msg, "Message", 10000);
      }
    } catch (error) {
      console.error("Error", error.message);
    }
  };

  const changeLanguage = (event) => {
    localStorage.setItem("activeLanguage", event);
    i18n.changeLanguage(event);
    getContent();
  };

  // const socket = io(server);
  const checkEligible = async () => {
    const res = await userInstance.get("/eligible");
    if (res.data.code === 200) {
      setEligible(true);
    } else if (res.data.code === 400) {
      localStorage.removeItem("token");
      localStorage.removeItem("Userid");
      localStorage.removeItem("nickName");
    }
  };
  const getUserProfile = async () => {
    const response = await userInstance.get("/getprofile");
    if (response.data.code === 200) {
      setPic(response.data.profile_data.photo);
      localStorage.setItem("photo", response.data.profile_data.photo);
      setShowButton(true);
      setUser(response.data.profile_data);
      setDepositAmount(response.data.profile_data.deposit[0].USD);
      setBonusAmt(response.data.profile_data.bonus[0].USD);
      setDeposit(response.data.profile_data.deposit[0].isActive);
      const currencyCode = getParamByParam("countryName", response.data.profile_data.country, "currency");
      setCurrency(currencyCode);
      localStorage.setItem("currency", currencyCode);
      const { result, bonusResult } = await convertToOtherCurrencies(response.data.profile_data.deposit[0].USD, response.data.profile_data.bonus[0].USD, currencyCode);
      setCurrencyConvert(result);
      setBonusConvert(bonusResult);
      // setBonus(response.data.profile_data.bonus[0].isActive);
      // setBanned(response.data.profile_data.isbanned);
      localStorage.setItem("nickName", response.data.profile_data.firstName);
      localStorage.setItem("Userid", response.data.profile_data._id);
    } else if (response.data.code === 400) {
      localStorage.removeItem("token");
      localStorage.removeItem("Userid");
      localStorage.removeItem("nickName");
    }
  };
  const getTotalLanguages = async () => {
    const res = await cmsInstance.get("/getlanguage");
    if (res.data.code === 200) {
      setlanguages(res.data.language);
      localStorage.setItem("activeLanguage", localStorage.getItem("i18nextLng"));
    }
  };
  const getReadStatus = async () => {
    const res = await userInstance.post("/readStatusUser");
    if (res.data.code === 200) {
      setUnread(!res.data.data);
    } else if (res.data.code === 400) {
      setShowButton(false);
      localStorage.removeItem("token");
      localStorage.removeItem("Userid");
      localStorage.removeItem("nickName");
    }
  };
  useEffect(() => {
    checkEligible();
    getTotalLanguages();
    getContent();
    getUserProfile();
    getReadStatus();
    localStorage.setItem("lastlocation", window.location.pathname);
  }, [showButton]);

  useEffect(() => {
    socket.on("tournamentFeeDeducted", (data) => {
      setDepositAmount(data.USD);
    });
    socket.on("betAmountDeposit", (data) => {
      setDepositAmount(data.USD);
    });
    socket.on("betAmountBonus", (data) => {
      setBonusAmt(data.USD);
    });
  }, [depositAmount]);

  const logout = async () => {
    const response = await userInstance.post("/logout");
    const statusCode = response.data.code;

    if (statusCode === 200) {
      localStorage.removeItem("token");
      localStorage.removeItem("Userid");
      localStorage.removeItem("nickName");
      NotificationManager.success(pageContent.notification3, "Message", 4000);
      setTimeout(() => {
        history.push(Routes.Home);
      }, 1000);
    } else {
      NotificationManager.error(pageContent.notification4, "Message", 4000);
    }
  };

  const profile = async () => {
    history.push(Routes.Profile);
  };

  const handlecheck = async (e) => {
    let payload;
    if (e === "deposit") {
      setDeposit(true);

      payload = { deposit: true, bonus: false };
    } else {
      setDeposit(false);
      payload = { deposit: false, bonus: true };
    }
    const res = await userInstance.post("/isActive", payload);
    if (res.data.code === 200) {
      NotificationManager.success(pageContent.notification1, "Message", 4000);
    } else {
      NotificationManager.error(pageContent.notification2, "Message", 4000);
    }
  };
  const [registrationSuccessShow, setRegistrationSuccessShow] = useState(false);
  const handleRegistrationSuccessShow = () => {
    setRegistrationSuccessShow(true);
  };
  const handleRegistrationSuccessClose = () => {
    setRegistrationSuccessShow(false);
  };
  const [loginshow, setLoginShow] = useState(false);
  const handleLoginShow = () => setLoginShow(true);
  const handleLoginClose = () => setLoginShow(false);
  const [signupshow, setSignupShow] = useState(false);
  const handleSignupShow = () => setSignupShow(true);
  const handleSignupClose = () => setSignupShow(false);
  const [inviteshow, setInviteShow] = useState(false);
  const handleInviteShow = () => setInviteShow(true);
  const handleInviteClose = () => setInviteShow(false);
  const [depositshow, setDepositShow] = useState(false);
  const handleDepositShow = () => setDepositShow(true);
  const handleDepositClose = () => setDepositShow(false);
  const [withdrawshow, setWithdrawShow] = useState(false);
  const handleWithdrawClose = () => setWithdrawShow(false);
  const [changepassshow, setChangepassShow] = useState(false);
  const handleChangepassClose = () => setChangepassShow(false);

  const SetBothSates = () => {
    handleSignupClose(false);
    handleRegistrationSuccessShow(true);
  };
  return (
    <header>
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Navbar
                collapseOnSelect
                expand="lg"
              >
                <Navbar.Brand href="/">
                  <div className="logo">
                    <img
                      src={logo}
                      alt="logo"
                    />
                  </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav>
                    <NavLink
                      exact
                      className="nav-link"
                      to="/"
                    >
                      {pageContent.menu1}
                    </NavLink>
                    <NavLink
                      className="nav-link"
                      to="/populargames"
                    >
                      {pageContent.menu2}
                    </NavLink>
                    <NavLink
                      className="nav-link"
                      to="/howitworks"
                    >
                      {pageContent.menu3}
                    </NavLink>
                    <NavLink
                      className="nav-link"
                      to="/tournament"
                    >
                      {pageContent.menu4}
                    </NavLink>
                    <NavLink
                      className="nav-link"
                      to="/faqs"
                    >
                      {pageContent.menu5}
                    </NavLink>
                    {/* <NavLink className="nav-link" to="/features">{pageContent.menu6")}</NavLink> */}
                  </Nav>
                </Navbar.Collapse>
                {!showButton && (
                  <div className="head-button">
                    <button
                      type="button"
                      to=""
                      className="login-btn"
                      onClick={handleLoginShow}
                    >
                      <i className="fa fa-user" />
                      {pageContent.login}
                    </button>
                    <button
                      type="button"
                      to=""
                      className="signup-btn"
                      onClick={handleSignupShow}
                    >
                      <i className="fa fa-user" />
                      {pageContent.signup}
                    </button>
                  </div>
                )}
                {user && user.length !== 0 && (
                  <div className="head-user-info">
                    <div className="my-profile-menu">
                      <div className="p-pic">
                        <img
                          src={`${server}/${pic}`}
                          alt={`${localStorage.getItem("nickName")}_pic`}
                          className="profile-image"
                        />

                        {eligible ? (
                          <img
                            src={staricon}
                            alt=""
                            className="star-icon"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="p-name">
                        <h5>{user.firstName}</h5>
                        {banned ? (
                          <Badge
                            className="ac-banned"
                            variant="danger"
                          >
                            <span className="text-light">{pageContent.banner}</span>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="default"
                                id="dropdown-basic"
                              />
                              <Dropdown.Menu>
                                <LogoutButton
                                  text={pageContent.link1}
                                  onClick={() => history.push(Routes.NotificationPage)}
                                />
                                <LogoutButton
                                  text={pageContent.link2}
                                  onClick={() => history.push(Routes.Home)}
                                />
                                <LogoutButton
                                  text={pageContent.link3}
                                  onClick={() => history.push(Routes.Home)}
                                />
                                <LogoutButton
                                  text={pageContent.link4}
                                  onClick={logout}
                                />
                              </Dropdown.Menu>
                            </Dropdown>
                          </Badge>
                        ) : (
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="default"
                              id="dropdown-basic"
                            />
                            <Dropdown.Menu>
                              <Dropdown.Item onClick={profile}>{pageContent.link5}</Dropdown.Item>

                              <LogoutButton
                                text={pageContent.link6}
                                onClick={() => history.push(Routes.Transactions)}
                              />
                              <LogoutButton
                                text={pageContent.link1}
                                onClick={() => history.push(Routes.NotificationPage)}
                              />

                              {eligible ? (
                                <LogoutButton
                                  text={pageContent.link7}
                                  onClick={() => history.push(Routes.CreateTournament)}
                                />
                              ) : (
                                ""
                              )}

                              <LogoutButton
                                text={pageContent.kyc}
                                onClick={() => history.push(Routes.Kyc)}
                              />
                              <LogoutButton
                                text={pageContent.link8}
                                onClick={handleInviteShow}
                              />
                              <LogoutButton
                                text={pageContent.link5}
                                onClick={logout}
                              />
                              {/* <button type="button" to="" className="logout-btn dropdown-item" onClick={logout}>
                                {pageContent.link4}
                              </button> */}
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                      </div>
                    </div>

                    <Badge
                      className="ac-notification"
                      variant="primary"
                      onClick={() => history.push(Routes.NotificationPage)}
                    >
                      <i className="fa fa-bell" />
                      {unread && (
                        <>
                          <Badge variant="info">{unread}</Badge>
                          <span className="sr-only">{pageContent.message1}</span>
                        </>
                      )}
                    </Badge>

                    <div className="wallet-container">
                      <h4>{pageContent.message2}</h4>

                      <Dropdown id="dropdown-wallet">
                        <Dropdown.Toggle
                          variant="default"
                          id="dropdown-basic-wallet"
                        >
                          <div className="wallet-box">
                            {deposit ? (
                              <div className="money-wallet">
                                <h5>{pageContent.message3}</h5>
                                <div className="money-wallet-box">
                                  {depositAmount ? (
                                    <span>
                                      {depositAmount.toFixed(2)}
                                      <i>USD</i>
                                    </span>
                                  ) : (
                                    <span>
                                      0.00
                                      <i>USD</i>
                                    </span>
                                  )}
                                </div>
                                <div className="money-wallet-box">
                                  {depositAmount ? (
                                    <span>
                                      {currencyConvert.toFixed(2)}
                                      <i>{currency}</i>
                                    </span>
                                  ) : (
                                    <span>
                                      0.00
                                      <i>{currency}</i>
                                    </span>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="money-wallet">
                                <h5>{pageContent.bonus}</h5>
                                <div className="money-wallet-box">
                                  {bonusAmt ? (
                                    <span>
                                      {bonusAmt.toFixed(2)}
                                      <i>USD</i>
                                    </span>
                                  ) : (
                                    <span>
                                      0.00
                                      <i>USD</i>
                                    </span>
                                  )}
                                </div>
                                <div className="money-wallet-box">
                                  {bonusAmt ? (
                                    <span>
                                      {bonusConvert.toFixed(2)}
                                      <i>{currency}</i>
                                    </span>
                                  ) : (
                                    <span>
                                      0.00
                                      <i>{currency}</i>
                                    </span>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={handlecheck}>
                            <div className="wallet-box">
                              <h5>{pageContent.message3}</h5>
                              <div className="money-wallet">
                                <div className="money-wallet-box">
                                  {depositAmount ? (
                                    <span>
                                      {depositAmount.toFixed(2)}
                                      <i>USD</i>
                                    </span>
                                  ) : (
                                    <span>
                                      0.00
                                      <i>USD</i>
                                    </span>
                                  )}
                                </div>
                                <div className="money-wallet-box">
                                  {depositAmount ? (
                                    <span>
                                      {currencyConvert.toFixed(2)}
                                      <i>{currency}</i>
                                    </span>
                                  ) : (
                                    <span>
                                      0.00
                                      <i>{currency}</i>
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Dropdown.Item>

                          <Dropdown.Item onClick={handlecheck}>
                            <div className="wallet-box">
                              <h5>{pageContent.bonus}</h5>
                              <div className="money-wallet-box">
                                {bonusAmt ? (
                                  <span>
                                    {bonusAmt.toFixed(2)}
                                    <i>USD</i>
                                  </span>
                                ) : (
                                  <span>
                                    0.00
                                    <i>USD</i>
                                  </span>
                                )}
                              </div>
                              <div className="money-wallet-box">
                                {bonusAmt ? (
                                  <span>
                                    {bonusConvert.toFixed(2)}
                                    <i>{currency}</i>
                                  </span>
                                ) : (
                                  <span>
                                    0.00
                                    <i>{currency}</i>
                                  </span>
                                )}
                              </div>
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>

                    <div className="add-money">
                      <Link to="/friend">
                        <img
                          src={friend}
                          alt=""
                        />
                      </Link>
                    </div>
                    <button
                      type="button"
                      className="add-money"
                      onClick={handleDepositShow}
                    >
                      <img
                        src={money}
                        alt=""
                      />
                    </button>
                  </div>
                )}

                <div className="language-dropdown">
                  <Dropdown id="dropdown-language">
                    <Dropdown.Toggle
                      variant="default"
                      id="dropdown-basic"
                    >
                      {localStorage.getItem("i18nextLng")}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {language &&
                        language.map((el) => (
                          <Dropdown.Item
                            onClick={changeLanguage}
                            key={el}
                          >
                            {el.toUpperCase()}
                          </Dropdown.Item>
                        ))}
                      {/* <Dropdown.Item onClick={changeLanguage.bind(this, "en")}>EN</Dropdown.Item>
                        <Dropdown.Item onClick={changeLanguage.bind(this, "pl")}>PL</Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Navbar>
            </div>
          </div>
        </div>
      </div>

      <Modal
        dialogClassName="dod-popup"
        show={loginshow}
        onHide={handleLoginClose}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <Login />
        </Modal.Body>
      </Modal>

      <Modal
        dialogClassName="dod-popup"
        show={signupshow}
        onHide={handleSignupClose}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <Signup SetBothSates={SetBothSates} />
        </Modal.Body>
      </Modal>
      <Modal
        dialogClassName="dod-popup"
        show={registrationSuccessShow}
        onHide={handleRegistrationSuccessClose}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <RegistrationSuccess handleRegistrationSuccessClose={handleRegistrationSuccessClose} />
        </Modal.Body>
      </Modal>

      <Modal
        dialogClassName="dod-popup"
        show={inviteshow}
        onHide={handleInviteClose}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <InviteFriends />
        </Modal.Body>
      </Modal>

      <Modal
        dialogClassName="dod-popup"
        show={depositshow}
        onHide={handleDepositClose}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <Deposit currency={currency} />
        </Modal.Body>
      </Modal>

      <Modal
        dialogClassName="dod-popup"
        show={withdrawshow}
        onHide={handleWithdrawClose}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <WithdrawMoney />
        </Modal.Body>
      </Modal>

      <Modal
        dialogClassName="dod-popup"
        show={changepassshow}
        onHide={handleChangepassClose}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <ChangePassword />
        </Modal.Body>
      </Modal>
    </header>
  );
}

export default Header;
