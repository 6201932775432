import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { cmsInstance } from "../../axios/axiosconfig";
import Layout from "../layout";

function Help() {
  const { i18n } = useTranslation();
  const [pageContent, setPageContent] = useState({});

  const getContent = async () => {
    try {
      const page = "/help";
      const { language } = i18n;
      const {
        data: { content, msg },
        status,
      } = await cmsInstance.get(`/content${page}/${language}`);
      if (status === 200) {
        setPageContent(content);
      } else {
        NotificationManager.error(msg, "Message", 10000);
      }
    } catch (error) {
      console.error("Error", error.message);
    }
  };
  const onLanguageChanged = () => {
    getContent();
  };

  useEffect(() => {
    getContent();
    i18n.on("languageChanged", onLanguageChanged);
  }, []);

  return (
    <Layout>
      <div className="legal">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-page help-title">
                <ul className="breadcrum">
                  <li>
                    <Link to="/">{pageContent.home}</Link>
                  </li>
                  <li>{pageContent.help}</li>
                </ul>
                <h2>{pageContent.help}</h2>
                <p>{pageContent.para1}</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">
              <div className="legal-content-box">
                <h2>{pageContent.para2}</h2>
                <h3>{pageContent.help}</h3>
                <p>{pageContent.para3}</p>
                <h4>{pageContent.help}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Help;
