import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./profile.css";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import { Table, Form, Spinner, Button, Modal } from "react-bootstrap";
import { getParamByParam } from "iso-country-currency";
import { userInstance, cmsInstance } from "../../axios/axiosconfig";
import Layout from "../layout";
import addmoney from "../../assets/images/home/addmoney-icon.png";
import withdraw from "../../assets/images/home/withdraw-icon.png";
import EditProfileStyled from "../editprofile/EditProfile.style";
import WithdrawMoney from "../withdrawMoney/withdrawMoney";
import Deposit from "../deposit/deposit";
import { server } from "../../config/config";
import Row from "./Row";
import Routes from "../../config/routes";

function Profile() {
  const history = useHistory();
  const { i18n } = useTranslation();
  const [user, setUser] = useState([]);
  const [preview, setPreview] = useState(null);
  const [pic, setPic] = useState(null);
  const [bonus, setBonus] = useState(null);
  const [deposit, setDeposit] = useState(null);
  const UserId = localStorage.getItem("Userid");
  const [friendList, setFriendList] = useState([]);
  const [receiveList, setReceiveList] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [recentDuels, setRecentDuels] = useState([]);
  const [pageContent, setPageContent] = useState({});
  const [page, setPage] = useState(1);
  const [recentTournament, setRecentTournament] = useState([]);
  const [tourPage, setTourPage] = useState(1);
  const ranking = ["Beginner", "Trainee", "Amateur", "Hustler", "Virtuoso", "Expert", "Veteran", "Semi-Pro", "Professional", "Master", "Champ", "Superstar", "Hero", "Legend", "Immortal"];
  const [rank, setRank] = useState("");
  const [currency, setCurrency] = useState(null);
  // useEffect(() => {
  //     getProfile();
  //     getturnover();
  //     createtournamentEligible();
  //     duelHistory();
  //    //tournamentcreation();
  // }, [])

  const getContent = async () => {
    try {
      // const page = window.location.pathname.replace('/cms/', '');
      const pageUrl = "/profile";
      const language = localStorage.getItem("activeLanguage") ? localStorage.getItem("activeLanguage") : localStorage.getItem("i18nextLng");
      const {
        data: { content, msg },
        status,
      } = await cmsInstance.get(`/content${pageUrl}/${language}`);
      if (status === 200) {
        setPageContent(content);
      } else {
        NotificationManager.error(msg, pageContent.message, 10000);
      }
    } catch (error) {
      console.error("Error", error.message);
    }
  };

  const onLanguageChanged = () => {
    getContent();
  };

  const getAllPlayers = async () => {
    const res = await userInstance.get("/friendlist");
    if (res.data.code === 200) {
      setFriendList(res.data.friend);
      setReceiveList(res.data.receive);
    }
  };

  const getLastDuels = async (pageValue, paginationValue) => {
    setPage(pageValue);
    const filterBy = "";
    const p = pageValue || 1;

    const payload = {
      data: filterBy,
      page: p,
      pagination: paginationValue,
    };

    const res = await userInstance.post("/recentDuel", payload);
    if (res.data.code === 200) {
      setRecentDuels(res.data.duels);
    }
  };
  const getRecentTournament = async (pageValue, paginationValue) => {
    setTourPage(pageValue);
    const filterBy = "";
    const p = pageValue || 1;

    const payload = {
      data: filterBy,
      page: p,
      pagination: paginationValue,
    };
    const res = await userInstance.post("/recentTournamentUser", payload);

    if (res.data.code === 200) {
      setRecentTournament(res.data.tournament);
    }
  };

  const handleCancelReceiveRequest = async (id) => {
    const payload = {
      requestid: id,
    };
    const res = await userInstance.post("/cancelReceiveRequest", payload);
    if (res.data.code === 200) {
      setFriendList(res.data.friend);
      setReceiveList(res.data.receive);
      NotificationManager.success(pageContent.notification1, pageContent.message, 4000);
    }
  };
  const handleAcceptRequest = async (id) => {
    const payload = {
      requestid: id,
    };
    setDisabled(true);
    const res = await userInstance.post("/acceptReceiveRequest", payload);
    if (res.data.code === 200) {
      setDisabled(false);
      setFriendList(res.data.friend);
      setReceiveList(res.data.receive);
      NotificationManager.success(pageContent.notification2, pageContent.message, 4000);
    }
  };
  const handleUnFriend = async (id) => {
    const payload = {
      requestid: id,
    };
    const res = await userInstance.post("/unfriendRequest", payload);
    if (res.data.code === 200) {
      setFriendList(res.data.friend);
      setReceiveList(res.data.receive);
      NotificationManager.success(pageContent.notification3, pageContent.message, 4000);
    }
  };

  const Item1 =
    receiveList &&
    receiveList.map((el) => (
      <tr key={el}>
        <td>
          <img
            src={`${server}/${el.photo}`}
            alt=""
          />
        </td>
        <td className="game-player-result__date">{el.nickName}</td>
        <td className="game-player-result__vs">{el.country}</td>
        <td className="game-player-result__vs">{el.Level}</td>
        <td className="game-player-result__vs">
          {el.online && el.online.isActive ? (
            pageContent.online
          ) : el.online ? (
            <div>
              {parseInt(Math.abs(new Date(new Date().toISOString()).getTime() - new Date(el.online.lastActive).getTime()).toString() / 1000 / 60 / 60, 10) % 24 > 0 &&
              parseInt(Math.abs(new Date(new Date().toISOString()).getTime() - new Date(el.online.lastActive).getTime()).toString() / 1000 / 60 / 60, 10) % 24 <= 24
                ? pageContent.offline
                : `${parseInt((Math.abs(new Date(new Date().toISOString()).getTime() - new Date(el.online.lastActive).getTime()).toString() / 1000 / 60) % 60, 10)} ${pageContent.minago}`}
            </div>
          ) : (
            pageContent.offline
          )}
        </td>

        <td className="game-player-result__vs">
          <i
            className="fa fa-plus-circle"
            aria-hidden="true"
            disabled={disabled}
            onClick={() => handleAcceptRequest(el._id)}
            style={{
              fontSize: "25px",
              marginRight: "20px",
              cursor: "pointer",
            }}
            data-toggle="tooltip"
            data-placement="top"
            title="Accept"
          />
          <i
            className="fa fa-times"
            aria-hidden="true"
            onClick={() => handleCancelReceiveRequest(el._id)}
            style={{ fontSize: "25px", cursor: "pointer" }}
            data-toggle="tooltip"
            data-placement="top"
            title="Reject"
          />
        </td>
      </tr>
    ));

  const Item =
    friendList &&
    friendList.map((el) => (
      <tr key={el}>
        <td>
          <img
            src={`${server}/${el.photo}`}
            alt=""
          />
        </td>
        <td className="game-player-result__date">{el.nickName}</td>
        <td className="game-player-result__vs">{el.country}</td>
        <td className="game-player-result__vs">{el.Level}</td>
        <td className="game-player-result__vs">
          {el.online && el.online.isActive ? (
            pageContent.online
          ) : el.online ? (
            <div>
              {parseInt(Math.abs(new Date(new Date().toISOString()).getTime() - new Date(el.online.lastActive).getTime()).toString() / 1000 / 60 / 60, 10) % 24 > 0 &&
              parseInt(Math.abs(new Date(new Date().toISOString()).getTime() - new Date(el.online.lastActive).getTime()).toString() / 1000 / 60 / 60, 10) % 24 <= 24
                ? pageContent.offline
                : `${parseInt((Math.abs(new Date(new Date().toISOString()).getTime() - new Date(el.online.lastActive).getTime()).toString() / 1000 / 60) % 60, 10)} ${pageContent.minago}`}
            </div>
          ) : (
            pageContent.offline
          )}
        </td>
        <td />
        <td className="game-player-result__vs">
          <Button
            className="blue-btn"
            onClick={() => handleUnFriend(el._id)}
          >
            {pageContent.unfriend}
          </Button>
        </td>
      </tr>
    ));
  const createtournamentEligible = async () => {};

  const handlePicChange = async (e) => {
    const f = e.target.files[0];
    const imageAsBase64 = URL.createObjectURL(e.target.files[0]);
    setPreview(imageAsBase64);
    setPic(f);
  };
  const handlePicShow = async () => {
    setDisabled(true);
    const fd = new FormData();

    if (pic.name) {
      fd.append("file", pic);
      const response = await userInstance.post("/addImg", fd);
      if (response.data.code === 200) {
        setDisabled(false);
        NotificationManager.success(pageContent.notification5, pageContent.message, 10000);
      } else if (response.data.code === 400) {
        setDisabled(false);
        NotificationManager.error(pageContent.notification6, pageContent.message, 10000);
      } else {
        setDisabled(false);
        NotificationManager.error(pageContent.notification7, pageContent.message, 10000);
      }
    } else {
      setDisabled(false);
      NotificationManager.error(pageContent.notification4, pageContent.message, 10000);
    }
  };
  const [editshow, setEditShow] = useState(false);
  const handleEditShow = () => setEditShow(true);
  const handleEditClose = () => setEditShow(false);

  const [depositshow, setDepositShow] = useState(false);
  const handleDepositShow = () => setDepositShow(true);
  const handleDepositClose = () => setDepositShow(false);

  const [withdrawshow, setWithdrawShow] = useState(false);
  const handleWithdrawShow = () => setWithdrawShow(true);
  const handleWithdrawClose = () => setWithdrawShow(false);

  useEffect(() => {
    const getUserProfile = async () => {
      const response = await userInstance.get("/getprofile");
      if (response.data.code === 200) {
        setUser(response.data.profile_data);
        setBonus(response.data.profile_data.bonus[0]);
        setDeposit(response.data.profile_data.deposit[0]);
        setPic(response.data.profile_data.photo);
        const currencyCode = getParamByParam("countryName", response.data.profile_data.country, "currency");
        setCurrency(currencyCode);
      } else {
        history.push(Routes.Login);
      }
      if (user) {
        if (response.data.profile_data.Level <= 2) setRank(ranking[0]);
        else if (response.data.profile_data.Level > 2 && response.data.profile_data.Level <= 5) setRank(ranking[1]);
        else if (response.data.profile_data.Level > 5 && response.data.profile_data.Level <= 9) setRank(ranking[2]);
        else if (response.data.profile_data.Level > 9 && response.data.profile_data.Level <= 14) setRank(ranking[3]);
        else if (response.data.profile_data.Level > 14 && response.data.profile_data.Level <= 20) setRank(ranking[4]);
        else if (response.data.profile_data.Level > 20 && response.data.profile_data.Level <= 27) setRank(ranking[5]);
        else if (response.data.profile_data.Level > 27 && response.data.profile_data.Level <= 35) setRank(ranking[6]);
        else if (response.data.profile_data.Level > 35 && response.data.profile_data.Level <= 44) setRank(ranking[7]);
        else if (response.data.profile_data.Level > 44 && response.data.profile_data.Level <= 54) setRank(ranking[8]);
        else if (response.data.profile_data.Level > 54 && response.data.profile_data.Level <= 65) setRank(ranking[9]);
        else if (response.data.profile_data.Level > 65 && response.data.profile_data.Level <= 77) setRank(ranking[10]);
        else if (response.data.profile_data.Level > 77 && response.data.profile_data.Level <= 90) setRank(ranking[11]);
        else if (response.data.profile_data.Level > 90 && response.data.profile_data.Level <= 104) setRank(ranking[12]);
        else if (response.data.profile_data.Level > 104 && response.data.profile_data.Level <= 119) setRank(ranking[13]);
        else if (response.data.profile_data.Level > 120 && response.data.profile_data.Level <= 136) setRank(ranking[14]);
        else if (response.data.profile_data.Level > 136 && response.data.profile_data.Level <= 150) setRank(ranking[15]);
      }
    };
    getUserProfile();
    // getturnover();
    getAllPlayers();
    getLastDuels(1, 10);
    getRecentTournament(1, 10);
    createtournamentEligible();
    getContent();
    i18n.on("languageChanged", onLanguageChanged);
    // duelHistory();
  }, []);

  return (
    <Layout>
      <div className="profile">
        <div className="profile-content">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="title-page">
                  <ul className="breadcrum">
                    <li>
                      <Link to="/">{pageContent.home}</Link>
                    </li>
                    <li>{pageContent.prof}</li>
                  </ul>
                  <h2>{pageContent.prof}</h2>
                </div>
              </div>
            </div>
            <div className="row mb-100">
              <div className="col-md-2">
                <div className="user-profile">
                  <div className="user-pic-box">
                    {preview === null ? (
                      user.photo !== "public\\profileimages\\default.png" ? (
                        <img
                          src={`${server}/${user.photo}`}
                          alt=""
                        />
                      ) : (
                        <img
                          src={`${server}/public/profileimages/default.jpg`}
                          alt=""
                        />
                      )
                    ) : (
                      <img
                        src={preview}
                        alt=""
                      />
                    )}
                    <Form>
                      <Form.Control
                        type="file"
                        className="profile-input"
                        onChange={handlePicChange}
                      />
                    </Form>
                  </div>
                  <Button
                    href="#"
                    className="edit-btn"
                    disabled={disabled}
                    onClick={handlePicShow}
                  >
                    {disabled ? (
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      ""
                    )}
                    {pageContent.upload}
                  </Button>
                </div>
              </div>
              <div className="col-md-5">
                <div className="card mb-4">
                  <div className="card-body">
                    <Row
                      div1={`${pageContent.gamename}:`}
                      div2={user.firstName}
                    />
                    {/* <div className="row mb-3">
                      <div className="col-md-5 text-muted">{`${pageContent.gamename}:`}</div>
                      <div className="col-md-7">{user.firstName}</div>
                    </div> */}
                    <Row
                      div1={`${pageContent.level}:`}
                      div2={`Level ${user.level}`}
                    />
                    {/* <div className="row mb-3">
                      <div className="col-md-5 text-muted">{`${pageContent.level}:`}</div>
                      <div className="col-md-7">
                        {"Level "}
                        {user.Level}
                      </div>
                    </div> */}
                    <Row
                      div1={`${pageContent.ranking}:`}
                      div2={rank}
                    />
                    {/* <div className="row mb-3">
                      <div className="col-md-5 text-muted">{`${pageContent.ranking}:`}</div>
                      <div className="col-md-7">{rank}</div>
                    </div> */}
                    <Row
                      div1={`${pageContent.name}:`}
                      div2={user.nickName}
                    />
                    {/* <div className="row mb-3">
                      <div className="col-md-5 text-muted">{`${pageContent.name}:`}</div>
                      <div className="col-md-7">{user.nickName}</div>
                    </div> */}
                    <Row
                      div1={`${pageContent.email}:`}
                      div2={user.email}
                    />
                    {/* <div className="row mb-3">
                      <div className="col-md-5 text-muted">{`${pageContent.email}:`}</div>
                      <div className="col-md-7">{user.email}</div>
                    </div> */}
                    <Row
                      div1={`${pageContent.country}:`}
                      div2={user.country}
                    />
                    {/* <div className="row mb-3">
                      <div className="col-md-5 text-muted">{`${pageContent.country}:`}</div>
                      <div className="col-md-7">{user.country}</div>
                    </div> */}
                    <Row
                      div1={`${pageContent.uniquereferal}:`}
                      div2={
                        <a
                          href={`${server}/user/invite/${user._id}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {`${server}/user/invite/`}
                          {user._id}
                        </a>
                      }
                    />
                    {/* <div className="row mb-3">
                      <div className="col-md-5 text-muted">{`${pageContent.uniquereferal}:`}</div>
                      <div className="col-md-7">
                        <a href={`${server}/user/invite/${user._id}`} target="_blank" rel="noreferrer">
                          {`${server}/user/invite/`}
                          {user._id}
                        </a>
                      </div>
                    </div> */}

                    <button
                      type="button"
                      href="#"
                      className="blue-btn edit-btn"
                      onClick={handleEditShow}
                    >
                      {pageContent.editprofile}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="row mb-3">
                      <Row
                        div1={`${pageContent.bonus}:`}
                        div2={user.bonusTurnOver ? `${user.bonusTurnOver.toFixed(2)} USD` : "0.0 USD"}
                      />
                      {/* <div className="row mb-3">
                        <div className="col-md-5 text-muted">{`${pageContent.bonus}:`}</div>
                        <div className="col-md-7">
                          {user.bonusTurnOver ? user.bonusTurnOver.toFixed(2) : 0.0}
                          {" USD"}
                        </div>
                      </div> */}
                      <Row
                        div1={`${pageContent.winpercentage}:`}
                        div2={user.gameWon && user.gameLoss ? ((user.gameWon * 100) / (user.gameLoss + user.gameWon)).toFixed(2) : 0.0}
                      />
                      {/* <div className="row mb-3">
                        <div className="col-md-5 text-muted">{`${pageContent.winpercentage}:`}</div>
                        <div className="col-md-7">{user.gameWon && user.gameLoss ? ((user.gameWon * 100) / (user.gameLoss + user.gameWon)).toFixed(2) : 0.0}</div>
                      </div> */}
                      <Row
                        div1={`${pageContent.totalwin}:`}
                        div2={user && user.totalWinPrize && `${user.totalWinPrize.toFixed(2)} USD`}
                      />
                      {/* <div className="row mb-3">
                        <div className="col-md-5 text-muted">{`${pageContent.totalwin}:`}</div>
                        <div className="col-md-7">
                          {user && user.totalWinPrize && user.totalWinPrize.toFixed(2)}
                          {" USD"}
                        </div>
                      </div> */}
                      <Row
                        div1={`${pageContent.duelswon}:`}
                        div2={user.gameWon}
                      />
                      {/* <div className="row mb-3">
                        <div className="col-md-5 text-muted">{`${pageContent.duelswon}:`}</div>
                        <div className="col-md-7">{user.gameWon}</div>
                      </div> */}
                      <Row
                        div1={`${pageContent.tournamentwon}:`}
                        div2={user.tournamentWon}
                      />
                      {/* <div className="row mb-3">
                        <div className="col-md-5 text-muted">{`${pageContent.tournamentwon}:`}</div>
                        <div className="col-md-7">{user.tournamentWon}</div>
                      </div> */}
                      <Row
                        div1={`${pageContent.bonusaccount}:`}
                        div2={`${bonus && <span>{bonus.USD.toFixed(2)}</span>} USD`}
                      />
                      {/* <div className="row mb-3">
                        <div className="col-md-5 text-muted">{`${pageContent.bonusaccount}:`}</div>
                        <div className="col-md-7">{`${bonus && <span>{bonus.USD.toFixed(2)}</span>} USD`}</div>
                      </div> */}
                      <Row
                        div1={`${pageContent.despositaccount}:`}
                        div2={`${deposit && <span>{deposit.USD.toFixed(2)}</span>} USD`}
                      />
                      {/* <div className="row mb-3">
                        <div className="col-md-5 text-muted">{`${pageContent.despositaccount}:`}</div>
                        <div className="col-md-7">{`${deposit && <span>{deposit.USD.toFixed(2)}</span>} USD`}</div>
                      </div> */}
                      <Button
                        className="border-btn"
                        onClick={handleDepositShow}
                      >
                        <img
                          src={addmoney}
                          alt=""
                        />
                        {pageContent.addmoney}
                      </Button>
                      <Button
                        className="border-btn"
                        onClick={handleWithdrawShow}
                      >
                        <img
                          src={withdraw}
                          alt=""
                        />
                        {pageContent.withdrawmoney}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mb-100">
                  <h4 className="litle-title mb-3">{pageContent.recentduels}</h4>
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="home-table ">
                        <Table
                          bordered
                          responsive
                        >
                          <thead>
                            <tr>
                              <th>{pageContent.matchtype}</th>
                              <th>{pageContent.betAmt}</th>
                              <th>{pageContent.winAmt}</th>
                              <th className="date">{pageContent.date}</th>
                              <th>{pageContent.opponent}</th>
                              <th>{pageContent.score}</th>
                              <th>{pageContent.result}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {recentDuels &&
                              recentDuels.map(
                                (ele, i) =>
                                  ele.matches.reverse().map((match, j) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <tr key={`item-${i}${j}`}>
                                      {/* <td>{ele.gameName && {ele.gameName}}</td> */}
                                      <td>
                                        {ele.betAmount}
                                        {" USD"}
                                      </td>
                                      <td>
                                        {ele.betPot}
                                        {" USD"}
                                      </td>
                                      <td>{ele.updatedAt && ele.updatedAt.substring(0, 10)}</td>

                                      {ele.players[0].toString() === UserId.toString() ? (
                                        match.player_one_score > match.player_two_score ? (
                                          <>
                                            <td>{ele.playersData[1].name ? ele.playersData[1].name : ""}</td>
                                            <td>{match.walkover ? "Walkover" : `${match.player_one_score} : ${match.player_two_score}`}</td>
                                            <td>{pageContent.winner}</td>
                                          </>
                                        ) : (
                                          <>
                                            <td>{ele.playersData[1].name ? ele.playersData[1].name : ""}</td>
                                            <td>{match.walkover ? "Walkover" : `${match.player_one_score} : ${match.player_two_score}`}</td>
                                            <td>{pageContent.loser}</td>
                                          </>
                                        )
                                      ) : match.player_one_score > match.player_two_score ? (
                                        <>
                                          <td>{ele.playersData[0].name ? ele.playersData[0].name : ""}</td>
                                          <td>{match.walkover ? "Walkover" : `${match.player_one_score} : ${match.player_two_score}`}</td>
                                          <td>{pageContent.loser}</td>
                                        </>
                                      ) : (
                                        <>
                                          <td>{ele.playersData[0].name ? ele.playersData[0].name : ""}</td>
                                          <td>{match.walkover ? "Walkover" : `${match.player_one_score} : ${match.player_two_score}`}</td>
                                          <td>{pageContent.winner}</td>
                                        </>
                                      )}
                                    </tr>
                                    // eslint-disable-next-line comma-dangle
                                  ))
                                // eslint-disable-next-line function-paren-newline
                              )}

                            {recentDuels.length === 0 ? (
                              <tr
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <td>{pageContent.noDuel}</td>
                              </tr>
                            ) : (
                              ""
                            )}
                          </tbody>
                        </Table>
                      </div>
                      <div className="pagination-section">
                        {page > 1 ? (
                          <button
                            type="button"
                            onClick={() => getLastDuels(page - 1, 10)}
                            className="previous"
                          >
                            {pageContent.previous}
                          </button>
                        ) : (
                          ""
                        )}
                        {recentDuels.length === 10 ? (
                          <button
                            type="button"
                            onClick={() => getLastDuels(page + 1, 10)}
                            className="next"
                          >
                            {pageContent.next}
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-100">
                  <h4 className="litle-title mb-3">{pageContent.recenttournaments}</h4>
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="home-table ">
                        <Table
                          bordered
                          responsive
                        >
                          <thead>
                            <tr>
                              <th>{pageContent.game}</th>
                              <th>{pageContent.winner}</th>
                              <th>{pageContent.platform}</th>
                              <th>{pageContent.prize}</th>
                              <th>{pageContent.players}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {recentTournament &&
                              recentTournament.map((value) => (
                                <tr key={value}>
                                  <td>{`${value.title}: ${value.tournamentStart} ${value.tournamentEntryFee}`}</td>
                                  <td className="winner">
                                    {value.winner.length === 3 ? `1st : ${value.winner[0]}, 2nd : ${value.winner[1]}, 3rd : ${value.winner[2]}` : value.winner.length === 2 ? `1st : ${value.winner[0]}, 2nd : ${value.winner[1]}` : value.winner[0]}
                                  </td>
                                  <td>XBOX</td>
                                  <td className="prize">
                                    {value.amt.length === 3 ? `1st : ${value.amt[0]}, &nbsp; 2nd: ${value.amt[1]}, &nbsp; 3rd: ${value.amt[2]}` : value.amt.length === 2 ? `1st : ${value.amt[0]}, &nbsp; 2nd:${value.amt[1]}` : `${value.amt[0]}`}
                                  </td>
                                  <td>{value.playerNumbers}</td>
                                </tr>
                              ))}
                            {recentTournament.length === 0 ? (
                              <tr
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <td />
                                <td colSpan={4}>{pageContent.noTournament}</td>
                              </tr>
                            ) : (
                              ""
                            )}
                          </tbody>
                        </Table>
                      </div>
                      <div className="pagination-section">
                        {tourPage > 1 ? (
                          <button
                            type="button"
                            onClick={() => getRecentTournament(tourPage - 1, 10)}
                            className="previous"
                          >
                            {pageContent.previous}
                          </button>
                        ) : (
                          ""
                        )}
                        {recentTournament.length === 10 ? (
                          <button
                            type="button"
                            onClick={() => getRecentTournament(tourPage + 1, 10)}
                            className="next"
                          >
                            {pageContent.next}
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 my-friends">
                  <h4 className="litle-title mb-3">{pageContent.myfriend}</h4>
                  <Link to="/friend">{pageContent.seeall}</Link>
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="home-table">
                        <Table
                          bordered
                          responsive
                        >
                          <thead>
                            <tr>
                              <th>{pageContent.nickName}</th>
                              <th>{pageContent.country}</th>
                              <th>{pageContent.level}</th>
                              <th>{pageContent.online}</th>
                              {/* <th></th> */}
                              <th>{pageContent.option}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {friendList && friendList.length !== 0 ? (
                              Item
                            ) : (
                              <tr
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <td colSpan={5}>{pageContent.nofriend}</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 my-friends">
                  <h4 className="litle-title mb-3">{pageContent.friendRequest}</h4>
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="home-table">
                        <Table
                          bordered
                          responsive
                        >
                          <thead>
                            <tr>
                              <th>{pageContent.nickName}</th>
                              <th>{pageContent.country}</th>
                              <th>{pageContent.level}</th>
                              <th>{pageContent.online}</th>
                              {/* <th></th> */}
                              <th>{pageContent.option}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {receiveList && receiveList.length !== 0 ? (
                              Item1
                            ) : (
                              <tr
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <td colSpan={5}>{pageContent.nofriend}</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        dialogClassName="dod-popup"
        show={editshow}
        onHide={handleEditClose}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <EditProfileStyled />
        </Modal.Body>
      </Modal>

      <Modal
        dialogClassName="dod-popup"
        show={depositshow}
        onHide={handleDepositClose}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <Deposit />
        </Modal.Body>
      </Modal>

      <Modal
        dialogClassName="dod-popup"
        show={withdrawshow}
        onHide={handleWithdrawClose}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <WithdrawMoney currency={currency} />
        </Modal.Body>
      </Modal>
    </Layout>
  );
}

export default Profile;
