import React, { useEffect, useState } from "react";
import "./changepassword.css";
// import logo from "../../assets/images/header/logo.png";
import { Form, Button } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { validateData } from "../../function/function";
import { userInstance, cmsInstance } from "../../axios/axiosconfig";

// let url = window.location.href.split('/');
// let hex = url[url.length - 1];

const INITIAL_STATE = {
  oldpassword: "",
  password: "",
  confirmPassword: "",
};

function ChangePassword() {
  const [values, setValues] = useState(INITIAL_STATE);
  const [pageData, setPageData] = useState({});
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const getContent = async () => {
    try {
      // const page = window.location.pathname.replace('/cms/', '');
      const page = "/changepassword";
      const language = localStorage.getItem("activeLanguage") ? localStorage.getItem("activeLanguage") : localStorage.getItem("i18nextLng");
      const {
        data: { content, msg },
        status,
      } = await cmsInstance.get(`/content${page}/${language}`);
      if (status === 200) {
        setPageData(content);
      } else {
        NotificationManager.error(msg, "Message", 10000);
      }
    } catch (error) {
      console.error("Error", error.message);
    }
  };
  useEffect(() => {
    getContent();
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();

    const isValid = validateData([values.password, values.confirmPassword, values.oldpassword]);
    if (isValid) {
      if (values.password === values.confirmPassword) {
        const payload = {
          currentPassword: values.oldpassword,
          newPassword: values.password,
        };
        const response = await userInstance.post("/changePassword", payload);
        const statusCode = response.data.code;
        if (statusCode === 200) {
          setValues(INITIAL_STATE);
          NotificationManager.success(pageData.message1, "Message", 4000);
        } else {
          NotificationManager.error(pageData.message2, "Message", 4000);
        }
      } else {
        NotificationManager.error(pageData.message3, "Message", 4000);
      }
    } else {
      NotificationManager.error(pageData.message4, "Message", 4000);
    }
  };

  return (
    <div className="forget changepass">
      <div className="forget-section">
        <div className="container">
          <div classNmae="row">
            <div className="col-md-12">
              <div className="forget-form">
                <h1>
                  {pageData.heading1}
                  <span>{pageData.heading2}</span>
                </h1>
                <div className="forget-box">
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label htmlFor="oldpassword">{pageData.label1}</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder={pageData.placeholder1}
                        name="oldpassword"
                        id="oldpassword"
                        onChange={handleChange}
                        value={values.oldpassword}
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label htmlFor="new password">{pageData.label2}</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder={pageData.placeholder2}
                        name="password"
                        id="new password"
                        onChange={handleChange}
                        value={values.password}
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label htmlFor="confirmPassword">{pageData.label3}</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder={pageData.placeholder3}
                        name="confirmPassword"
                        id="confirmPassword"
                        onChange={handleChange}
                        value={values.confirmPassword}
                      />
                    </Form.Group>
                    <div className="forget-button">
                      <Button
                        type="submit"
                        className="red-btn"
                      >
                        {pageData.label4}
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
