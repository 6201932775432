import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import "./registrationSuccess.css";
import { Button } from "react-bootstrap";
import { cmsInstance } from "../../axios/axiosconfig";

interface PageContent {
  heading1: string;
  msg: string;
  submit: string;
}

interface RegistrationSuccessProps {
  handleRegistrationSuccessClose: () => void;
}

function RegistrationSuccess({ handleRegistrationSuccessClose }: RegistrationSuccessProps) {
  const [pageContent, setPageContent] = useState<PageContent>();
  const [isLoading, setIsLoading] = useState(false);

  const getContent = async () => {
    try {
      // const page = window.location.pathname.replace('/cms/', '');
      const page = "/registersuccess";
      const language = localStorage.getItem("activeLanguage") ? localStorage.getItem("activeLanguage") : localStorage.getItem("i18nextLng");
      const {
        data: { content, msg },
        status,
      } = await cmsInstance.get(`/content${page}/${language}`);
      if (status === 200) {
        setPageContent(content);
        setIsLoading(true);
      } else {
        NotificationManager.error(msg, "Message", 10000);
      }
    } catch (error) {
      console.error("Error", (error as Error).message);
    }
  };
  useEffect(() => {
    getContent();
  }, []);
  if (!pageContent) return <div>Not found</div>;
  return (
    <div className="withdraw">
      <div className="container">
        {isLoading && (
          <div className="withdraw-content">
            <h2>{pageContent.heading1}</h2>
            <label htmlFor="id">{pageContent.msg}</label>
            <div className="text-center">
              <Button
                type="button"
                className="red-btn"
                onClick={() => {
                  handleRegistrationSuccessClose();
                }}
              >
                {pageContent.submit}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default RegistrationSuccess;
