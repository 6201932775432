import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Alert, Dropdown, DropdownButton } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { userInstance, cmsInstance } from "../../axios/axiosconfig";
import socket from "../../config/socket";

interface PageContent {
  betAmount: number;
  close: string;
  submit: string;
}

interface BetProps {
  CloseBetComponent: () => void;
  matchRoom: (amount: number, walletType: string) => void;
}

export default function Bet({ CloseBetComponent, matchRoom }: BetProps) {
  const [amount, setAmount] = useState(0);
  const [userBalance, setUserBalance] = useState(0);
  const [walletType, setWalletType] = useState<string>();
  const [error, setError] = useState<string | null>(null);
  const [pageContent, setPageContent] = useState<PageContent>();
  const [friendDropdown, setFriendDropdown] = useState<string>();

  const getContent = async () => {
    try {
      const page = "/bet";
      const language = localStorage.getItem("activeLanguage") ? localStorage.getItem("activeLanguage") : localStorage.getItem("i18nextLng");
      const {
        data: { content },
        status,
      } = await cmsInstance.get(`/content${page}/${language}`);
      if (status === 200) {
        setPageContent(content);
        setFriendDropdown(content.selectBet);
      }
    } catch (errorMsg) {
      console.error("Error", (errorMsg as Error).message);
    }
  };

  const getUserProfile = async () => {
    getContent();
    const response = await userInstance.get("/getprofile");
    if (response.data.code === 200) {
      if (response.data.profile_data.deposit[0].isActive === true) {
        setUserBalance(response.data.profile_data.deposit[0].USD);
        setWalletType("Deposit");
      } else {
        setUserBalance(response.data.profile_data.bonus[0].USD);
        setWalletType("Bonus");
      }
    }
  };

  useEffect(() => {
    socket.on("betAmount", () => {});
    getUserProfile();
  }, []);

  const closeError = () => {
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (walletType === "Deposit" && userBalance >= amount && amount > 0) {
      matchRoom(amount, walletType);
    } else if (walletType === "Bonus" && (userBalance * 50) / 100 >= amount && amount > 0) {
      matchRoom(amount, walletType);
    } else {
      if (amount <= 0) {
        setError("Please Select Bet Amount");
      } else if (walletType === "Bonus" && (userBalance * 50) / 100 < amount) {
        setError(" Only 50% can be used from Bonus wallet for match Making ");
      } else {
        setError(" Insufficient Amount in Your Wallet ");
      }
      closeError();
    }
  };

  const handleChange = (i: number) => {
    setAmount(i);
    setFriendDropdown(`${i}USD`);
  };

  if (!pageContent) {
    return <div>Page not found</div>;
  }
  return (
    <Modal
      size="sm"
      show
      backdrop="static"
      className="bet-popup"
      onHide={() => CloseBetComponent()}
    >
      <Modal.Header>
        <Modal.Title>{pageContent.betAmount}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>{pageContent.betAmount}</Form.Label>
              <DropdownButton
                id="formBasicCountry"
                title={friendDropdown}
              >
                <Dropdown.Item
                  value="0.5"
                  onClick={() => handleChange(0.5)}
                >
                  0.5 USD
                </Dropdown.Item>
                <Dropdown.Item
                  value="1"
                  onClick={() => handleChange(1)}
                >
                  1 USD
                </Dropdown.Item>
                <Dropdown.Item
                  value="2"
                  onClick={() => handleChange(2)}
                >
                  2 USD
                </Dropdown.Item>
                <Dropdown.Item
                  value="5"
                  onClick={() => handleChange(5)}
                >
                  5 USD
                </Dropdown.Item>
                <Dropdown.Item
                  value="10"
                  onClick={() => handleChange(10)}
                >
                  10 USD
                </Dropdown.Item>
                <Dropdown.Item
                  value="25"
                  onClick={() => handleChange(25)}
                >
                  25 USD
                </Dropdown.Item>
                <Dropdown.Item
                  value="50"
                  onClick={() => handleChange(50)}
                >
                  50 USD
                </Dropdown.Item>
                <Dropdown.Item
                  value="100"
                  onClick={() => handleChange(100)}
                >
                  100 USD
                </Dropdown.Item>
              </DropdownButton>
              <div className="m-2">
                <Button
                  className="red-btn"
                  type="submit"
                  size="sm"
                >
                  {pageContent.submit}
                </Button>
                <Button
                  className="blue-btn"
                  onClick={() => CloseBetComponent()}
                  size="sm"
                >
                  {pageContent.close}
                </Button>
              </div>
            </Form.Group>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
