import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./notificationpage.css";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import Table from "react-bootstrap/Table";
import Layout from "../layout";
import { userInstance, cmsInstance } from "../../axios/axiosconfig";

function Notificationpage() {
  const { i18n } = useTranslation();
  const [notificationMsg, setNotificationMsg] = useState(null);
  const [pageContent, setPageContent] = useState({});

  const getContent = async () => {
    try {
      // const page = window.location.pathname.replace('/cms/', '');
      const page = "/notification";
      const language = localStorage.getItem("activeLanguage") ? localStorage.getItem("activeLanguage") : localStorage.getItem("i18nextLng");
      const {
        data: { content, msg },
        status,
      } = await cmsInstance.get(`/content${page}/${language}`);
      if (status === 200) {
        setPageContent(content);
      } else {
        NotificationManager.error(msg, "Message", 10000);
      }
    } catch (error) {
      console.error("Error", error.message);
    }
  };
  const onLanguageChanged = () => {
    getContent();
  };

  useEffect(() => {
    const getNotificationMsg = async () => {
      const res = await userInstance.post("/getNotification");
      if (res.data.code === 200) {
        setNotificationMsg(res.data.notification);
      }
    };
    getNotificationMsg();
    const msgRead = async () => {};
    setTimeout(() => {
      msgRead();
    }, 5000);
    getContent();
    i18n.on("languageChanged", onLanguageChanged);
  }, []);

  return (
    <Layout>
      <div className="notificationpage">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-page">
                <ul className="breadcrum">
                  <li>
                    <Link to="/">{pageContent.home}</Link>
                  </li>
                  <li>{pageContent.notification}</li>
                </ul>
                <h2>{pageContent.notification}</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="home-table">
                <div className="responsive-table">
                  {notificationMsg && notificationMsg ? (
                    <Table bordered>
                      <thead>
                        <tr>
                          <th>{pageContent.message}</th>
                          <th>{pageContent.time}</th>
                          <th>{pageContent.date}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {notificationMsg &&
                          notificationMsg.map((ele) => (
                            <tr key={ele._id}>
                              <td className="text-truncate">{ele.msg}</td>
                              <td>{ele.createdAt.substring(11, 16)}</td>
                              <td>{ele.createdAt.substring(0, 10)}</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  ) : (
                    <h3>{pageContent.nonotification}</h3>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Notificationpage;
