import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { Tab, Nav } from "react-bootstrap";
import { userInstance, cmsInstance } from "../../axios/axiosconfig";
import PlayerList from "./components/player-list";
import Layout from "../layout";
import Routes from "../../config/routes";

function Friend() {
  const history = useHistory();
  const [allPlayers, setAllPlayers] = useState({});
  const [key, setKey] = useState("allPlayers");
  const [disabled, setDisabled] = useState(false);
  const [pageContent, setPageContent] = useState({});
  const { i18n } = useTranslation();

  const getContent = async () => {
    try {
      // const page = window.location.pathname.replace('/cms/', '');
      const page = "/friend";
      const language = localStorage.getItem("activeLanguage") ? localStorage.getItem("activeLanguage") : localStorage.getItem("i18nextLng");
      const {
        data: { content, msg },
        status,
      } = await cmsInstance.get(`/content${page}/${language}`);
      if (status === 200) {
        setPageContent(content);
      } else {
        NotificationManager.error(msg, "Message", 10000);
      }
    } catch (error) {
      console.error("Error", error.message);
    }
  };

  const onLanguageChanged = () => {
    getContent();
  };

  const getAllPlayers = async () => {
    const res = await userInstance.get("/allPlayers");
    if (res.data.code === 200) {
      setAllPlayers(res.data);
    } else {
      history.push(Routes.Login);
    }
  };

  useEffect(() => {
    getContent();
    i18n.on("languageChanged", onLanguageChanged);
    getAllPlayers();
  }, []);

  const handleAddFriend = async (id) => {
    const payload = {
      friendId: id,
    };
    setDisabled(true);
    const res = await userInstance.post("/sendRequest", payload);
    if (res.data.code === 200) {
      setDisabled(false);
      setAllPlayers(res.data);
      // setFullList(res.data);
      NotificationManager.success(pageContent.notification1, "Message", 4000);
    } else {
      NotificationManager.error(pageContent.notification2, "Message", 4000);
    }
  };
  const handleCancelSendRequest = async (id) => {
    const payload = {
      requestid: id,
    };
    setDisabled(true);
    const res = await userInstance.post("/cancelSendRequest", payload);
    if (res.data.code === 200) {
      setDisabled(false);
      setAllPlayers(res.data);
      // setFullList(res.data);
      NotificationManager.success(pageContent.notification3, "Message", 4000);
    }
  };

  const handleCancelReceiveRequest = async (id) => {
    const payload = {
      requestid: id,
    };
    setDisabled(true);
    const res = await userInstance.post("/cancelReceiveRequest", payload);
    if (res.data.code === 200) {
      setDisabled(false);
      setAllPlayers(res.data);
      // setFullList(res.data);
      NotificationManager.success(pageContent.notification4, "Message", 4000);
    }
  };
  const handleAcceptRequest = async (id) => {
    const payload = {
      requestid: id,
    };
    setDisabled(true);
    const res = await userInstance.post("/acceptReceiveRequest", payload);
    if (res.data.code === 200) {
      setDisabled(false);
      setAllPlayers(res.data);
      // setFullList(res.data);
      NotificationManager.success(pageContent.notification5, "Message", 4000);
    }
  };
  const handleUnFriend = async (id) => {
    const payload = {
      requestid: id,
    };
    setDisabled(true);
    const res = await userInstance.post("/unfriendRequest", payload);
    if (res.data.code === 200) {
      setDisabled(false);
      setAllPlayers(res.data);
      // setFullList(res.data);
      NotificationManager.success(pageContent.notification6, "Message", 4000);
    }
  };
  return (
    <Layout>
      <section className="player-page">
        <div className="container">
          <div className="card__content">
            {/* {isLoading && (<SectionHeader title={pageContent.player_list}
                   query={query}
                   filter={filter}
                   content = {content}
                   handleFilter={handleFilter}
                    handleSearch={handleSearch} />)}
                    <br/><br/> */}
            <Tab.Container
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Nav
                variant="pills"
                className=""
              >
                <Nav.Item>
                  <Nav.Link eventKey="allPlayers">{pageContent.allplayers}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="friends">{pageContent.friends}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="sendRequest">{pageContent.send_request}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="receivedRequest">{pageContent.received_request}</Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content>
                <Tab.Pane eventKey="allPlayers">
                  {key === "allPlayers" ? (
                    <PlayerList
                      allPlayers={allPlayers}
                      content={pageContent}
                      handleAddFriend={handleAddFriend}
                      handleUnFriend={handleUnFriend}
                      handleCancelSendRequest={handleCancelSendRequest}
                      handleCancelReceiveRequest={handleCancelReceiveRequest}
                      handleAcceptRequest={handleAcceptRequest}
                      disabled={disabled}
                      tab="player"
                    />
                  ) : (
                    ""
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="friends">
                  {key === "friends" ? (
                    <PlayerList
                      allPlayers={allPlayers}
                      handleUnFriend={handleUnFriend}
                      content={pageContent}
                      disabled={disabled}
                      tab="friend"
                    />
                  ) : (
                    ""
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="sendRequest">
                  {key === "sendRequest" ? (
                    <PlayerList
                      allPlayers={allPlayers}
                      handleCancelSendRequest={handleCancelSendRequest}
                      content={pageContent}
                      disabled={disabled}
                      tab="send"
                    />
                  ) : (
                    ""
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="receivedRequest">
                  {key === "receivedRequest" ? (
                    <PlayerList
                      allPlayers={allPlayers}
                      handleCancelReceiveRequest={handleCancelReceiveRequest}
                      handleAcceptRequest={handleAcceptRequest}
                      content={pageContent}
                      disabled={disabled}
                      tab="received"
                    />
                  ) : (
                    ""
                  )}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Friend;
