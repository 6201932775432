import styled from "styled-components";
import SelectInput from "./SelectInput";

const SelectInputStyled = styled(SelectInput)`
  display: flex;
  flex-direction: column;

  label {
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fonts.robotoBold};
  }

  .react-select__control {
    background: transparent;
    border-radius: 3px;
    border: 1px solid ${({ theme }) => theme.colors.white};
    padding: 3px 10px;
    font-size: ${({ theme }) => theme.fontSizes.input};
    color: ${({ theme }) => theme.colors.grey};
    border-image-slice: 1 !important;
    border-width: 1px !important;
    border-image-source: linear-gradient(to left, #3d4253, #ffffff, #3d4253) !important;
  }

  .react-select__input-container,
  .react-select__single-value {
    color: ${({ theme }) => theme.colors.grey};
  }

  .error {
    color: ${({ theme }) => theme.colors.red};
    font-family: ${({ theme }) => theme.fonts.robotoBold};
    height: 20px;
  }
`;

export default SelectInputStyled;
