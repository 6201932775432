import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import "./aboutus.css";
import Layout from "../layout";
import about from "../../assets/images/home/about.jpg";
import { cmsInstance } from "../../axios/axiosconfig";

function AboutUs() {
  const { i18n } = useTranslation();
  const [pageData, setPageData] = useState({});

  const getContent = async () => {
    try {
      const page = window.location.pathname.replace("/cms/", "");
      const { language } = i18n;
      const {
        data: { content, msg },
        status,
      } = await cmsInstance.get(`/content${page}/${language}`);
      if (status === 200) {
        setPageData(content);
      } else {
        NotificationManager.error(msg, "Message", 10000);
      }
    } catch (error) {
      console.error("Error", error.message);
    }
  };
  const onLanguageChanged = () => {
    getContent();
  };

  useEffect(() => {
    getContent();
    i18n.on("languageChanged", onLanguageChanged);
  }, []);

  return (
    <Layout>
      <div className="aboutus">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-page">
                <ul className="breadcrum">
                  <li>
                    <Link to="/">{pageData.home}</Link>
                  </li>
                  <li>{pageData.aboutus}</li>
                </ul>
                <h2>{pageData.aboutus}</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="aboutus-descp">
                <h4>{pageData.subheading}</h4>
                <p>{pageData.aboutheading}</p>
              </div>
              <div className="about-list">
                <ul className="about-f">
                  <li>{pageData.stringcreate}</li>
                  <li>{pageData.stringdescription}</li>
                </ul>
              </div>
            </div>

            <div className="col-md-6">
              <img
                className="about-image"
                src={about}
                alt="aboutprofile"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AboutUs;
