import React from "react";
import playstore from "../../../assets/images/home/playstore.png";
import appstore from "../../../assets/images/home/appstore.png";
import "../../home/home.css";

interface MobileAppLinkProps {
  playstoreURL: string;
  appstoreURL: string;
}

function MobileAppLink({ playstoreURL, appstoreURL }: MobileAppLinkProps) {
  return (
    <div className="mobile-app-link">
      <span>Pobierz na urządzenie mobilne</span>
      <div className="mobile-app-img">
        <a href={playstoreURL}>
          <img
            src={playstore}
            alt="playstore"
          />
        </a>
      </div>
      <div className="mobile-app-img">
        <a href={appstoreURL}>
          <img
            src={appstore}
            alt="appstore"
          />
        </a>
      </div>
    </div>
  );
}

export default MobileAppLink;
