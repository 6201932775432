import React from "react";
import tryicon from "../../../assets/images/home/try-icon.png";
import manyicon from "../../../assets/images/home/many-icon.png";
import duel from "../../../assets/images/home/duel.png";
import tournament from "../../../assets/images/home/tournament.png";
import newtournament from "../../../assets/images/home/new-tournament.png";
import multiplayerImg from "../../../assets/images/home/multiplayer.png";
import "../../home/home.css";

interface Content {
  playduel: string;
  try: string;
  searchtournament: string;
  newtournament: string;
  game1: string;
  para1: string;
}

interface PopularGameBoxProps {
  index: string;
  imageSrc: string;
  count?: string;
  tryButtonOnClick: () => void;
  content: Content;
  duelButtonClick: () => void;
  tournamentButtonClick: () => void;
  multiplayer?: boolean;
}

function PopularGameBox({ index, imageSrc, tryButtonOnClick, count = "100", content, duelButtonClick, tournamentButtonClick, multiplayer = false }: PopularGameBoxProps) {
  return (
    <div className={`col-md-3 popular-${index}`}>
      <div className="popular-box">
        <div className="game-img">
          <img
            src={imageSrc}
            alt="pool"
          />
          <button
            type="button"
            className="try"
            onClick={tryButtonOnClick}
          >
            <img
              src={tryicon}
              alt=""
            />
            <span>{content && content.try}</span>
          </button>
        </div>
        <div className="many-players">
          <img
            src={manyicon}
            alt=""
          />
          {count}
        </div>

        <div className={multiplayer ? "game-action winter-game" : "game-action"}>
          <button
            type="button"
            className="duel-action"
            onClick={duelButtonClick}
          >
            <img
              src={duel}
              alt=""
            />
            <span>{content && content.playduel}</span>
          </button>
          <div className="tournament-action">
            <img
              src={tournament}
              alt=""
            />
            <span>{content && content.searchtournament}</span>
          </div>
          {multiplayer && (
            <div className="multiplayer-action">
              <img
                src={multiplayerImg}
                alt=""
              />
              <span>Multiplayer </span>
            </div>
          )}
          <button
            type="button"
            className="newtournament-action"
            onClick={tournamentButtonClick}
          >
            <img
              src={newtournament}
              alt=""
            />
            <span>{content && content.newtournament}</span>
          </button>
        </div>
        <div className="game-info">
          <h4>{content && content.game1}</h4>
          <p>{content && content.para1}</p>
        </div>
      </div>
    </div>
  );
}

export default PopularGameBox;
