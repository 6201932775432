import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { FacebookShareButton, EmailShareButton, WhatsappShareButton } from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "./choose.css";
import { Form, Button } from "react-bootstrap";
import { cmsInstance } from "../../axios/axiosconfig";
import { server } from "../../config/config";

function InviteFriends() {
  const [copy, setcopy] = useState(false);
  const [copytext, setcopytext] = useState(null);
  const [pageContent, setPageContent] = useState({});
  const getContent = async () => {
    try {
      // const page = window.location.pathname.replace('/cms/', '');
      const page = "/invitefriends";
      const language = localStorage.getItem("activeLanguage") ? localStorage.getItem("activeLanguage") : localStorage.getItem("i18nextLng");
      const {
        data: { content, msg },
        status,
      } = await cmsInstance.get(`/content${page}/${language}`);
      if (status === 200) {
        setPageContent(content);
      } else {
        NotificationManager.error(msg, "Message", 10000);
      }
    } catch (error) {
      console.error("Error", error.message);
    }
  };
  useEffect(() => {
    setcopytext(`${server}/user/invite/${localStorage.getItem("Userid")}`);
    setcopy(false);
    getContent();
  }, [copy]);

  return (
    <div className="invite-friend">
      <div className="invite-friend-content">
        <h2>{pageContent.heading1}</h2>

        <Form>
          <Form.Group className="max-300">
            <Form.Label>{pageContent.label1}</Form.Label>
            <Form.Control
              type="text"
              placeholder={`${server}/user/invite/${localStorage.getItem("Userid")}`}
              value={`${server}/user/invite/${localStorage.getItem("Userid")}`}
            />

            <CopyToClipboard
              text={copytext}
              onCopy={() => setcopy({ copy: true })}
            >
              <button
                type="button"
                onClick={() => {
                  NotificationManager.success("Referal Link Copied", "Message", 10000);
                }}
              >
                <i className="fa fa-copy" />
              </button>
            </CopyToClipboard>
          </Form.Group>

          <Form.Group className="clear">
            <Form.Label className="text-center">{pageContent.label2}</Form.Label>

            <div className="entry-fee">
              <div className="entry-box">
                <FacebookShareButton
                  tite="Day of Dual"
                  url={`https://demo.dayofduel.com/user/invite/${localStorage.getItem("Userid")}`}
                  quote="Check this new Gaming Website where I Have earned a lot."
                >
                  <Button varaint="outline-secondary">
                    <i
                      className="fa fa-facebook-square"
                      aria-hidden="true"
                    />
                  </Button>
                </FacebookShareButton>

                <span>{pageContent.label3}</span>
              </div>

              <div className="entry-box">
                <WhatsappShareButton
                  tite="Day of Dual"
                  url={`https://demo.dayofduel.com/user/invite/${localStorage.getItem("Userid")}`}
                  quote="Check this new Gaming Website where I Have earned a lot."
                >
                  <Button varaint="outline-secondary">
                    <i
                      className="fa fa-whatsapp"
                      aria-hidden="true"
                    />
                  </Button>
                </WhatsappShareButton>
                <span>{pageContent.label4}</span>
              </div>

              <div className="entry-box">
                <EmailShareButton
                  subject="Check out what I did on GoodWerk"
                  // eslint-disable-next-line prettier/prettier
                  body={`Check this new Gaming Website where I Have earned a lot.: ${server}/user/invite/${localStorage.getItem("Userid")}`}
                >
                  <Button varaint="outline-secondary">
                    <i
                      className="fa fa-envelope-o"
                      aria-hidden="true"
                    />
                  </Button>
                </EmailShareButton>

                <span>{pageContent.label5}</span>
              </div>
            </div>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
}

export default InviteFriends;
