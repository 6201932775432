import React, { useState } from "react";
import Select from "react-select";

interface Option {
  label: string;
  value: string;
}

export interface SelectInterface {
  type: "select";
  label: string;
  id: string;
  placeholder: string;
  name: string; // name has to be the same as formik value key
  value: string | boolean;
  onChange: (option: Option) => void;
  options: Option[];
  error: string;
}

interface SelectInputProps {
  item: SelectInterface;
  className?: string;
}

function SelectInput({ item, className = "" }: SelectInputProps) {
  const { label, id, options, error, onChange } = item;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedOption, setSelectedOption] = useState<Option>();

  const onCountrySelect = (option: Option) => {
    setSelectedOption(option);
    onChange(option);
  };

  return (
    <div className={className}>
      <label htmlFor={id}>{label}</label>
      <Select
        className="react-select-container"
        classNamePrefix="react-select"
        defaultValue={selectedOption}
        onChange={(option: Option) => onCountrySelect(option)}
        options={options}
      />
      <p className="error">{error}</p>
    </div>
  );
}

export default SelectInput;
