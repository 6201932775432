import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import Helmet from "react-helmet";
import { Button } from "react-bootstrap";
import Layout from "../layout";
import Timer from "./Timer";
import Popup from "./popup";
import Bet from "./bet";
import "./home.css";
import "react-accessible-accordion/dist/fancy-example.css";
import { userInstance, adminInstance, cmsInstance } from "../../axios/axiosconfig";
import PopularGames from "./populargames";
import HowTournament from "./howtotournament";
import DayOfDuel from "./dayofduel";
import HomeTiles from "./hometiles";
import GameTable from "./gametable";

let message;
let nomatch;
function Home({ socket }) {
  const [gametableContent, setgametableContnet] = useState({});
  const { i18n } = useTranslation();
  const [pageContent, setPageContent] = useState({});
  const [level, setLevel] = useState(null);
  const [nickName, setNickName] = useState(null);
  const [country, setCountry] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [startTimer, setstartTimer] = useState(false);
  const [matched, setMatched] = useState(false);
  const [roomId, setRoomId] = useState(null);
  const Userid = localStorage.getItem("Userid");
  const [playerOne, setPlayerOne] = useState(null);
  const [playerTwo, setPlayerTwo] = useState(null);
  // const [playerDetails, setPlayerDetails] = useState(null);
  const [banned, setBanned] = useState(null);
  const [bet, setBet] = useState(false);
  const [betAmount, setBetAmount] = useState(null);
  const [wallettype, setWallettype] = useState(null);
  const [loading, setLoading] = useState(false);
  const [gametype, setGameType] = useState("Pong");

  // const loadRoom = async () => {
  //   const { data } = await userInstance.post("/createRoom");
  //   const { roomId } = data;
  //   if (roomId) {
  //     window.location.href = `/winterGame?roomId=${roomId}`;
  //   } else {
  //     window.location.href = "/login";
  //   }
  // };
  const handleBetAmount = () => {
    // if(localStorage.getItem('token')){
    //   setBet(true);
    // }else{
    //   window.location.href = '/login';
    // }
    const elmnt = document.getElementById("populargame");
    elmnt.scrollIntoView();
  };

  const CloseBetComponent = () => {
    setBet(false);
  };

  const matchRoom = async (amount, walletType) => {
    const res = await adminInstance.post("/getCommission");
    const cutOff = ((res.data.rate * amount) / 100).toFixed(2);
    // const response = await userInstance.post("/getprofile");
    socket.emit("matchMaking", {
      Userid,
      level,
      socketId: socket.id,
      bet: amount,
      adminCommission: cutOff,
      betAmt: amount - cutOff,
      walletType,
      nickName,
      country,
      photo,
      gameType: gametype,
      platform: "web",
    });
    setBetAmount(amount);
    setBet(false);
    setWallettype(walletType);
    setMatched(true);
  };
  const handleTimerPopup = (e) => {
    if (e === "close") {
      setstartTimer(false);
      // socket.emit("cancel", {
      //   Userid
      // });
    }
  };
  const getgameTableContent = async () => {
    try {
      // const page = window.location.pathname.replace('/cms/', '');
      const page = "/gametable";
      const language = localStorage.getItem("activeLanguage") ? localStorage.getItem("activeLanguage") : localStorage.getItem("i18nextLng");
      const {
        data: { content, msg },
        status,
      } = await cmsInstance.get(`/content${page}/${language}`);
      if (status === 200) {
        setgametableContnet(content);
        setLoading(true);
      } else {
        NotificationManager.error(msg, "Message", 10000);
      }
    } catch (error) {
      console.error("Error", error.message);
    }
  };
  const getContent = async () => {
    try {
      // const page = window.location.pathname.replace('/cms/', '');
      const page = "/home";
      const language = localStorage.getItem("activeLanguage") ? localStorage.getItem("activeLanguage") : localStorage.getItem("i18nextLng");
      const {
        data: { content, msg },
        status,
      } = await cmsInstance.get(`/content${page}/${language}`);
      if (status === 200) {
        nomatch = content.home.nomatch;
        message = content.home.message;
        setPageContent(content);
        setLoading(true);
      } else {
        NotificationManager.error(msg, "Message", 10000);
      }
    } catch (error) {
      console.error("Error", error.message);
    }
  };

  const onLanguageChanged = () => {
    getContent();
    getgameTableContent();
  };

  useEffect(() => {
    getContent();
    getgameTableContent();
    i18n.on("languageChanged", onLanguageChanged);

    const getUserProfile = async () => {
      const response = await userInstance.get("/getprofile");
      if (response.data.code === 200) {
        setBanned(response.data.profile_data.isbanned);

        setLevel(response.data.profile_data.Level);
        setPlayerOne(response.data.profile_data);
        setNickName(response.data.profile_data.firstName);
        setCountry(response.data.profile_data.country);
        setPhoto(response.data.profile_data.photo);
      }
    };

    getUserProfile();
  }, []);
  const { home, dayofduel, hometiles, howtournament, populargames } = pageContent;
  useEffect(() => {
    // socket.emit("disconnect", {
    //   Userid
    // });
    socket.on("playerPopUp", (data) => {
      if (Userid === data.matched[0].Userid) {
        setPlayerOne(data.matched[0]);
        setPlayerTwo(data.matched[1]);
      } else {
        setPlayerOne(data.matched[1]);
        setPlayerTwo(data.matched[0]);
      }
    });

    socket.on("play", (data) => {
      // loadRoom();
      // setPlayerDetails(data.matched);
      setRoomId(data.roomId);
      setstartTimer(false);
      handleTimerPopup("close");
      setMatched(true);
      // setBet(true);

      socket.emit("server", {
        data,
      });

      socket.emit("betAmount", () => {
        setBet(false);
      });
    });
    socket.on("winterplay", (data) => {
      // loadRoom();
      // setPlayerDetails(data.matched);
      setRoomId(data.roomId);
      setstartTimer(false);
      handleTimerPopup("close");
      setMatched(true);
      // setBet(true);

      socket.emit("server", {
        data,
      });

      socket.emit("betAmount", () => {
        setBet(false);
      });
    });
    socket.on("noMatch", () => {
      NotificationManager.error(nomatch, message);
      setMatched(false);
    });
  }, [startTimer]);

  return (
    <Layout>
      <Helmet>
        <body className="home-page" />
      </Helmet>

      <div className="home">
        {matched && (
          <Popup
            roomId={roomId}
            setMatched={setMatched}
            playerOne={playerOne}
            playerTwo={playerTwo}
            socket={socket}
            amount={betAmount}
            wallettype={wallettype}
            gameType={gametype}
          />
        )}

        {bet && (
          <Bet
            socket={socket}
            matchRoom={matchRoom}
            CloseBetComponent={CloseBetComponent}
          />
        )}

        <div className="home-banner">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="home-text">
                  <h2>{home && home.futureexperience}</h2>
                  <h3>{home && home.para1}</h3>
                  <div className="home-btns">
                    {banned ? (
                      <h1>{home && home.banned}</h1>
                    ) : (
                      <Button
                        className="red-btn mt-1"
                        onClick={handleBetAmount}
                      >
                        {home && home.startplaying}
                      </Button>
                    )}
                    {startTimer && (
                      <Timer
                        startTimer={startTimer}
                        socket={socket}
                        handleTimerPopup={handleTimerPopup}
                      />
                    )}
                    <Button className="border-btn mt-1">
                      <Link to="/howitworks">{home && home.howitworks}</Link>
                    </Button>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <GameTable content={gametableContent} />
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <>
            <div className="gamepad-bg">
              <HomeTiles content={hometiles} />
              <div id="populargame">
                <PopularGames
                  content={populargames}
                  socket={socket}
                  setBet={setBet}
                  setGameType={setGameType}
                />
              </div>
            </div>
            <HowTournament
              content={howtournament}
              loadRoom={handleBetAmount}
            />
            <DayOfDuel content={dayofduel} />
          </>
        ) : (
          ""
        )}
      </div>
    </Layout>
  );
}

export default Home;
