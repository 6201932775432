import React from "react";

function ContentBox({ header, paragraph, imageSrc, reversed }) {
  const image = (
    <div className="col-md-6">
      <img
        className="feature-img"
        src={imageSrc}
        alt=""
      />
    </div>
  );

  const description = (
    <div className="col-md-6">
      <div className="feature-descp">
        <h4>{header}</h4>
        <p>{paragraph}</p>
      </div>
    </div>
  );

  if (reversed) {
    return (
      <div className="f-content">
        <div className="row">
          {image}
          {description}
        </div>
      </div>
    );
  }

  return (
    <div className="f-content">
      <div className="row">
        {description}
        {image}
      </div>
    </div>
  );
}

export default ContentBox;
