import styled from "styled-components";
import CheckBox from "./CheckBox";

const CheckBoxStyled = styled(CheckBox)`
  div {
    display: flex;
  }

  input {
    width: 15px;
    height: 15px;
    border: 1px solid #fff;
    -webkit-appearance: none;
    margin-top: 0;
    position: relative;
  }

  input:checked:after {
    content: "\\2713";
    color: ${({ theme }) => theme.colors.white};
    display: block;
    font-size: 20px;
    position: absolute;
    top: -11px;
    left: 1px;
  }

  label {
    font-size: 12px;
    padding-left: 10px;
    color: ${({ theme }) => theme.colors.lightGrey};
    font-family: ${({ theme }) => theme.fonts.roboto};
    max-width: 650px;
    margin-bottom: 0;
  }

  .error {
    color: ${({ theme }) => theme.colors.red};
    font-family: ${({ theme }) => theme.fonts.robotoBold};
    height: 20px;
    margin-left: 15px;
  }
`;

export default CheckBoxStyled;
