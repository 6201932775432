import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./transaction.css";
import Table from "react-bootstrap/Table";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import Layout from "../layout";
import { userInstance, cmsInstance } from "../../axios/axiosconfig";

function UserTransaction() {
  const { i18n } = useTranslation();
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [pageContent, setPageContent] = useState({});
  const [page, setPage] = useState(1);

  const getTransaction = async (pagevalue, paginationvalue) => {
    setPage(pagevalue);
    const payload = {
      page: pagevalue,
      pagination: paginationvalue,
    };
    const res = await userInstance.post("/getTransactionuser", payload);
    if (res.data.code === 200) {
      setTransactionDetails(res.data.list);
    }
  };

  const getContent = async () => {
    try {
      // const page = window.location.pathname.replace('/cms/', '');
      const language = localStorage.getItem("activeLanguage") ? localStorage.getItem("activeLanguage") : localStorage.getItem("i18nextLng");
      const {
        data: { content, msg },
        status,
      } = await cmsInstance.get(`/content${page}/${language}`);
      if (status === 200) {
        setPageContent(content);
      } else {
        NotificationManager.error(msg, "Message", 10000);
      }
    } catch (error) {
      console.error("Error", error.message);
    }
  };

  const onLanguageChanged = () => {
    getContent();
  };

  useEffect(() => {
    getTransaction(1, 10);
    getContent();
    i18n.on("languageChanged", onLanguageChanged);
  }, []);

  return (
    <Layout>
      <div className="transactionpage">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-page">
                <ul className="breadcrum">
                  <li>
                    <Link to="/">{pageContent.home}</Link>
                  </li>
                  <li>{pageContent.transactions}</li>
                </ul>
                <h2>{pageContent.transactions}</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="home-table">
                <div className="responsive-table">
                  <Table
                    bordered
                    responsive
                  >
                    <thead>
                      <tr>
                        <th>{pageContent.transactionid}</th>
                        <th>{pageContent.transactiontype}</th>
                        <th>{pageContent.amount}</th>

                        <th>{pageContent.time}</th>
                        <th>{pageContent.date}</th>
                        <th>{pageContent.source}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactionDetails && transactionDetails.length ? (
                        <>
                          {" "}
                          {transactionDetails.map((obj) => (
                            <tr
                              key={obj._id}
                              className={obj.addRemove === "+" ? "red" : "green"}
                            >
                              <td>{obj._id}</td>
                              <td>{obj.isDeposit ? pageContent.deposit : pageContent.withdraw}</td>
                              <td className="amount">{`${obj.addRemove} ${obj.amount} USD`}</td>

                              <td>{new Date(obj.createdAt).toLocaleTimeString()}</td>
                              <td>{obj.createdAt.substring(0, 10)}</td>
                              <td>{obj.gameType ? obj.gameType : obj.source}</td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <thead>
                          <h3>{pageContent.notransaction}</h3>
                        </thead>
                      )}
                    </tbody>
                  </Table>
                  <div className="pagination-section">
                    {page > 1 ? (
                      <button
                        type="button"
                        onClick={() => getTransaction(page - 1, 10)}
                        className="previous"
                      >
                        {pageContent.previous}
                      </button>
                    ) : (
                      ""
                    )}
                    {transactionDetails && transactionDetails.length === 10 ? (
                      <button
                        type="button"
                        onClick={() => getTransaction(page + 1, 10)}
                        className="next"
                      >
                        {pageContent.next}
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default UserTransaction;
