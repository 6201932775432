import React from "react";
import Slider from "react-slick";
import tile1 from "../../assets/images/home/tile-4.png";
import SliderImage from "./SliderImage";

const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 500000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

function Hometiletwo({ content }) {
  return (
    <div className="home-tile-slider">
      <Slider {...settings}>
        <SliderImage
          src={tile1}
          spanOne={content && content.letgame}
          spanTwo={content && content.withfriends}
          tile={4}
        />
        <SliderImage
          src={tile1}
          spanOne={content && content.letgame}
          spanTwo={content && content.withfriends}
          tile={4}
        />
        <SliderImage
          src={tile1}
          spanOne={content && content.letgame}
          spanTwo={content && content.withfriends}
          tile={4}
        />
        <SliderImage
          src={tile1}
          spanOne={content && content.letgame}
          spanTwo={content && content.withfriends}
          tile={4}
        />
      </Slider>
    </div>
  );
}
export default Hometiletwo;
