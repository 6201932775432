import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import queryString from "query-string";
// import { CANVAS } from "../../data/canvas";
import socket from "../../config/socket";
import { userInstance } from "../../axios/axiosconfig";
import { server } from "../../config/config";

const DEFAULT_STATE = {
  promoted: false,
  nextRoundPromo: false,
  roomId: "",
  gameType: "",
  loserPanel: false,
  winnerPanel: false,
};

function Pong() {
  useEffect(() => {
    socket.on("deniedConfirm", () => {
      window.location.href = "/";
    });
  }, [socket]);

  const [gameState, setGameState] = useState(DEFAULT_STATE);

  const findRoomIdInArray = (roomId: string | (string | null)[]) => {
    if (typeof roomId === "string") return roomId;
    roomId.forEach((item: string | null) => {
      if (item) {
        return item;
      }
    });
    return null;
  };

  const getRoomId = () => {
    const values = queryString.parse(window.location.search);
    if (values.roomId) {
      localStorage.setItem("offline", values.offline ? "true" : "false");
      const foundRoomId = findRoomIdInArray(values.roomId);
      if (foundRoomId) {
        localStorage.setItem("roomId", foundRoomId);
      }
      localStorage.setItem("dodAdress", `${server}/socket.io/`);
      return values;
    }
  };

  const forBetMoney = () => userInstance.post("/deductRematchBet").then((data) => data);
  const check = (values: queryString.ParsedQuery<string>) => userInstance.post("/checkRoom", values).then((data) => data);

  useEffect(() => {
    const encodedData = localStorage.getItem("Userid");
    const values = getRoomId();
    if (values) {
      window.unityInstance = window.UnityLoader.instantiate("unityContainer", "Pong Web/Build/Pong Web.json");
    }
    socket.on("rematchBet", () => {
      forBetMoney();
    });
    // next Round Socket
    socket.on("nextRound", (data: { roomId: string; gameType: string; userId: string }) => {
      if (!encodedData) return;
      if (data.userId.toString() === encodedData.toString()) {
        setGameState({ ...gameState, nextRoundPromo: true, roomId: data.roomId, gameType: data.gameType });
      }
    });

    // Promoted to next Round
    socket.on("promotedPlayer", (data: { roomId: string; gameType: string }) => {
      setGameState({ ...gameState, promoted: true, roomId: data.roomId, gameType: data.gameType });
    });
    // start next Round
    socket.on("startNextRound", () => {
      if (gameState.roomId) {
        setTimeout(() => {
          window.location.href = `/${gameState.gameType}?roomId=${gameState.roomId}`;
        }, 1000);
      } else {
        setGameState({ ...gameState, loserPanel: true });
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
      }
    });
    // winner Socket
    socket.on("Winner", () => {
      setGameState({ ...gameState, winnerPanel: true });
      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
    });

    const rid = values?.roomId;
    if (values?.tour_id) {
      check(values);
    }

    socket.on("welcome", () => {});
    socket.on("rematchShow", () => {});
    socket.on("connect", () => {});
    socket.on("players", () => {});
    socket.emit("stop", { roomId: rid });
    socket.on("request", () => {});
    socket.on("confirm", () => {
      window.location.reload();
    });

    socket.on("social", () => {});
  }, []);

  return (
    <>
      <div
        id="unityContainer"
        style={{ height: "100vh" }}
      />
      <Modal
        show={gameState.nextRoundPromo}
        onHide={() => {
          setGameState({ ...gameState, nextRoundPromo: true });
        }}
      >
        <ModalBody>
          <div>
            <h1>Winner Promoted to Next Round</h1>
            <h3>Next Round will start after all game on round 1 is finished</h3>
            <p>Do not close this popup</p>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        show={gameState.promoted}
        onHide={() => {
          setGameState({ ...gameState, promoted: true });
        }}
      >
        <ModalBody>
          <div>
            <h1>Promoted to Next Round </h1>
            <h3>Next Round will start after all game on round 1 is finished</h3>
            <p>Do not close this popup</p>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        show={gameState.loserPanel}
        onHide={() => {
          setGameState({ ...gameState, loserPanel: true });
        }}
      >
        <ModalBody>
          <div>
            <h1>You Lose this Round </h1>
            <h3>Will redirect to Home page in 1 sec</h3>
            <p>Do not close this popup</p>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        show={gameState.winnerPanel}
        onHide={() => {
          setGameState({ ...gameState, winnerPanel: true });
        }}
      >
        <ModalBody>
          <div>
            <h1>Tournament Final Match Finished </h1>
            <h3>Will redirect to Home page in 1 sec</h3>
            <p>Do not close this popup</p>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default Pong;
