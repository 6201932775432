import React from "react";
import "./home.css";

function HowToTournamentBox({ src, header, paragraph }) {
  return (
    <div className="col-md-2">
      <div className="how-tournament-box">
        <div className="how-tournament-icon">
          <img
            src={src}
            alt=""
          />
        </div>
        <div className="how-tournament-info">
          <h4>{header}</h4>
          <p>{paragraph}</p>
        </div>
      </div>
    </div>
  );
}

export default HowToTournamentBox;
