import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./forget.css";
// import logo from "../../assets/images/header/logo.png";
import { Form, Button } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { validateData } from "../../function/function";
import { userInstance, cmsInstance } from "../../axios/axiosconfig";
import Routes from "../../config/routes";

const url = window.location.href.split("/");
const hex = url[url.length - 1];

const INITIAL_STATE = {
  password: "",
  confirmPassword: "",
  hex,
};

function Resetpassword() {
  const history = useHistory();
  const [values, setValues] = useState(INITIAL_STATE);
  const [errPassword, setErrPassword] = useState("");
  const [errConfirmPass, setErrConfirmPass] = useState("");
  const [pageContent, setPageContent] = useState({});
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const getContent = async () => {
    try {
      // const page = window.location.pathname.replace('/cms/', '');
      const page = "/reset";
      const language = localStorage.getItem("activeLanguage") ? localStorage.getItem("activeLanguage") : localStorage.getItem("i18nextLng");
      const {
        data: { content, msg },
        status,
      } = await cmsInstance.get(`/content${page}/${language}`);
      if (status === 200) {
        setPageContent(content);
      } else {
        NotificationManager.error(msg, "Message", 10000);
      }
    } catch (error) {
      console.error("Error", error.message);
    }
  };

  useEffect(() => {
    getContent();
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();

    const isValid = validateData([values.password, values.confirmPassword, values.hex]);
    if (isValid) {
      // eslint-disable-next-line prefer-regex-literals
      const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8})");
      if (values.password.length < 8 || !strongRegex.test(values.password)) {
        setErrPassword(pageContent.errPassword);
      } else if (values.password === "") {
        setErrPassword(pageContent.errPassword);
      } else if (values.confirmPassword === "" || values.confirmPassword !== values.password) {
        setErrConfirmPass(pageContent.errConf1);
        setErrPassword("");
      } else {
        setErrConfirmPass("");
        setErrPassword("");
        const response = await userInstance.post("/forget", values);
        const statusCode = response.data.code;

        if (statusCode === 200) {
          setValues(INITIAL_STATE);
          NotificationManager.success(pageContent.notification1, "Message", 4000);
          history.push(Routes.Login);
        } else {
          NotificationManager.error(pageContent.notification2, "Message", 4000);
        }
      }
    } else {
      if (values.password === "") {
        setErrPassword(pageContent.errConf);
      } else {
        setErrPassword("");
      }
      if (values.confirmPassword === "") {
        setErrConfirmPass(pageContent.errConf);
      } else {
        setErrConfirmPass("");
      }
    }
  };

  return (
    <div className="forget">
      <div className="forget-section">
        <div className="container">
          <div classNmae="row">
            <div className="col-md-12">
              <div className="forget-form">
                <h1>
                  {pageContent.heading1}
                  <span>{pageContent.heading2}</span>
                </h1>
                <div className="forget-box">
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label>{pageContent.para1}</Form.Label>

                      {errPassword === "" ? (
                        <Form.Control
                          type="password"
                          placeholder={pageContent.placeholder1}
                          name="password"
                          onChange={handleChange}
                          value={values.password}
                        />
                      ) : (
                        <Form.Control
                          type="password"
                          className="showError"
                          placeholder={pageContent.placeholder1}
                          name="password"
                          onChange={handleChange}
                          value={values.password}
                        />
                      )}
                      <label
                        className="text-danger"
                        htmlFor="id"
                      >
                        {errPassword}
                      </label>
                    </Form.Group>
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label>{pageContent.para2}</Form.Label>

                      {errConfirmPass === "" ? (
                        <Form.Control
                          type="password"
                          placeholder={pageContent.placeholder2}
                          name="confirmPassword"
                          onChange={handleChange}
                          value={values.confirmPassword}
                        />
                      ) : (
                        <Form.Control
                          type="password"
                          placeholder={pageContent.placeholder2}
                          name="confirmPassword"
                          className="showError"
                          onChange={handleChange}
                          value={values.confirmPassword}
                        />
                      )}
                      <label
                        className="text-danger"
                        htmlFor="id"
                      >
                        {errConfirmPass}
                      </label>
                    </Form.Group>
                    <div className="forget-button">
                      <Button
                        type="submit"
                        className="red-btn"
                      >
                        {pageContent.para3}
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Resetpassword;
