import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// prettier-ignore
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemPanel, AccordionItemButton } from "react-accessible-accordion";
import { useTranslation } from "react-i18next";
import "./style.css";
import { NotificationManager } from "react-notifications";
import { cmsInstance } from "../../axios/axiosconfig";
import Layout from "../layout";

function Faqs() {
  const { i18n } = useTranslation();
  const [pageContent, setPageContent] = useState({});

  const getContent = async () => {
    try {
      const language = localStorage.getItem("activeLanguage");
      const {
        data: { content, msg },
        status,
      } = await cmsInstance.get(`/content/faq/${language}`);
      if (status === 200) {
        setPageContent(content);
      } else {
        NotificationManager.error(msg, "Message", 10000);
      }
    } catch (error) {
      console.error("Error", error.message);
    }
  };

  const onLanguageChanged = () => {
    getContent();
  };

  useEffect(() => {
    getContent();
    i18n.on("languageChanged", onLanguageChanged);
  }, []);

  return (
    <Layout>
      <div className="faq-bg">
        <section className="faqs">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="title-page">
                  <ul className="breadcrum">
                    <li>
                      <Link to="/">{pageContent.home}</Link>
                    </li>
                    <li>{pageContent.faq}</li>
                  </ul>
                  <h2>{pageContent.faq}</h2>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Accordion allowZeroExpanded="true">
                  <div className="col-md-6">
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{pageContent.heading1}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>{pageContent.para1}</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{pageContent.heading2}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>{pageContent.para2}</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{pageContent.heading3}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>{pageContent.para3}</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{pageContent.heading4}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>{pageContent.para4}</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{pageContent.heading5}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>{pageContent.para5}</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{pageContent.heading6}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>{pageContent.para6}</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{pageContent.heading7}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>{pageContent.para7}</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{pageContent.heading8}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>{pageContent.para8}</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{pageContent.heading9}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>{pageContent.para9}</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{pageContent.heading10}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>{pageContent.para10}</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{pageContent.heading11}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>{pageContent.para11}</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{pageContent.heading23}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>{pageContent.para23}</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </div>

                  <div className="col-md-6">
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{pageContent.heading12}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>{pageContent.para12}</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{pageContent.heading13}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>{pageContent.para13}</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{pageContent.heading14}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>{pageContent.para14}</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{pageContent.heading1}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>{pageContent.para15}</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{pageContent.heading16}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>{pageContent.para16}</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{pageContent.heading17}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>{pageContent.para17}</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{pageContent.heading18}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>{pageContent.para18}</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{pageContent.heading19}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>{pageContent.para19}</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{pageContent.heading20}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>{pageContent.para20}</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{pageContent.heading21}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>{pageContent.para21}</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{pageContent.heading22}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>{pageContent.para22}</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </div>
                </Accordion>
              </div>
            </div>

            <div className="row helpsection">
              <div className="col-md-6">
                <h3>{pageContent.helpHeading}</h3>
                <p>{pageContent.helpPara}</p>
              </div>

              <div className="col-md-6 text-center">
                <Link to="/">gethelp@dayofduel.com</Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default Faqs;
