import React, { useEffect, useState } from "react";
import "./forget.css";
// import logo from "../../assets/images/header/logo.png";
import { Form, Button } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { userInstance, cmsInstance } from "../../axios/axiosconfig";
import { validateData } from "../../function/function";

const INITIAL_STATE = {
  email: "",
};

function Forgetpass() {
  const [values, setValues] = useState(INITIAL_STATE);
  const [pageContent, setPageContent] = useState({});

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const getContent = async () => {
    try {
      // const page = window.location.pathname.replace('/cms/', '');
      const page = "/forgetpassword";
      const language = localStorage.getItem("activeLanguage") ? localStorage.getItem("activeLanguage") : localStorage.getItem("i18nextLng");
      const {
        data: { content, msg },
        status,
      } = await cmsInstance.get(`/content${page}/${language}`);
      if (status === 200) {
        setPageContent(content);
      } else {
        NotificationManager.error(msg, "Message", 10000);
      }
    } catch (error) {
      console.error("Error", error.message);
    }
  };

  useEffect(() => {
    getContent();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const isValid = validateData([values.email]);
    if (isValid) {
      const response = await userInstance.post("/resetpassword", values);
      const statusCode = response.data.code;
      if (statusCode === 200) {
        setValues(INITIAL_STATE);
        NotificationManager.success(pageContent.notification2, "Message", 4000);
      } else {
        NotificationManager.error(pageContent.notification3, "Message", 4000);
      }
    } else {
      NotificationManager.error(pageContent.notification1, "Message", 4000);
    }
  };

  return (
    <div className="forget">
      <div className="forget-section">
        <div className="container">
          <div classNmae="row">
            <div className="col-md-12">
              <div className="forget-form">
                <h1>
                  {pageContent.heading1}
                  <span>{pageContent.heading2}</span>
                </h1>
                <div className="forget-box">
                  <Form onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label>{pageContent.para1}</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder={pageContent.para3}
                        name="email"
                        onChange={handleChange}
                        value={values.email}
                      />
                    </Form.Group>
                    <div className="forget-button">
                      <Button
                        type="submit"
                        className="red-btn"
                      >
                        {pageContent.para2}
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Forgetpass;
