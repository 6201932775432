import React, { useEffect, useState } from "react";
import "./login.css";
// import logo from "../../assets/images/header/logo.png";
import { NotificationManager } from "react-notifications";
import { Form, Button, Modal } from "react-bootstrap";
import { validateData } from "../../function/function";
import { userInstance, cmsInstance } from "../../axios/axiosconfig";
import Facebook from "../facebook";
import Google from "../google";
import Signup from "../signup/Signup.style";
import Forgetpass from "../forget/forget";

const INITIAL_STATE = {
  email: "",
  password: "",
};

function Login() {
  const [values, setValues] = useState(INITIAL_STATE);
  const [errPassword, setErrPassword] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [pageContent, setPageContent] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getContent = async () => {
    try {
      // const page = window.location.pathname.replace('/cms/', '');
      const page = "/login";
      const language = localStorage.getItem("activeLanguage") ? localStorage.getItem("activeLanguage") : localStorage.getItem("i18nextLng");
      const {
        data: { content, msg },
        status,
      } = await cmsInstance.get(`/content${page}/${language}`);
      if (status === 200) {
        setPageContent(content);
        setIsLoading(true);
      } else {
        NotificationManager.error(msg, "Message", 10000);
      }
    } catch (error) {
      console.error("Error", error.message);
    }
  };

  useEffect(() => {
    getContent();
  }, []);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const tokenparser = (token) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        // eslint-disable-next-line comma-dangle
        .join("")
    );
    return JSON.parse(jsonPayload);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const isValid = validateData([values.email, values.password]);
    if (isValid) {
      try {
        const response = await userInstance.post("/login", values);
        const statusCode = response.data.code;
        if (statusCode === 400) {
          setErrEmail(pageContent.errEmail2);
        } else if (statusCode === 401) {
          setErrEmail(pageContent.errEmail3);
          setErrPassword(pageContent.errPassword1);
        } else if (statusCode === 403) {
          setErrEmail(pageContent.errEmail4);
        } else {
          localStorage.setItem("token", response.data.token);
          const payload = tokenparser(response.data.token);
          localStorage.setItem("Userid", payload.userid);
          const path = localStorage.getItem("lastlocation");
          window.location.href = `${path}`;
        }
      } catch (error) {
        console.error("error", error);
      }
    } else {
      if (values.email === "") {
        setErrEmail(pageContent.errEmail1);
      } else {
        const atposition = values.email.indexOf("@");
        const dotposition = values.email.lastIndexOf(".");
        if (atposition < 1 || dotposition < atposition + 2 || dotposition + 2 >= values.email.length) {
          setErrEmail(pageContent.errEmail);
        } else {
          setErrEmail("");
        }
      }
      if (values.password === "") {
        setErrPassword(pageContent.errPassword);
      }
    }
  };

  const [signupshow, setSignupShow] = useState(false);
  const handleSignupClose = () => setSignupShow(false);

  const [forgetshow, setForgetShow] = useState(false);
  const handleForgetClose = () => setForgetShow(false);

  return (
    isLoading && (
      <div className="login">
        <div className="login-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="login-form">
                  <h1>
                    {pageContent.loginon}
                    <span>{pageContent.dayofduel}</span>
                  </h1>

                  <div className="social-login">
                    <span className="loginBtn loginBtn--facebook">
                      <Facebook btntext={pageContent.fbbtntext} />
                    </span>
                    <span className="loginBtn loginBtn--google">
                      <Google btnText={pageContent.ggbtntext} />
                    </span>
                  </div>

                  <div className="login-box">
                    <Form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group controlId="formBasicloginOne">
                            <Form.Label>{pageContent.emailaddress}</Form.Label>
                            {errEmail === "" ? (
                              <Form.Control
                                type="text"
                                placeholder={pageContent.youremail}
                                name="email"
                                onChange={handleChange}
                                value={values.email}
                              />
                            ) : (
                              <Form.Control
                                type="text"
                                placeholder="Your e-mail"
                                name="email"
                                className="showError"
                                onChange={handleChange}
                                value={values.email}
                              />
                            )}
                            <label
                              className="text-danger"
                              htmlFor="id"
                            >
                              {errEmail}
                            </label>
                          </Form.Group>
                        </div>

                        <div className="col-md-6">
                          <Form.Group controlId="formBasicPassword">
                            <Form.Label>{pageContent.password}</Form.Label>
                            {errPassword === "" ? (
                              <Form.Control
                                type="password"
                                placeholder={pageContent.yourpassword}
                                name="password"
                                onChange={handleChange}
                                value={values.password}
                              />
                            ) : (
                              <Form.Control
                                type="password"
                                className="showError"
                                placeholder="8 Characters with Alpha Numeric and Symbols"
                                name="password"
                                onChange={handleChange}
                                value={values.password}
                              />
                            )}
                            <label
                              className="text-danger"
                              htmlFor="id"
                            >
                              {errPassword}
                            </label>
                          </Form.Group>
                        </div>
                      </div>

                      <div className="login-button">
                        <Button
                          type="submit"
                          className="red-btn"
                        >
                          {pageContent.login}
                        </Button>
                      </div>

                      <div className="forget-password">
                        {pageContent.para1}
                        <button
                          type="button"
                          className="forget-link"
                          onClick={setForgetShow}
                        >
                          {pageContent.para2}
                          <span>{">>"}</span>
                        </button>
                      </div>
                    </Form>
                    <p>
                      {pageContent.para3}
                      <button
                        type="button"
                        className="signup-link"
                        onClick={setSignupShow}
                      >
                        {pageContent.signup}
                        <span>{">>"}</span>
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          dialogClassName="dod-popup dark-popup"
          show={signupshow}
          onHide={handleSignupClose}
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <Signup />
          </Modal.Body>
        </Modal>

        <Modal
          dialogClassName="dod-popup dark-popup"
          show={forgetshow}
          onHide={handleForgetClose}
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <Forgetpass />
          </Modal.Body>
        </Modal>
      </div>
    )
  );
}

export default Login;
