import React from "react";
import "./howitworks.css";

function InfoBox({ src, header, paragraph1, paragraph2, paragraph3 }) {
  return (
    <div className="info-box">
      <img
        src={src}
        alt="match"
      />
      <div className="info-list">
        {header && <h4>{header}</h4>}
        {paragraph1 && <p>{paragraph1}</p>}
        {paragraph2 && <p>{paragraph2}</p>}
        {paragraph3 && <p>{paragraph3}</p>}
      </div>
    </div>
  );
}

export default InfoBox;
