import styled from "styled-components";
import EditProfile from "./EditProfile";

const EditProfileStyled = styled(EditProfile)`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .wrapper {
    width: 400px;
  }

  h1 {
    font-size: ${({ theme }) => theme.fontSizes.h1};
    text-align: center;
    font-family: ${({ theme }) => theme.fonts.molot};
    color: ${({ theme }) => theme.colors.white};
  }

  p {
    font-size: ${({ theme }) => theme.fontSizes.p};
    color: ${({ theme }) => theme.colors.grey};
    text-align: center;
    margin-bottom: 40px;
  }
`;

export default EditProfileStyled;
