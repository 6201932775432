import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import pong from "../../assets/images/home/pong-placeholder.png";
import pongmobile from "../../assets/images/home/pong-mobile.png";
import fifa from "../../assets/images/home/wintergame.png";
import ChooseFriend from "../choooseduel/choosefriend";
import ChooseGame from "../choooseduel/choosegame";
import agejumpers from "../../assets/images/home/agejumpers.jpg";
import golf from "../../assets/images/home/golf.jpg";
import skifly from "../../assets/images/home/skifly.jpg";
import bijatyka from "../../assets/images/home/TEMPLATE-SKOKI+ANGRY+Golf+Bijatyka.jpg";
import speedway from "../../assets/images/home/featureGraphic.png";
import { server } from "../../config/config";
import PopularGameBox from "../common/PopularGameBox/PopularGameBox";
import PopularMobileGameBox from "../common/PopularMobileGameBox/PopularMobileGameBox";
import Routes from "../../config/routes";
import { createGameUrl } from "../../helpers/game-helpers";

function PopularGames({ content, socket, setBet, setGameType }) {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [view, setViewShow] = useState(false);
  const [pcount, setpcount] = useState(0);
  const [wcount, setwcount] = useState(0);

  useEffect(() => {
    socket.emit("onlinePlayer");
    socket.on("playercount", (data) => {
      setpcount(data.pong);
      setwcount(data.winter);
    });
  });
  const handleViewShow = () => setViewShow(true);

  const handleClose = () => setShow(false);
  const handleViewClose = () => setViewShow(false);

  const checkLogin = (game) => {
    if (localStorage.getItem("token")) {
      setBet(true);
      setGameType(game);
    } else {
      history.push(Routes.Login);
    }
  };

  return (
    <div className="popular-game-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="title">
              <h2>{content && content.games}</h2>
            </div>
          </div>
          <PopularGameBox
            index="one"
            imageSrc={pong}
            tryButtonOnClick={() => history.push(createGameUrl("pong"))}
            count={pcount}
            content={content}
            duelButtonClick={() => checkLogin("Pong")}
            tournamentButtonClick={handleViewShow}
          />
          <PopularMobileGameBox
            index="two"
            imageSrc={pongmobile}
            count={pcount}
            content={content}
            playstoreURL={`${server}/user/appDownload`}
            appstoreURL="https://testflight.apple.com/join/URzIgwRg"
          />
          <PopularGameBox
            index="three"
            imageSrc={fifa}
            tryButtonOnClick={() => history.push(createGameUrl("wintergame"))}
            count={wcount}
            content={content}
            duelButtonClick={() => checkLogin("Winter Game")}
            tournamentButtonClick={handleViewShow}
          />
          <PopularGameBox
            index="three"
            imageSrc={agejumpers}
            tryButtonOnClick={() => history.push(createGameUrl("wintergame"))}
            count={wcount}
            content={content}
            duelButtonClick={() => checkLogin("Winter Game")}
            tournamentButtonClick={handleViewShow}
          />
          <PopularGameBox
            index="three"
            imageSrc={golf}
            tryButtonOnClick={() => history.push(createGameUrl("wintergame"))}
            count={wcount}
            content={content}
            duelButtonClick={() => checkLogin("Winter Game")}
            tournamentButtonClick={handleViewShow}
          />
          <PopularGameBox
            index="three"
            imageSrc={skifly}
            tryButtonOnClick={() => history.push(createGameUrl("wintergame"))}
            count={wcount}
            content={content}
            duelButtonClick={() => checkLogin("Winter Game")}
            tournamentButtonClick={handleViewShow}
          />
          <PopularGameBox
            index="three"
            imageSrc={bijatyka}
            tryButtonOnClick={() => history.push(createGameUrl("wintergame"))}
            count={wcount}
            content={content}
            duelButtonClick={() => checkLogin("Winter Game")}
            tournamentButtonClick={handleViewShow}
          />
          <PopularGameBox
            index="three"
            imageSrc={speedway}
            tryButtonOnClick={() => history.push(createGameUrl("wintergame"))}
            count={wcount}
            content={content}
            duelButtonClick={() => checkLogin("Winter Game")}
            tournamentButtonClick={handleViewShow}
          />
        </div>
      </div>

      <Modal
        dialogClassName="dod-popup"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <ChooseFriend socket={socket} />
        </Modal.Body>
      </Modal>

      <Modal
        dialogClassName="dod-popup"
        show={view}
        onHide={handleViewClose}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <ChooseGame socket={socket} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PopularGames;
