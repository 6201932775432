import styled from "styled-components";
import SignupForm from "./Signup";

const SignupFormStyled = styled(SignupForm)`
  max-width: 700px;
  margin: 75px auto;

  h1 {
    font-size: ${({ theme }) => theme.fontSizes.h1};
    text-align: center;
    font-family: ${({ theme }) => theme.fonts.molot};
    color: ${({ theme }) => theme.colors.white};
  }

  .loginBtn {
    box-sizing: border-box;
    position: inline;
    margin: 0.2em;
    padding: 14px 50px;
    border: none;
    text-align: left;
    line-height: 34px;
    white-space: nowrap;
    border-radius: 0.2em;
    font-size: ${({ theme }) => theme.fontSizes.button};
    color: ${({ theme }) => theme.colors.white};
  }
  .loginBtn:before {
    content: "";
    box-sizing: border-box;
    position: inline;
    top: 0;
    left: 0;
    width: 34px;
    height: 100%;
  }
  .loginBtn:focus {
    outline: none;
  }
  .loginBtn:active {
    box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
  }

  .form-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    row-gap: 30px;
  }

  .form-row > div {
    width: 45%;
  }

  .form-checkboxes {
    margin-top: 40px;
  }
`;

export default SignupFormStyled;
