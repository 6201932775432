import React from "react";
import "./profile.css";

function Row({ div1, div2 }) {
  return (
    <div className="row mb-3">
      <div className="col-md-5 text-muted">{div1}</div>
      <div className="col-md-7">{div2}</div>
    </div>
  );
}
export default Row;
