import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import "./tournament.css";
import { userInstance, cmsInstance } from "../../axios/axiosconfig";
import socket from "../../config/socket";
import Layout from "../layout";
import Routes from "../../config/routes";

function TournamentPage() {
  const history = useHistory();
  const { i18n } = useTranslation();
  const [tournament, setTournament] = useState([]);
  const [createTournament, setCreateTournament] = useState(false);
  const [pageContent, setPageContent] = useState({});
  const [page, setPage] = useState(1);
  const [finishTournament, setFinishTournament] = useState([]);
  // const [JoinStatus, setJoinStatus] = useState(true);

  const getContent = async () => {
    try {
      // const page = window.location.pathname.replace('/cms/', '');

      const language = localStorage.getItem("activeLanguage") ? localStorage.getItem("activeLanguage") : localStorage.getItem("i18nextLng");
      const {
        data: { content, msg },
        status,
      } = await cmsInstance.get(`/content${page}/${language}`);
      if (status === 200) {
        setPageContent(content);
      } else {
        NotificationManager.error(msg, "Message", 10000);
      }
    } catch (error) {
      console.error("Error", error.message);
    }
  };

  const getTournaments = async (pageValue, paginationValue) => {
    setPage(pageValue);
    const filterBy = "";
    const p = pageValue || 1;

    const payload = {
      data: filterBy,
      page: p,
      pagination: paginationValue,
    };
    const response = await userInstance.post("/getTournamentsUser", payload);
    if (response.data.code === 200) {
      const newData = response.data.data.filter((ele) => ele.tournamentEnd);
      const tournamentData = response.data.data.filter((ele) => !ele.tournamentEnd);
      setFinishTournament(newData);
      setTournament(tournamentData);
    }
  };

  const getEligible = async () => {
    const res = await userInstance.get("/eligible");
    if (res.data.code === 200) {
      setCreateTournament(res.data.eligible);
    }
  };
  const onLanguageChanged = () => {
    getContent();
  };

  useEffect(() => {
    getContent();
    getEligible();
    getTournaments(1, 5);
    i18n.on("languageChanged", onLanguageChanged);
  }, []);

  const JoinTournament = async (tid) => {
    const payload = {
      tournamentId: tid,
      socketId: socket.id,
    };
    const res = await userInstance.post("/joinTournament", payload);
    if (res.data.code === 200) {
      const Tournament = [...tournament];
      const index = tournament.findIndex((ele) => ele._id === tid);
      // let obj = tournament[index];
      //  obj.joinStatus=true;
      Tournament[index].joinStatus = true;
      setTournament(Tournament);

      // setBtnStatus(result);
      NotificationManager.success(pageContent.notification1, "Message", 4000);
    } else if (res.data.code === 401) {
      NotificationManager.error(pageContent.notification2, "Message", 4000);
    } else if (res.data.code === 301) {
      NotificationManager.error(pageContent.notification3, "Message", 4000);
    } else if (res.data.code === 302) {
      NotificationManager.error(pageContent.notification4, "Message", 4000);
    } else if (res.data.code === 303) {
      NotificationManager.error(pageContent.notification5, "Message", 4000);
    } else if (res.data.code === 304) {
      NotificationManager.error(pageContent.notification6, "Message", 4000);
    } else {
      NotificationManager.error("Error in Join", "Message", 4000);
    }
  };

  const tournamentcreation = () => {
    if (createTournament) {
      history.push(Routes.CreateTournament);
    } else {
      NotificationManager.error(pageContent.notification7, "Message", 4000);
    }
  };

  return (
    <Layout>
      <section className="tournament-page">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-page">
                <ul className="breadcrum">
                  <li>
                    <Link to="/">{pageContent.home}</Link>
                  </li>
                  <li>{pageContent.tournament}</li>
                </ul>
                <h2>{pageContent.tournament}</h2>
                {createTournament ? (
                  <Button
                    className="red-btn"
                    onClick={tournamentcreation}
                  >
                    {pageContent.createtournament}
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="home-table">
                <div className="responsive-table">
                  <h3>{pageContent.upcoming}</h3>
                  <Table
                    bordered
                    responsive
                  >
                    <thead>
                      <tr>
                        <th>{pageContent.game}</th>
                        <th>{pageContent.date}</th>
                        <th>{pageContent.time}</th>
                        <th>{pageContent.entryfee}</th>
                        <th>{pageContent.winprize}</th>
                        <th>{pageContent.players}</th>
                        <th>{pageContent.joinnow}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tournament &&
                        tournament.map((value) => (
                          <tr key={value}>
                            <td>{value.title}</td>
                            <td>{value.tournamentStart.substring(0, 10)}</td>
                            <td>{new Date(value.tournamentStart).toLocaleTimeString()}</td>
                            <td>
                              {value.tournamentEntryFee}
                              {" USD"}
                            </td>
                            <td>
                              {value.amt.length === 3 ? (
                                <>
                                  {" "}
                                  {`1st :${value.amt[0]}`}
                                  {`USD, ${"&nbsp"}; 2nd: ${value.amt[1]}`}
                                  {`USD, ${"&nbsp"}; 3rd: ${value.amt[2]}`}
                                  {" USD"}
                                </>
                              ) : value.amt.length === 2 ? (
                                <>
                                  {`1st :${value.amt[0]}`}
                                  {`USD, ${"&nbsp"}; 2nd: ${value.amt[1]}`}
                                  {" USD"}
                                </>
                              ) : (
                                <>
                                  {value.amt[0]}
                                  {" USD"}
                                </>
                              )}
                            </td>
                            <td>{value.playerNumbers}</td>
                            <td>
                              {!value.joinStatus ? (
                                <button
                                  type="button"
                                  className="btn"
                                  onClick={() => JoinTournament(value._id)}
                                >
                                  {pageContent.join}
                                  <span>&gt;&gt;</span>
                                </button>
                              ) : (
                                pageContent.joined
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
                <div className="pagination-section">
                  {page > 1 ? (
                    <button
                      type="button"
                      onClick={() => getTournaments(page - 1, 5)}
                      className="previous"
                    >
                      {pageContent.previous}
                    </button>
                  ) : (
                    ""
                  )}
                  {tournament.length === 5 ? (
                    <button
                      type="button"
                      onClick={() => getTournaments(page + 1, 5)}
                      className="next"
                    >
                      {pageContent.next}
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-12 finished-tournament">
              <div className="home-table">
                <div className="responsive-table">
                  <h3>{pageContent.finished}</h3>
                  <Table
                    bordered
                    responsive
                  >
                    <thead>
                      <tr>
                        <th>{pageContent.game}</th>
                        <th>{pageContent.entryfee}</th>
                        <th>{pageContent.participents}</th>
                        <th>{pageContent.winner}</th>
                        <th>{pageContent.prize}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {finishTournament &&
                        finishTournament.map((value) => (
                          <tr key={value}>
                            <td>
                              <b>{value.title}</b>
                            </td>
                            <td>
                              {" "}
                              {value.tournamentEntryFee}
                              {" USD"}
                            </td>
                            <td>{value.playerJoined.length}</td>
                            <td className="winner">
                              {value.winner.length === 3 ? (
                                <>
                                  {`1st : ${value.winner[0]}`}
                                  {`USD, 2nd: ${value.winner[1]}`}
                                  {`USD, 3rd: ${value.winner[2]}`}
                                  {" USD"}
                                </>
                              ) : value.winner.length === 2 ? (
                                <>
                                  {`1st : ${value.winner[0]}`}
                                  {`2nd : ${value.winner[1]}`}
                                </>
                              ) : (
                                value.winner[0]
                              )}
                            </td>
                            <td className="prize">
                              {value.amt.length === 3 ? (
                                <>
                                  {" "}
                                  {`1st :${value.amt[0]}`}
                                  {`USD, ${"&nbsp"}; 2nd: ${value.amt[1]}`}
                                  {`USD, ${"&nbsp"}; 3rd: ${value.amt[2]}`}
                                  {" USD"}
                                </>
                              ) : value.amt.length === 2 ? (
                                <>
                                  {`1st :${value.amt[0]}`}
                                  {`USD, ${"&nbsp"}; 2nd: ${value.amt[1]}`}
                                  {" USD"}
                                </>
                              ) : (
                                <>
                                  {value.amt[0]}
                                  {" USD"}
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      {finishTournament.length === 0 ? (
                        <tr style={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
                          <td />
                          <td colSpan="4">{pageContent.noTournament}</td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default TournamentPage;
