import React from "react";

export interface CheckBoxInterface {
  type: "checkbox";
  label: string;
  id: string;
  name: string; // name has to be the same as formik value key
  value: boolean;
  onChange: (e: React.ChangeEvent) => void;
  error: string;
}

interface CheckBoxProps {
  item: CheckBoxInterface;
  className?: string;
}

function CheckBox({ className = "", item }: CheckBoxProps) {
  const { label, name, id, onChange, error } = item;
  return (
    <div className={className}>
      <div>
        <input
          type="checkbox"
          name={name}
          id={id}
          onChange={onChange}
        />
        <label htmlFor={id}>{label}</label>
      </div>
      <p className="error">{error}</p>
    </div>
  );
}

export default CheckBox;
