import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import "./howitworks.css";
import Layout from "../layout";
import match from "../../assets/images/howitworks/icon-match.png";
import fight from "../../assets/images/howitworks/icon-fight.png";
import payout from "../../assets/images/howitworks/icon-payout.png";
import royal from "../../assets/images/howitworks/icon-royal.png";
import date from "../../assets/images/howitworks/icon-date.png";
import waiting from "../../assets/images/howitworks/icon-waiting.png";
import { cmsInstance, userInstance } from "../../axios/axiosconfig";
import InfoBox from "./InfoBox";
import Routes from "../../config/routes";
import { createGameUrl } from "../../helpers/game-helpers";

function HowItWorks() {
  const history = useHistory();
  const { i18n } = useTranslation();
  const [pageContent, setPageContent] = useState({});

  const loadRoom = async () => {
    const { data } = await userInstance.post("/createRoom");
    const { roomId } = data;
    if (roomId) {
      history.push(createGameUrl("pong", roomId));
    } else {
      history.push(Routes.Login);
    }
  };
  const getContent = async () => {
    try {
      const language = localStorage.getItem("activeLanguage");
      const {
        data: { content, msg },
        status,
      } = await cmsInstance.get(`/content/howitwork/${language}`);
      if (status === 200) {
        setPageContent(content);
      } else {
        NotificationManager.error(msg, "Message", 10000);
      }
    } catch (error) {
      console.error("Error", error.message);
    }
  };

  const onLanguageChanged = () => {
    getContent();
  };
  useEffect(() => {
    getContent();
    i18n.on("languageChanged", onLanguageChanged);
  }, []);

  return (
    <Layout>
      <div className="how-it-works-page">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-page">
                <ul className="breadcrum">
                  <li>
                    <Link to="/">{pageContent.home}</Link>
                  </li>
                  <li>{pageContent.heading1}</li>
                </ul>
                <h2>{pageContent.heading1}</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="how-main-box">
          <div className="container">
            <div className="how-box border-line">
              <div className="row">
                <div className="col-md-5">
                  <div className="left-content">
                    <p>{pageContent.para}</p>
                    <p>{pageContent.para1}</p>
                    <p className="span-content">{pageContent.para2}</p>

                    <p>{pageContent.para3}</p>
                    <ul>
                      <li>{pageContent.label1}</li>
                      <li>{pageContent.label2}</li>
                      <li>{pageContent.label3}</li>
                      <li>{pageContent.label4}</li>
                      <li>{pageContent.label5}</li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-7">
                  <div className="right-content">
                    <h2>{pageContent.heading2}</h2>
                    <p className="duel-description">{pageContent.dueldesc}</p>

                    <InfoBox
                      src={match}
                      header={pageContent.heading3}
                      paragraph1={pageContent.newpara1}
                      paragraph2={pageContent.para4}
                      paragraph3={pageContent.newpara2}
                    />
                    <InfoBox
                      src={fight}
                      header={pageContent.heading4}
                      paragraph1={pageContent.para5}
                    />
                    <InfoBox
                      src={payout}
                      header={pageContent.heading5}
                      paragraph1={pageContent.para6}
                      paragraph2={pageContent.newpara3}
                    />
                    <Button
                      onClick={loadRoom}
                      className="red-btn"
                    >
                      {pageContent.startduel}
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className="how-box border-line">
              <div className="row">
                <div className="col-md-5">
                  <div className="left-content">
                    <p className="textright">{pageContent.contentthird}</p>
                    <div className="info-box-new1">
                      <h4 className="textright-heading">{pageContent.contentfourth1}</h4>
                      <p className="textright">{pageContent.contentfourth}</p>
                    </div>
                  </div>
                </div>

                <div className="col-md-7">
                  <div className="right-content">
                    <h2>{pageContent.tournament}</h2>
                    <InfoBox
                      src={match}
                      header={pageContent.checkin}
                      paragraph1={pageContent.checkinpara}
                    />
                    <InfoBox
                      src={fight}
                      header={pageContent.heading4}
                      paragraph1={pageContent.fight2para}
                    />
                    <InfoBox
                      src={payout}
                      header={pageContent.heading5}
                      paragraph1={pageContent.payoutpara}
                    />

                    <Button className="blue-btn">
                      <Link
                        to="/tournament"
                        className="search-tournament"
                      >
                        {pageContent.searchtournament}
                      </Link>
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className="how-box">
              <div className="row">
                <div className="col-md-5">
                  <div className="left-content">
                    <div className="info-box-new">
                      <div className="info-list">
                        <h4>{pageContent.setpriceleft}</h4>
                        <p>{pageContent.setpriceleftpara1}</p>
                        <p>{pageContent.setpriceleftpara2}</p>
                        <p>{pageContent.setpriceleftpara3}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-7">
                  <div className="right-content">
                    <h2>{pageContent.newtournament}</h2>
                    <InfoBox
                      src={royal}
                      header={pageContent.royalggamer}
                      paragraph1={pageContent.royalgamer}
                    />
                    <InfoBox
                      src={payout}
                      header={pageContent.setprice}
                      paragraph1={pageContent.setpricepara1}
                      paragraph2={pageContent.setpricepara2}
                    />
                    <InfoBox
                      src={date}
                      header={pageContent.setdate}
                      paragraph1={pageContent.setdatepara}
                      paragraph2={pageContent.setdatepara1}
                    />
                    <InfoBox
                      src={waiting}
                      header={pageContent.waiting}
                      paragraph1={pageContent.waitingpara}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default HowItWorks;
