import styled from "styled-components";
import Button from "./Button";

const ButtonStyled = styled(Button)`
  text-transform: uppercase;
  transition: all 0.3s ease;
  padding: 12px 15px !important;
  font-size: ${({ theme }) => theme.fontSizes.button};
  line-height: 1.5em;
  border-radius: 3px;
  font-weight: 400 !important;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.white};
  border: 1px solid transparent;
  background: ${({ theme }) => theme.colors.red};
  display: block;
  margin: auto;

  :hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.red};
    border-color: ${({ theme }) => theme.colors.red};
    box-shadow: 0px 0px 50px 0px #ffffff9c;
    outline: none;
  }

  .btn:not(:disabled) {
    cursor: pointer;
  }
`;

export default ButtonStyled;
