import React, { useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import Slider from "react-slick";
import signup from "../../assets/images/home/signup-icon.png";
import checkin from "../../assets/images/home/checkin-icon.png";
import fight from "../../assets/images/home/fight-icon.png";
import payout from "../../assets/images/home/payout-icon.png";
import HowToTournamentBox from "./HowToTournamentBox";
import Routes from "../../config/routes";

function HowTournament({ content, loadRoom }) {
  const history = useHistory();
  const slider = useRef(null);

  const next = () => {
    slider.current.slickNext();
  };
  const previous = () => {
    slider.current.slickPrev();
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="how-tournament-section">
      <div className="align-tournament">
        <Slider
          ref={slider}
          {...settings}
        >
          <div className="duel-bg-slide">
            <div className="container">
              <Link
                to="/"
                onClick={next}
                className="next-link"
              >
                {content && content.tournament}
                <span>{">>"}</span>
              </Link>
              <div className="row">
                <div className="col-md-12">
                  <div className="title">
                    {/* <h2>How to Duel</h2> */}
                    <h2>{content && content.title1}</h2>
                  </div>
                </div>

                <div className="col-md-3" />

                <HowToTournamentBox
                  src={checkin}
                  header={content && content.match}
                  paragraph={content && content.opponent}
                />
                <HowToTournamentBox
                  src={fight}
                  header={content && content.fight}
                  paragraph={content && content.battle}
                />
                <HowToTournamentBox
                  src={payout}
                  header={content && content.payout}
                  paragraph={content && content.reward}
                />

                <div className="col-md-3" />

                <div className="col-md-12">
                  <div className="how-btn">
                    <Button
                      onClick={loadRoom}
                      className="red-btn"
                    >
                      {content && content.startplaying}
                    </Button>
                    <Button
                      className="border-btn"
                      onClick={() => history.push(Routes.HowItWorks)}
                    >
                      {content && content.details}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="tournament-bg-slide">
            <div className="container">
              <Link
                to="/"
                onClick={previous}
                className="prev-link"
              >
                <span>{"<<"}</span>
                {content && content.duel}
              </Link>
              <div className="row">
                <div className="col-md-12">
                  <div className="title">
                    <h2>{content && content.title2}</h2>
                  </div>
                </div>

                <div className="col-md-2" />
                <HowToTournamentBox
                  src={signup}
                  header={content && content.signup}
                  paragraph={content && content.para2}
                />
                <HowToTournamentBox
                  src={checkin}
                  header={content && content.checkin}
                  paragraph={content && content.para3}
                />
                <HowToTournamentBox
                  src={fight}
                  header={content && content.fight2}
                  paragraph={content && content.para4}
                />
                <HowToTournamentBox
                  src={payout}
                  header={content && content.payout2}
                  paragraph={content && content.para5}
                />

                <div className="col-md-2" />

                <div className="col-md-12">
                  <div className="how-btn">
                    <Button
                      className="blue-btn"
                      onClick={() => history.push(Routes.Tournament)}
                    >
                      {content && content.searchtournament}
                    </Button>
                    <Button
                      className="border-btn"
                      onClick={() => history.push(Routes.HowItWorks)}
                    >
                      {content && content.details}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
}

export default HowTournament;
