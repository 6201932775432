import React, { useEffect, useState } from "react";
import "./choose.css";
import { Form, Button } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { userInstance, adminInstance, cmsInstance } from "../../axios/axiosconfig";
import Popup from "../home/popup";
import Bet from "../home/bet";
import { server } from "../../config/config";

function ChooseFriend({ socket }) {
  const { i18n } = useTranslation();
  const [game, setGame] = useState(null);
  const [matched, setMatched] = useState(false);
  const [bet, setBet] = useState(false);
  const Userid = localStorage.getItem("Userid");
  const [startTimer, setstartTimer] = useState(false);
  const [betAmount, setBetAmount] = useState(null);
  const [wallettype, setWallettype] = useState(null);
  const [playerOne, setPlayerOne] = useState(null);
  const [playerTwo, setPlayerTwo] = useState(null);
  const [roomId, setRoomId] = useState(null);
  const [level, setLevel] = useState(null);
  const [nickName, setNickName] = useState(null);
  const [country, setCountry] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [pageContent, setPageContent] = useState({});
  const [choosefriend, setchoosefriend] = useState("Choose Friend");
  const [friend, setFriend] = useState([]);
  const [userid, setUserId] = useState("");
  const [onlinefriend, setOnlineFriend] = useState(false);

  const handleTimerPopup = (e) => {
    if (e === "close") {
      setstartTimer(false);
      // socket.emit("cancel", {
      //   Userid
      // });
    }
  };

  const loadRoom = async () => {
    const { data } = await userInstance.post("/createRoom");
    const roomID = data.roomId;
    return roomID;
    //  if(roomId && game === 'Pong'){
    //   window.location.href =`/pong?roomId=${roomId}`;
    //  }else if(roomId){
    //   window.location.href =`/wintergame?roomId=${roomId}`;
    //  }
  };

  const normalMatch = async () => {
    if (game) {
      const roomID = await loadRoom();
      socket.emit("normalMatch", {
        userId: userid,
        gameType: game,
        roomId: roomID,
      });
    }
  };

  const getContent = async () => {
    try {
      // const page = window.location.pathname.replace('/cms/', '');
      const page = "/choosefriend";
      const language = localStorage.getItem("activeLanguage") ? localStorage.getItem("activeLanguage") : localStorage.getItem("i18nextLng");
      const {
        data: { content, msg },
        status,
      } = await cmsInstance.get(`/content${page}/${language}`);
      if (status === 200) {
        setPageContent(content);
        setGame(pageContent.label1);
      } else {
        NotificationManager.error(msg, "Message", 10000);
      }
    } catch (error) {
      console.error("Error", error.message);
    }
  };

  const getFriends = async () => {
    const res = await userInstance.get("/friendlist");
    if (res.data.code === 200) {
      setFriend(res.data.friend);
    }
  };

  const handleBetAmount = () => {
    setBet(true);
  };
  const CloseBetComponent = () => {
    setBet(false);
  };

  const getUserProfile = async () => {
    const response = await userInstance.get("/getprofile");
    if (response.data.code === 200) {
      setLevel(response.data.profile_data.Level);
      setPlayerOne(response.data.profile_data);
      setNickName(response.data.profile_data.nickName);
      setCountry(response.data.profile_data.country);
      setPhoto(response.data.profile_data.photo);
    }
  };

  const onLanguageChanged = () => {
    getContent();
  };

  useEffect(() => {
    getContent();
    getUserProfile();
    getFriends();
    i18n.on("languageChanged", onLanguageChanged);
  }, []);

  const matchRoom = async (amount, walletType) => {
    const res = await adminInstance.post("/getCommission");
    const cutOff = ((res.data.rate * amount) / 100).toFixed(2);
    // const response = await userInstance.post("/getprofile");
    socket.emit("matchMaking", {
      Userid,
      level,
      socketId: socket.id,
      bet: amount,
      adminCutOff: cutOff,
      betAmt: amount - cutOff,
      walletType,
      nickName,
      country,
      photo,
      gameType: game,
    });
    setBetAmount(amount);
    setBet(false);
    setWallettype(walletType);
    setMatched(true);
  };

  useEffect(() => {
    // socket.emit("disconnect", {
    //   Userid
    // });
    socket.on("playerPopUp", (data) => {
      if (Userid === data.matched[0].Userid) {
        setPlayerOne(data.matched[0]);
        setPlayerTwo(data.matched[1]);
      } else {
        setPlayerOne(data.matched[1]);
        setPlayerTwo(data.matched[0]);
      }
    });

    socket.on("winterplay", (data) => {
      setRoomId(data.roomId);
      setstartTimer(false);
      handleTimerPopup("close");
      setMatched(true);
    });
    socket.on("play", (data) => {
      // loadRoom();
      setRoomId(data.roomId);
      setstartTimer(false);
      handleTimerPopup("close");
      setMatched(true);
      // setBet(true);

      socket.emit("server", {
        data,
      });

      socket.emit("betAmount", () => {
        setBet(false);
      });
    });
  }, [startTimer]);

  // const handleSubmit = async() => {
  //     game = "WinterGame";
  //         const roomId = await loadRoom();
  //         window.location.href =`/pong?roomId=${roomId}`;

  // }

  const handlefriendselect = (userID, i) => {
    setchoosefriend(i);
    setUserId(userID);
    setOnlineFriend(true);
  };

  const handleSelect = (i) => {
    setGame(i);
  };

  return (
    <div className="choose-friend">
      {matched && (
        <Popup
          roomId={roomId}
          setMatched={setMatched}
          playerOne={playerOne}
          playerTwo={playerTwo}
          socket={socket}
          amount={betAmount}
          wallettype={wallettype}
          gameType={game}
        />
      )}

      {bet && (
        <Bet
          socket={socket}
          matchRoom={matchRoom}
          CloseBetComponent={CloseBetComponent}
        />
      )}

      <div className="choose-friend-content">
        <h2>{pageContent.heading1}</h2>

        <Form onSubmit={loadRoom}>
          <Form.Group>
            {/* <Form.Label>{pageContent.label1}</Form.Label>
                    <Form.Control id="choose-game" as="select" name="game"
                     onChange={handleSelect} defaultValue={game}>
                                                            <option>{pageContent.choosegame}</option>
                                                            <option>{pageContent.label2}</option>
                                                            <option>{pageContent.label3}</option>
                                                            <option>{pageContent.label4}</option>
                                                        </Form.Control> */}
            <DropdownButton
              id="choose-game"
              title={game}
            >
              <Dropdown.Item onClick={() => handleSelect(pageContent.label2)}>{pageContent.label2}</Dropdown.Item>
              <Dropdown.Item onClick={() => handleSelect(pageContent.label3)}>{pageContent.label3}</Dropdown.Item>
              <Dropdown.Item onClick={() => handleSelect(pageContent.label4)}>{pageContent.label4}</Dropdown.Item>
            </DropdownButton>
          </Form.Group>

          <Form.Group>
            <Form.Label>{pageContent.label5}</Form.Label>

            <DropdownButton
              id="choose-friend"
              title={choosefriend}
            >
              {friend &&
                friend.map((ele) => {
                  if (!ele.online || !ele.online.isActive) return;
                  return (
                    <Dropdown.Item onClick={() => handlefriendselect(ele._id, ele.nickName)}>
                      <img
                        src={`${server}/${ele.photo}`}
                        alt=""
                      />
                      {ele.nickName}
                    </Dropdown.Item>
                  );
                })}
            </DropdownButton>
          </Form.Group>

          <div className="choose-button">
            <Button
              type="button"
              onClick={handleBetAmount}
              className="red-btn"
            >
              {pageContent.searchuser}
            </Button>
            {onlinefriend ? (
              <Button
                type="button"
                onClick={normalMatch}
                className="blue-btn"
              >
                {pageContent.choose_friend}
              </Button>
            ) : (
              ""
            )}
          </div>
        </Form>
      </div>
    </div>
  );
}

export default ChooseFriend;
