import React from "react";
import { useHistory } from "react-router-dom";
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from "react-google-login";
// import { GoogleclientId } from "../../config/keys";
import { userInstance } from "../../axios/axiosconfig";
import { GoogleclientId } from "../../config/config";
import Routes from "../../config/routes";

interface GoogleProps {
  btnText: string;
}

function Google({ btnText }: GoogleProps) {
  const history = useHistory();
  const responseGoogle = async (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    try {
      const guser = await userInstance.post("/googlelogin", response);
      if (guser) {
        localStorage.setItem("token", guser.data.token);
        localStorage.setItem("Userid", guser.data.id);
        history.push(Routes.Home);
      }
      return guser;
    } catch (e) {
      return 0;
    }
  };
  if (!GoogleclientId) return <div>Not Found</div>;
  return (
    <GoogleLogin
      clientId={GoogleclientId}
      buttonText={btnText}
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      cookiePolicy="single_host_origin"
      autoLoad={false}
    />
  );
}

export default Google;
