import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./deposit.css";
import { Form, Button, InputGroup } from "react-bootstrap";
import querString from "query-string";
import { NotificationManager } from "react-notifications";
import Alert from "react-bootstrap/Alert";
import { cmsInstance, userInstance } from "../../axios/axiosconfig";
import { convertAmountToOtherCurrencies } from "../../function/function";
import Routes from "../../config/routes";

function Deposit() {
  const history = useHistory();
  const currency = localStorage.getItem("currency");
  const [amount, setAmount] = useState(null);
  const [showStatus, setShowStatus] = useState(null);
  const [pageContent, setPageContent] = useState({});
  const [errMsg, setErrMsg] = useState("");

  const getContent = async () => {
    try {
      // const page = window.location.pathname.replace('/cms/', '');
      const page = "/addmoney";
      const language = localStorage.getItem("activeLanguage") ? localStorage.getItem("activeLanguage") : localStorage.getItem("i18nextLng");
      const {
        data: { content, msg },
        status,
      } = await cmsInstance.get(`/content${page}/${language}`);
      if (status === 200) {
        setPageContent(content);
      } else {
        NotificationManager.error(msg, "Message", 10000);
      }
    } catch (error) {
      console.error("Error", error.message);
    }
  };

  useEffect(() => {
    const paymentStatus = querString.parse(window.location.search);
    if (paymentStatus.payment === "success" || paymentStatus.payment === "fail") {
      setShowStatus(paymentStatus.payment);
      setTimeout(() => {
        history.push(Routes.Home);
      }, 4000);
    }
    getContent();
  }, []);

  const payment = async (e) => {
    e.preventDefault();
    if (amount !== null) {
      const result = await convertAmountToOtherCurrencies(amount, currency);
      const payload = {
        totalPrice: result,
      };
      const res = await userInstance.post("/payment", payload);
      if (res.data.code === 200) {
        history.push(res.data.data);
      }
    } else {
      setErrMsg(pageContent.notification1);
    }
  };

  const handlechange = (e) => {
    setAmount(e.target.value);
  };

  return (
    <div className="deposit">
      <div className="container">
        <div className="deposite-content">
          <h2>{pageContent.heading1}</h2>

          <div className="deposit-p">
            {showStatus && showStatus === "success" ? (
              <Alert
                variant="success"
                className="succ-box"
              >
                <Alert.Heading>{pageContent.heading2}</Alert.Heading>
                <p>{pageContent.redirect}</p>
              </Alert>
            ) : (
              ""
            )}
            {showStatus && showStatus === "fail" ? (
              <Alert
                variant="danger"
                className="succ-box"
              >
                <Alert.Heading>{pageContent.heading3}</Alert.Heading>
                <p>{pageContent.redirect}</p>
              </Alert>
            ) : (
              ""
            )}
          </div>
          <Form onSubmit={payment}>
            <Form.Group>
              <label htmlFor="Amount">{pageContent.label1}</label>
              <InputGroup className="mb-3">
                {errMsg === "" ? (
                  <Form.Control
                    type="number"
                    autocomplete="off"
                    className="deposit-in"
                    value={amount}
                    id="Amount"
                    onChange={handlechange}
                    min={1}
                  />
                ) : (
                  <Form.Control
                    type="number"
                    autocomplete="off"
                    className="deposit-in"
                    value={amount}
                    id="Amount"
                    onChange={handlechange}
                    min={1}
                  />
                )}
                <InputGroup.Append>
                  <InputGroup.Text id="basic-addon2">{currency}</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
              <p style={{ color: "white" }}>{errMsg}</p>
              {/* <Form.Group controlId='formBasicloginone'>
              <Form.Label htmlFor='Amount'>{content.label1}</Form.Label>
              {errMsg === '' ? (
                <Form.Control
                  type='number'
                  autocomplete='off'
                  className='deposit-in'
                  value={amount}
                  id='Amount'
                  onChange={handlechange}
                  min={1}
                />
              ) : (
                <>
                  <Form.Control
                    type='number'
                    autocomplete='off'
                    className='deposit-in'
                    value={amount}
                    id='Amount'
                    onChange={handlechange}
                    min={1}
                  />
                  <p style={{ color: 'white' }}>{errMsg}</p>
                </>
              )}
            </Form.Group> */}

              <div className="text-center">
                <Button
                  type="submit"
                  className="red-btn"
                >
                  {pageContent.label2}
                </Button>
              </div>
            </Form.Group>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Deposit;
