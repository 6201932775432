/* eslint-disable react/jsx-filename-extension */
import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import { ThemeProvider } from "styled-components";
import baseTheme from "./themes";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./components/home/home";
import Login from "./components/login/login";
import SignupFormStyled from "./components/signup/Signup.style";
import Forgetpass from "./components/forget/forget";
import Resetpassword from "./components/forget/reset";
import history from "./config/history";
import Legal from "./components/legal/legal";
import Terms from "./components/legal/terms";
import Privacy from "./components/legal/privacy";
import Faqs from "./components/faqs/faqs";
import HowItWorks from "./components/howitworks/howitworks";
import TournamentPage from "./components/tournament/tournamentpage";
import Runningduels from "./components/runningduels/runningduels";
import Features from "./components/features/features";
import Populargames from "./components/populargames/populargames";
import Profile from "./components/profile/profile";
import AboutUs from "./components/aboutus/aboutus";
import EditProfileStyled from "./components/editprofile/EditProfile.style";
import OurTeam from "./components/ourteam/ourteam";
import OurVision from "./components/ourvision/ourvision";
import Help from "./components/help/help";
import Payment from "./components/payments/payment";
import TransactionSecurity from "./components/transactionsecurity/transactionsecurity";
import ResponsibleGaming from "./components/responsiblegaming/responsiblegaming";
import Deposit from "./components/deposit/deposit";
import Tournamentdetails from "./components/tournamentdetails/tournamentdetails";
import Notificationpage from "./components/notificationpage/notificationpage";
import UserTransaction from "./components/transaction/transaction";
import WithdrawMoney from "./components/withdrawMoney/withdrawMoney";
import Friend from "./components/friend/index";
// import PayPal from "./components/paypal/paypal";
import "react-notifications/lib/notifications.css";
import Pong from "./components/pong/game";
import Test from "./components/test/test";
import ChangePassword from "./components/changepassword/changepassword";
import CreateTournament from "./components/createtournament/createtournament";
import KYC from "./components/kyc/kyc";
import socket from "./config/socket";
import EnterTournament from "./components/tournament/entertournament";
import SeriesGame from "./components/seriesgame";
import WinterGame from "./components/pong/winterGame";

import "./responsive.css";

export function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") ? (
          <Component
            {...props}
            {...rest}
          />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

export function AdminPrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        /admintoken/.test(document.cookie) ? (
          <Component
            {...props}
            {...rest}
          />
        ) : (
          <Redirect to="/admin/login" />
        )
      }
    />
  );
}

export function AdminCMSPrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        /admintoken/.test(document.cookie) ? (
          <Component
            {...props}
            {...rest}
          />
        ) : (
          <Redirect to="/cms/login" />
        )
      }
    />
  );
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // adminValid: isAuthAdmin(),
    };
  }

  render() {
    return (
      <div className="App">
        <ThemeProvider theme={baseTheme}>
          <NotificationContainer />
          <Suspense fallback={null}>
            <Router history={history}>
              <Switch>
                <Route
                  exact
                  path="/"
                  component={() => <Home socket={socket} />}
                />
                <Route
                  exact
                  path="/login"
                  component={() => <Login />}
                />
                <Route
                  exact
                  path="/signup"
                  component={() => <SignupFormStyled />}
                />
                <Route
                  exact
                  path="/forget"
                  component={() => <Forgetpass />}
                />

                {/* <Route exact path="/paypal" component={PayPal} /> */}
                <Route
                  exact
                  path="/test"
                  component={Test}
                />

                <Route
                  path="/legal"
                  exact
                  component={Legal}
                />

                <Route
                  path="/terms"
                  exact
                  component={Terms}
                />
                <Route
                  path="/privacy"
                  exact
                  component={Privacy}
                />

                <Route
                  path="/howitworks"
                  exact
                  component={HowItWorks}
                />
                <PrivateRoute
                  path="/tournament"
                  exact
                  component={TournamentPage}
                />
                <Route
                  path="/runningduels"
                  exact
                  component={Runningduels}
                />
                <Route
                  path="/features"
                  exact
                  component={Features}
                />
                <Route
                  path="/faqs"
                  exact
                  component={Faqs}
                />
                <Route
                  exact
                  path="/populargames"
                  component={() => <Populargames socket={socket} />}
                />
                <PrivateRoute
                  path="/profile"
                  exact
                  component={Profile}
                />
                <PrivateRoute
                  path="/friend"
                  exact
                  component={Friend}
                />
                <PrivateRoute
                  path="/seriesGame"
                  exact
                  component={() => <SeriesGame socket={socket} />}
                />
                <Route
                  path="/about"
                  exact
                  component={AboutUs}
                />
                <Route
                  path="/editprofile"
                  exact
                  component={EditProfileStyled}
                />
                <Route
                  exact
                  path="/changepassword"
                  component={() => <ChangePassword />}
                />
                <Route
                  path="/ourvision"
                  exact
                  component={OurVision}
                />
                <Route
                  path="/ourteam"
                  exact
                  component={OurTeam}
                />
                <Route
                  path="/help"
                  exact
                  component={Help}
                />
                <Route
                  path="/payment"
                  exact
                  component={Payment}
                />
                <Route
                  path="/transactionsecurity"
                  exact
                  component={TransactionSecurity}
                />
                <Route
                  path="/responsiblegaming"
                  exact
                  component={ResponsibleGaming}
                />
                <Route
                  exact
                  path="/reset/:hex"
                  component={() => <Resetpassword />}
                />
                <Route
                  exact
                  path="/createtournament"
                  component={() => <CreateTournament />}
                />
                <Route
                  exact
                  path="/deposit"
                  component={() => <Deposit />}
                />
                <Route
                  exact
                  path="/kyc"
                  component={() => <KYC />}
                />
                <PrivateRoute
                  exact
                  path="/transactions"
                  component={() => <UserTransaction />}
                />
                <PrivateRoute
                  exact
                  path="/withdrawMoney"
                  component={() => <WithdrawMoney />}
                />
                <Route
                  exact
                  path="/enterTournament"
                  component={() => <EnterTournament />}
                />
                <Route
                  path="/tournamentdetails"
                  exact
                  component={Tournamentdetails}
                />
                <Route
                  path="/notificationpage"
                  exact
                  component={Notificationpage}
                />
                <PrivateRoute
                  exact
                  path="/pong"
                  component={() => <Pong socket={socket} />}
                />
                <PrivateRoute
                  exact
                  path="/winterGame"
                  component={() => <WinterGame socket={socket} />}
                />
                <Route
                  path="*"
                  component={() => <div>Page not found</div>}
                />
              </Switch>
            </Router>
          </Suspense>
        </ThemeProvider>
      </div>
    );
  }
}

export default App;
