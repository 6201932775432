/* eslint-disable no-return-assign */
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./populargames.css";
import { Modal } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import Layout from "../layout";
import pong from "../../assets/images/home/pong-placeholder.png";
import fifa from "../../assets/images/home/wintergame.png";
import { userInstance, adminInstance, cmsInstance } from "../../axios/axiosconfig";
import pongmobile from "../../assets/images/home/pong-mobile.png";
import Bet from "../home/bet";
import Popup from "../home/popup";
import multiplayer from "../../assets/images/home/multiplayer.png";
import { server } from "../../config/config";
import PopularGameBox from "../common/PopularGameBox/PopularGameBox";
import PopularMobileGameBox from "../common/PopularMobileGameBox/PopularMobileGameBox";
import ComingSoonGameBox from "./ComingSoonGameBox";
import Routes from "../../config/routes";

let message;
let nomatch;

function Populargames({ socket }) {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [view, setViewShow] = useState(false);
  const { i18n } = useTranslation();
  const [pageContent, setPageContent] = useState({});
  const [level, setLevel] = useState(null);
  const [nickName, setNickName] = useState(null);
  const [country, setCountry] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [startTimer, setstartTimer] = useState(false);
  const [matched, setMatched] = useState(false);
  const [roomId, setRoomId] = useState(null);
  const Userid = localStorage.getItem("Userid");
  const [playerOne, setPlayerOne] = useState(null);
  const [playerTwo, setPlayerTwo] = useState(null);

  const [bet, setBet] = useState(false);
  const [betAmount, setBetAmount] = useState(null);
  const [wallettype, setWallettype] = useState(null);

  const [gametype, setGameType] = useState("Pong");

  const handleViewShow = () => setViewShow(true);

  const handleClose = () => setShow(false);
  const handleViewClose = () => setViewShow(false);

  const CloseBetComponent = () => {
    setBet(false);
  };

  const getUserProfile = async () => {
    const response = await userInstance.get("/getprofile");
    if (response.data.code === 200) {
      setLevel(response.data.profile_data.Level);
      setPlayerOne(response.data.profile_data);
      setNickName(response.data.profile_data.firstName);
      setCountry(response.data.profile_data.country);
      setPhoto(response.data.profile_data.photo);
    }
  };

  const getContent = async () => {
    try {
      const page = window.location.pathname.replace("/cms/", "");
      const { language } = i18n;
      const {
        data: { content, msg },
        status,
      } = await cmsInstance.get(`/content${page}/${language}`);
      if (status === 200) {
        setPageContent(content);
        message = pageContent.message;
        nomatch = pageContent.nomatch;
      } else {
        NotificationManager.error(msg, "Message", 10000);
      }
    } catch (error) {
      console.error("Error", error.message);
    }
  };

  const onLanguageChanged = () => {
    getContent();
  };
  useEffect(() => {
    getContent();
    getUserProfile();
    i18n.on("languageChanged", onLanguageChanged);
  }, []);

  const matchRoom = async (amount, walletType) => {
    const res = await adminInstance.post("/getCommission");
    const cutOff = ((res.data.rate * amount) / 100).toFixed(2);
    // const response = await userInstance.post("/getprofile");

    socket.emit("matchMaking", {
      Userid,
      level,
      socketId: socket.id,
      bet: amount,
      adminCommission: cutOff,
      betAmt: amount - cutOff,
      walletType,
      nickName,
      country,
      photo,
      gameType: gametype,
      platform: "web",
    });

    setBetAmount(amount);
    setBet(false);
    setWallettype(walletType);
    setMatched(true);
  };
  const handleTimerPopup = (e) => {
    if (e === "close") {
      setstartTimer(false);
      // socket.emit("cancel", {
      //   Userid
      // });
    }
  };

  useEffect(() => {
    // socket.emit("disconnect", {
    //   Userid
    // });
    socket.on("playerPopUp", (data) => {
      if (Userid === data.matched[0].Userid) {
        setPlayerOne(data.matched[0]);
        setPlayerTwo(data.matched[1]);
      } else {
        setPlayerOne(data.matched[1]);
        setPlayerTwo(data.matched[0]);
      }
    });

    socket.on("play", (data) => {
      // loadRoom();
      // setPlayerDetails(data.matched);
      setRoomId(data.roomId);
      setstartTimer(false);
      handleTimerPopup("close");
      setMatched(true);
      // setBet(true);

      socket.emit("server", {
        data,
      });

      socket.emit("betAmount", () => {
        setBet(false);
      });
    });
    socket.on("winterplay", (data) => {
      // loadRoom();
      // setPlayerDetails(data.matched);
      setRoomId(data.roomId);
      setstartTimer(false);
      handleTimerPopup("close");
      setMatched(true);
      // setBet(true);

      socket.emit("server", {
        data,
      });

      socket.emit("betAmount", () => {
        setBet(false);
      });
    });
    socket.on("noMatch", () => {
      NotificationManager.error(nomatch, message);
      setMatched(false);
    });
  }, [startTimer]);

  const checkLogin = (game) => {
    if (localStorage.getItem("token")) {
      setBet(true);
      setGameType(game);
    } else {
      history.push(Routes.Login);
    }
  };

  return (
    <Layout>
      {matched && (
        <Popup
          roomId={roomId}
          setMatched={setMatched}
          playerOne={playerOne}
          playerTwo={playerTwo}
          socket={socket}
          amount={betAmount}
          wallettype={wallettype}
          gameType={gametype}
        />
      )}

      {bet && (
        <Bet
          socket={socket}
          matchRoom={matchRoom}
          CloseBetComponent={CloseBetComponent}
        />
      )}

      <div className="popular-game-bg">
        <div className="popular-game-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="title-page">
                  <ul className="breadcrum">
                    <li>
                      <Link to="/">{pageContent.home}</Link>
                    </li>
                    <li>{pageContent.games}</li>
                  </ul>
                  <h2>{pageContent.games}</h2>
                </div>
              </div>
              <div className="col-md-12">
                <div className="game-tile">
                  <div className="home-tile-box">
                    <div className="home-tile-info">
                      <span>{pageContent.play}</span>
                      <h4>{pageContent.poolgame}</h4>

                      <h2>100$</h2>
                    </div>
                  </div>
                </div>
              </div>
              <PopularGameBox
                imageSrc={pong}
                content={pageContent}
                duelButtonClick={() => checkLogin("Pong")}
                tournamentButtonClick={handleViewShow}
              />
              <PopularMobileGameBox
                index="two"
                imageSrc={pongmobile}
                content={pageContent}
                playstoreURL={`${server}/user/appDownload`}
                appstoreURL="https://testflight.apple.com/join/URzIgwRg"
              />
              <PopularGameBox
                imageSrc={fifa}
                content={pageContent}
                multiplayer={multiplayer}
                duelButtonClick={() => checkLogin("Winter Game")}
                tournamentButtonClick={handleViewShow}
              />
              <ComingSoonGameBox pageContent={pageContent} />
              <ComingSoonGameBox pageContent={pageContent} />
              <ComingSoonGameBox pageContent={pageContent} />
              <ComingSoonGameBox pageContent={pageContent} />
              <ComingSoonGameBox pageContent={pageContent} />
            </div>
          </div>
        </div>
      </div>

      <Modal
        dialogClassName="dod-popup"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <Bet />
        </Modal.Body>
      </Modal>

      <Modal
        dialogClassName="dod-popup"
        show={view}
        onHide={handleViewClose}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <Bet />
        </Modal.Body>
      </Modal>
    </Layout>
  );
}

export default Populargames;
