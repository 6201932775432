import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useFormik } from "formik";
import * as Yup from "yup";
import countryList from "react-select-country-list";
import { userInstance } from "../../axios/axiosconfig";
import { getPageContent } from "../../helpers/content";
import PageContent from "./pageContent.type";

import InputStyled from "../common/formComponents/input/Input.style";
import SelectInputStyled from "../common/formComponents/selectInput/SelectInput.style";
import ButtonStyled from "../common/button/Button.style";
import { InputInterface } from "../common/formComponents/input/Input";
import { SelectInterface } from "../common/formComponents/selectInput/SelectInput";

interface FormState {
  firstName: string;
  nickName: string;
  country: string;
  email: string;
}

const INITIAL_STATE = {
  firstName: "",
  nickName: "",
  country: "",
  email: "",
};

interface EditProfileProps {
  className?: string;
}

function EditProfile({ className }: EditProfileProps) {
  const [initialValues, setInitialValues] = useState(INITIAL_STATE);
  const [pageContent, setPageContent] = useState<PageContent>();

  const getProfile = async () => {
    const response = await userInstance.get("/getprofile");
    if (response.data.code === 200) {
      const profileData = response.data.profile_data;
      setInitialValues({
        ...initialValues,
        firstName: profileData.firstName,
        nickName: profileData.nickName,
        country: profileData.country,
        email: profileData.email,
      });
    }
  };

  useEffect(() => {
    const page = "/editprofile";
    const language = localStorage.getItem("activeLanguage") || localStorage.getItem("i18nextLng");
    if (!language) return;
    getPageContent(language, page).then((data) => setPageContent(data));
    getProfile();
  }, []);

  const onFormSubmit = async (values: FormState) => {
    const payload = {
      firstName: values.firstName,
      nickName: values.nickName,
      country: values.country,
      email: values.email,
    };
    const res = await userInstance.post("/updateProfile", payload);
    if (res.data.code === 200) {
      NotificationManager.success(pageContent?.notification1, "Message", 5000);
      return;
    }
    NotificationManager.error(pageContent?.notification2, "Message", 5000);
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().min(3, "Too short"),
    nickName: Yup.string().min(3, "Too short"),
    country: Yup.string(),
    email: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      firstName: initialValues.firstName,
      nickName: initialValues.nickName,
      country: initialValues.country,
      email: initialValues.email,
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      onFormSubmit(values);
    },
  });

  const formInputs: (InputInterface | SelectInterface)[] = [
    {
      type: "text",
      label: pageContent?.label1 || "",
      id: "edit-profile-nickname",
      placeholder: pageContent?.placeholder1 || "",
      name: "nickName",
      value: formik.values.nickName,
      onChange: formik.handleChange,
      error: formik.errors.nickName || "",
    },
    {
      type: "text",
      label: pageContent?.name || "",
      id: "edit-profile-name",
      placeholder: pageContent?.placeholder2 || "",
      name: "firstName",
      value: formik.values.firstName,
      onChange: formik.handleChange,
      error: formik.errors.firstName || "",
    },
    {
      type: "email",
      label: pageContent?.label4 || "",
      id: "edit-profile-email",
      placeholder: pageContent?.label4 || "",
      name: "email",
      value: formik.values.email,
      onChange: formik.handleChange,
      error: formik.errors.email || "",
    },
    {
      type: "select",
      label: pageContent?.label5 || "",
      id: "edit-profile-country",
      placeholder: "",
      name: "country",
      value: formik.values.country,
      onChange: (option) => formik.setFieldValue("country", option.value),
      options: countryList().getData(),
      error: formik.errors.country || "",
    },
  ];

  const renderFormField = (input: InputInterface | SelectInterface) => {
    if (input.type === "select")
      return (
        <SelectInputStyled
          item={input}
          key={input.id}
        />
      );
    return (
      <InputStyled
        item={input}
        key={input.id}
      />
    );
  };

  if (!pageContent) return <div>Not Found</div>;
  return (
    <div className={className}>
      <div className="wrapper">
        <h1>{pageContent?.heading1}</h1>
        <p>{pageContent?.para1}</p>
        <form onSubmit={formik.handleSubmit}>
          <div className="form">{formInputs.map((input) => renderFormField(input))}</div>
          <ButtonStyled
            label={pageContent.submit}
            type="submit"
          />
        </form>
      </div>
    </div>
  );
}

export default EditProfile;
