import React from "react";
import { useTranslation } from "react-i18next";
import { userInstance } from "../../axios/axiosconfig";

export default function Test() {
  const { t } = useTranslation();

  const handlePayment = async () => {
    const payload = {
      totalPrice: 150,
    };
    const response = await userInstance.post("payment", payload);
    window.location.href = response.data.data;
  };

  return (
    <button
      type="button"
      onClick={handlePayment}
    >
      {t("test.dopayment")}
    </button>
  );
}
