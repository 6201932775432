import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import vs from "../../assets/images/home/vs-table.png";
import eye from "../../assets/images/home/eye-icon.png";
import { adminInstance } from "../../axios/axiosconfig";

function GameTable({ content }) {
  const { t } = useTranslation();
  const [livematches, setLiveMatches] = useState([]);

  const getLivematches = async () => {
    const res = await adminInstance.post("/gametable");
    if (res.data.code === 200) {
      setLiveMatches(res.data.matches);
    }
  };
  useEffect(() => {
    getLivematches();
  }, []);

  return (
    <div className="home-table">
      <div className="home-game-table">
        <div className="game-head">
          <div className="table-game">{content.game}</div>
          <div className="table-gamer">{content.gamers}</div>
          <div className="table-platform">{content.platform}</div>
          <div className="table-entry">{content.entryfee}</div>
          <div className="table-prize">{content.prize}</div>
        </div>
        {livematches &&
          livematches.length &&
          livematches.map((ele) => (
            <div
              className="game-list-view"
              key={ele._id}
            >
              <div className="table-game">
                {ele.gameName}
                <Link to="/">
                  <OverlayTrigger
                    placement="left"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="button-tooltip">Coming soon</Tooltip>}
                  >
                    <img
                      src={eye}
                      alt=""
                    />
                  </OverlayTrigger>
                </Link>
              </div>
              <div className="table-gamer">
                <span className="gamer-name">{ele.playersData.length ? ele.playersData[0].name : ""}</span>
                <span className="gamer-vs">
                  <img
                    src={vs}
                    alt=""
                  />
                </span>
                <span className="gamer-name">{ele.playersData.length === 2 ? ele.playersData[1].name : ""}</span>
              </div>
              <div className="table-platform">WEB</div>
              <div className="table-entry">{`${ele.betAmount}$`}</div>
              <div className="table-prize">{`${ele.betPot}$`}</div>
            </div>
          ))}
        <div className="game-list-view">
          <div className="table-game">
            Pong Game
            <Link to="/">
              <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="button-tooltip">Coming soon</Tooltip>}
              >
                <img
                  src={eye}
                  alt=""
                />
              </OverlayTrigger>
            </Link>
          </div>
          <div className="table-gamer">
            <span className="gamer-name">Abdigall</span>
            <span className="gamer-vs">
              <img
                src={vs}
                alt=""
              />
            </span>
            <span className="gamer-name">Bella</span>
          </div>
          <div className="table-platform">XBOX</div>
          <div className="table-entry">{`40${t("strings.currency")}`}</div>
          <div className="table-prize">{`72${t("strings.currency")}`}</div>
        </div>
        <div className="game-list-view">
          <div className="table-game">
            Pong Game
            <Link to="/">
              <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="button-tooltip">Coming soon</Tooltip>}
              >
                <img
                  src={eye}
                  alt=""
                />
              </OverlayTrigger>
            </Link>
          </div>
          <div className="table-gamer">
            <span className="gamer-name">Abdigall</span>
            <span className="gamer-vs">
              <img
                src={vs}
                alt=""
              />
            </span>
            <span className="gamer-name">Bella</span>
          </div>
          <div className="table-platform">XBOX</div>
          <div className="table-entry">{`50${t("strings.currency")}`}</div>
          <div className="table-prize">{`90${t("strings.currency")}`}</div>
        </div>
      </div>
    </div>
  );
}

export default GameTable;
