import React from "react";
import Slider from "react-slick";
import tile1 from "../../assets/images/home/tile-11.png";
import SliderImage from "./SliderImage";

const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

function HometileSlider({ content }) {
  return (
    <div className="home-tile-slider">
      <Slider {...settings}>
        <SliderImage
          src={tile1}
          span={content && content.now}
          headerOne={content && content.takefree}
          headerTwo={content && content.takeprice}
          tile={1}
        />
        <SliderImage
          src={tile1}
          span={content && content.now}
          headerOne={content && content.takefree}
          headerTwo={content && content.takeprice}
          tile={1}
        />
        <SliderImage
          src={tile1}
          span={content && content.now}
          headerOne={content && content.takefree}
          headerTwo={content && content.takeprice}
          tile={1}
        />
        <SliderImage
          src={tile1}
          span={content && content.now}
          headerOne={content && content.takefree}
          headerTwo={content && content.takeprice}
          tile={1}
        />
      </Slider>
    </div>
  );
}
export default HometileSlider;
