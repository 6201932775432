import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import "./choose.css";
import { Form, Button } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import fee1 from "../../assets/images/duel/fee-1.png";
import fee2 from "../../assets/images/duel/fee-2.png";
import fee3 from "../../assets/images/duel/fee-3.png";
import fee4 from "../../assets/images/duel/fee-4.png";
import fee5 from "../../assets/images/duel/fee-5.png";
import fee6 from "../../assets/images/duel/fee-6.png";
import fee7 from "../../assets/images/duel/fee-7.png";
import fee8 from "../../assets/images/duel/fee-8.png";
import { cmsInstance } from "../../axios/axiosconfig";

function ChooseGame() {
  const [pageContent, setPageContent] = useState({});
  const feeImages = [fee1, fee2, fee3, fee4, fee5, fee6, fee7, fee8];
  const getContent = async () => {
    try {
      // const page = window.location.pathname.replace('/cms/', '');
      const page = "/choosegame";
      const language = localStorage.getItem("activeLanguage") ? localStorage.getItem("activeLanguage") : localStorage.getItem("i18nextLng");
      const {
        data: { content, msg },
        status,
      } = await cmsInstance.get(`/content${page}/${language}`);
      if (status === 200) {
        setPageContent(content);
      } else {
        NotificationManager.error(msg, "Message", 10000);
      }
    } catch (error) {
      console.error("Error", error.message);
    }
  };
  useEffect(() => {
    getContent();
  }, []);
  return (
    <div className="choose-game">
      <div className="choose-game-content">
        <h2>{pageContent.heading1}</h2>

        <Form>
          <Form.Group className="max-300">
            <Form.Label>{pageContent.label1}</Form.Label>
            <DropdownButton
              id="choose-game"
              title="Choose game"
            >
              <Dropdown.Item href="/">{pageContent.label2}</Dropdown.Item>
              <Dropdown.Item href="/">{pageContent.label3}</Dropdown.Item>
              <Dropdown.Item href="/">{pageContent.label4}</Dropdown.Item>
            </DropdownButton>
          </Form.Group>

          <Form.Group className="clear">
            <Form.Label className="text-center">{pageContent.label5}</Form.Label>

            <div className="entry-fee">
              {feeImages.map((image) => (
                <div
                  className="entry-box"
                  key={image}
                >
                  <img
                    src={image}
                    alt="fee"
                  />
                  <span>{pageContent.label6}</span>
                </div>
              ))}
            </div>
          </Form.Group>

          <div className="choose-button">
            <Button
              type="submit"
              className="red-btn"
            >
              {pageContent.searchuser}
            </Button>
            <Button
              type="submit"
              className="blue-btn"
            >
              {pageContent.choose_friend}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default ChooseGame;
