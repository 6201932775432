import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./home.css";
import { cmsInstance } from "../../axios/axiosconfig";
import user from "../../assets/images/home/game-user.png";
import userimg from "../../assets/images/home/JEST69.jpg";
import userimg2 from "../../assets/images/home/znalezione.gif";
import { createGameUrl } from "../../helpers/game-helpers";

function Popup(props) {
  const history = useHistory();
  const { roomId, amount, gameType, playerOne, playerTwo, socket, setMatched } = props;
  const show = false;
  const [second, setSecond] = useState(10);
  const Userid = localStorage.getItem("Userid");
  const [pageContent, setPageContent] = useState({});
  let refsec = 10;
  let timer = "";

  const handleClose = () => {
    setMatched(false);
    socket.emit("cancel", { Userid });
  };

  const getContent = async () => {
    try {
      // const page = window.location.pathname.replace('/cms/', '');
      const page = "/popup";
      const language = localStorage.getItem("activeLanguage") ? localStorage.getItem("activeLanguage") : localStorage.getItem("i18nextLng");
      const {
        data: { content },
        status,
      } = await cmsInstance.get(`/content${page}/${language}`);
      if (status === 200) {
        setPageContent(content);
      }
    } catch (error) {
      console.error("Error", error.message);
    }
  };

  useEffect(() => {
    getContent();
  }, []);

  const Timer = () => {
    if (refsec === 1) {
      clearInterval(timer);
      // window.location.href = `/pong?roomId=${props.roomId}`
    }
  };

  const countdown = () => {
    timer = setInterval(() => {
      Timer();

      setSecond(refsec - 1);
      refsec -= 1;
    }, 1000);
  };
  const playerOneData = playerOne !== null ? playerOne : null;
  const playerTwoData = playerTwo !== null ? playerTwo : null;

  const forBetMoney = async () => {};

  useEffect(() => {
    if (roomId !== null) {
      forBetMoney();
      countdown();
      localStorage.setItem("betAmt", amount);
      if (gameType === "Winter Game") {
        setTimeout(() => {
          history.push(createGameUrl("wintergame", roomId));
        }, 10000);
      } else {
        setTimeout(() => {
          history.push(createGameUrl("pong", roomId));
        }, 10000);
      }
    }
  }, [playerOne, playerTwo, roomId, show]);

  if (playerTwoData) {
    localStorage.setItem("opponent", playerTwoData.nickName);
  }

  return (
    <Modal
      show
      onHide={handleClose}
      className="match-making-popup"
    >
      {playerTwoData !== null && (
        <div className="estimated-time">
          {" "}
          <div>
            <p>
              <span>{second}</span>
            </p>
          </div>
        </div>
      )}

      <Modal.Header closeButton>
        {/* closeButton> */}
        <Modal.Title>
          {pageContent.opponent}
          {playerTwoData !== null ? pageContent.found : pageContent.finding}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="match-making-popup-content">
        <div className="row">
          <div className="col-md-12">
            <p className="match-heading">{pageContent.require}</p>
            <p className="speed">{pageContent.please}</p>
            <p className="speed">{pageContent.net}</p>
          </div>
          <div className="col-2" />
          <div className="col-3">
            {playerOneData !== null ? (
              <div className="match-box dfs">
                <img
                  src={userimg}
                  alt=""
                />
                <h4 className="">
                  {playerOneData.nickName.substring(0, 6)}
                  {playerOneData.nickName.length >= 7 ? "..." : ""}
                </h4>
                {/* <p>{playerOneData.country}</p>
                <span>{playerOneData.level}</span> */}
                {/* <div className="user-pics">
                  <img src={`${server}/` + `${playerOneData.photo}`} className="player-img" />
                  <img src={`${server}/public/country/` + `${playerOneData.country}.png`} className="flag-pic" />
                </div> */}
              </div>
            ) : (
              <div className="match-box ">
                {/* <div className="user-pics">
                  <img src={random} className="player-img vsm" />
                  <img src={coinpot} className="player-img coinpot" />

                   <img src={`${server}/public/country/`+`${playerTwoData.country}.png`} className="flag-pic"/>
                </div> */}
                <h4 className="text-right">{pageContent.finding}</h4>
                {/* <p>{pageContent.finding}</p>
                <span>{pageContent.finding}</span> */}
              </div>
            )}
          </div>
          <div className="col-2 text-center bg857">
            <div className="wrapperr">
              <h1>VS</h1>
              {/* <div className="vs">
              <img src={vs} />
            </div> */}
              {/* <div className="coinpot-wrapper">
            <img src={coinpot} className="player-img coinpot" />
            </div> */}
            </div>
          </div>

          <div className="col-3">
            {playerTwoData !== null ? (
              <div className="match-box sdf">
                {/* <div className="user-pics">
                <img src={`${server}/` + `${playerTwoData.photo}`} className="player-img" />
                <img src={`${server}/public/country/` + `${playerTwoData.country}.png`} className="flag-pic" />
              </div> */}
                <img
                  src={userimg2}
                  className="userimg2"
                  alt=""
                />
                <h4 className="">
                  {playerTwoData.nickName ? playerTwoData.nickName.substring(0, 6) : "Player"}
                  {playerTwoData.nickName && playerTwoData.nickName.length >= 7 ? "..." : ""}
                </h4>
                {/* <p>{playerTwoData.country}</p>
              <span>{playerTwoData.level}</span> */}
              </div>
            ) : (
              <div className="match-box sdf">
                {/* <div className="user-pics">
                  <img src={random} className="player-img" />
                  <img src={`${server}/public/country/`+`${playerTwoData.country}.png`} className="flag-pic"/>
                </div> */}
                <div className="loading-image">
                  <img
                    src={user}
                    className="loading"
                    alt=""
                  />
                </div>

                <h4> Player</h4>
                {/* <p>{pageContent.finding}</p>
                <span>{pageContent.finding}</span> */}
              </div>
            )}
          </div>
          <div className="col-2" />
        </div>

        <div className="row">
          <div className="col-12">
            <div
              className="loader text-center"
              style={{ margin: "0 auto" }}
            >
              {/* <h4>{pageContent.loading}</h4> */}
              <h4>Player Matching in Progress</h4>
              <div className="loader-bar">
                <div className="loaderBar" />
              </div>
            </div>

            <div className="interval">
              {playerTwoData !== null && (
                <p className="link-go">
                  {" "}
                  <p>Winning Amount</p>
                  <p>{`${parseFloat(playerOneData.betAmt) + parseFloat(playerTwoData.betAmt)}`}</p>
                </p>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      {/* <div class="modal-footer">
        <button type="button" class="btn btn-primary" onClick={handleClose}>
          Close
        </button>
      </div> */}
    </Modal>
  );
}

export default Popup;
