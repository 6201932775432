import React from "react";

interface ButtonProps {
  type?: "submit" | "button";
  label: string;
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
}

function Button({ type, onClick, label, className }: ButtonProps) {
  return (
    <button
      className={className}
      type={type === "submit" ? "submit" : "button"}
      onClick={onClick}
    >
      {label}
    </button>
  );
}

export default Button;
