import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import queryString from "query-string";
// import { CANVAS } from "../../data/canvas";
import socket from "../../config/socket";
import { userInstance } from "../../axios/axiosconfig";
import { server } from "../../config/config";

interface GameState {
  promoted: boolean;
  nextRoundPromo: boolean;
  roomId: string | null;
  loserPanel: boolean;
  winnerPanel: boolean;
}

const DEFAULT_STATE = {
  promoted: false,
  nextRoundPromo: false,
  roomId: null,
  loserPanel: false,
  winnerPanel: false,
};

function WinterGame() {
  const [gameState, setGameState] = useState<GameState>(DEFAULT_STATE);

  const findRoomIdInArray = (roomId: string | (string | null)[]) => {
    if (typeof roomId === "string") return roomId;
    roomId.forEach((item: string | null) => {
      if (item) {
        return item;
      }
    });
    return null;
  };
  const getRoomId = () => {
    const values = queryString.parse(window.location.search);
    if (values.roomId) {
      localStorage.setItem("offline", values.offline ? "true" : "false");
      const foundRoomId = findRoomIdInArray(values.roomId);
      if (foundRoomId) localStorage.setItem("roomId", foundRoomId);
      localStorage.setItem("dodAdress", `${server}/socket.io/`);

      return values;
    }
  };
  useEffect(() => {
    socket.on("deniedConfirm", () => {
      window.location.href = "/";
    });
  }, []);

  const forBetMoney = async () => {
    const res = await userInstance.post("/deductRematchBet");
    return res;
  };

  const check = async (values: queryString.ParsedQuery<string>) => {
    const response = await userInstance.post("/checkRoom", values);
    return response;
  };

  useEffect(() => {
    const encodedData = localStorage.getItem("Userid");
    if (!encodedData) return;
    const values = getRoomId();
    if (values) {
      window.unityInstance = window.UnityLoader.instantiate("gameContainer", "Winter Web/Build/Winter Web.json");
    }
    socket.on("rematchBet", () => {
      forBetMoney();
    });
    // next Round Socket
    socket.on("nextRound", (data: { userId: string; roomId: string }) => {
      if (data.userId.toString() === encodedData.toString()) {
        setGameState({ ...gameState, nextRoundPromo: true, roomId: data.roomId });
      }
    });

    // Promoted to next Round
    socket.on("promotedPlayer", (data: { roomId: string }) => {
      setGameState({ ...gameState, promoted: true, roomId: data.roomId });
    });
    // start next Round
    socket.on("startNextRound", () => {
      if (gameState.roomId) {
        setTimeout(() => {
          // window.location.href = `/${this.state.gameType}?roomId=${this.state.roomId}`;
        }, 1000);
      } else {
        setGameState({ ...gameState, loserPanel: true });
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
      }
    });
    // winner Socket
    socket.on("Winner", () => {
      setGameState({ ...gameState, winnerPanel: true });
      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
    });

    const rid = values?.roomId;
    if (values) {
      check(values);
    }

    socket.on("welcome", () => {});
    socket.on("rematchShow", () => {});
    socket.on("connect", () => {});
    socket.on("players", () => {});
    socket.emit("stop", { roomId: rid });
    socket.on("request", () => {});
    socket.on("confirm", () => {
      window.location.reload();
    });
    socket.on("social", () => {});
  }, []);

  // async componentDidMount() {
  //   const encodedData = localStorage.getItem("Userid");
  //   const Userid = atob(encodedData);
  //   // this.setState({ Userid });
  //   const values = await this.getRoomId();
  //   if (values) {
  //     window.unityInstance = window.UnityLoader.instantiate("gameContainer", "Winter Web/Build/Winter Web.json");
  //   }
  //   socket.on("rematchBet", () => {
  //     this.forBetMoney();
  //   });
  //   // next Round Socket
  //   socket.on("nextRound", (data) => {
  //     if (data.userId.toString() === encodedData.toString()) {
  //       this.setState({ nextRoundPromo: true, roomId: data.roomId });
  //     }
  //   });

  //   // Promoted to next Round
  //   socket.on("promotedPlayer", (data) => {
  //     this.setState({ promoted: true, roomId: data.roomId });
  //   });
  //   // start next Round
  //   socket.on("startNextRound", () => {
  //     const { roomId } = this.state;
  //     if (roomId) {
  //       setTimeout(() => {
  //         // window.location.href = `/${this.state.gameType}?roomId=${this.state.roomId}`;
  //       }, 1000);
  //     } else {
  //       this.setState({ loserPanel: true });
  //       setTimeout(() => {
  //         window.location.href = "/";
  //       }, 1000);
  //     }
  //   });
  //   // winner Socket
  //   socket.on("Winner", () => {
  //     this.setState({ winnerPanel: true });
  //     setTimeout(() => {
  //       window.location.href = "/";
  //     }, 1000);
  //   });
  //   //  let encodedData = localStorage.getItem("Userid");

  //   const rid = values.roomId;
  //   if (values.tour_id) {
  //     this.check(values);
  //     // this.setState({ tournamentId: values.tour_id });
  //     // const players = response.data.players;
  //   }

  //   socket.on("welcome", (data) => {
  //     // this.setState({ handle: data.handle });
  //     const arr = data.roomdet.players;
  //     if (arr.length) {
  //       if (arr.length === 2) {
  //         this.setState({
  //           // player1: arr[0].name,
  //           // player2: arr[1].name,
  //           // player1id: arr[0].id,
  //           // player2id: arr[1].id,
  //         });
  //       }
  //       // this.setState({ player1: arr[0].name, player1id: arr[0].id });
  //       arr.forEach((ele) => {
  //         if (ele.id === Userid) {
  //           // this.setState({ handle: ele.handle });
  //         }
  //       });
  //     }
  //   });

  //   socket.on("rematchShow", () => {});
  //   socket.on("connect", () => {});
  //   socket.on("players", (data) => {
  //     const arr = data.response.players;
  //     if (arr.length) {
  //       if (arr.length === 2) {
  //         // this.setState({ player1: arr[0].name, player2: arr[1].name });
  //       }
  //       // this.setState({ player1: arr[0].name });

  //       arr.forEach((ele) => {
  //         if (ele.id === Userid) {
  //           // this.setState({ handle: ele.handle });
  //         }
  //       });
  //     }
  //   });

  //   socket.emit("stop", { roomId: rid });
  //   socket.on("request", () => {
  //     // this.setState({ rematch: true });
  //   });
  //   socket.on("confirm", () => {
  //     window.location.reload();
  //   });

  //   socket.on("social", () => {});
  // }

  // joinRoom = async () => {
  //   const values = this.getRoomId();
  //   if (values.roomId) {
  //     const token = localStorage.getItem("token");
  //     this.setState({
  //       play: false
  //     });
  //     let rid = values.roomId;
  //     socket.emit("room", {
  //       roomId: rid,
  //       token,
  //       Userid: this.state.Userid
  //     });
  //   }
  // };

  // Deduct amount from the user wallet----------------------------->

  return (
    <>
      <div
        id="gameContainer"
        style={{ height: "100vh" }}
      />
      <Modal
        show={gameState.nextRoundPromo}
        onHide={() => {
          setGameState({ ...gameState, nextRoundPromo: true });
        }}
      >
        <ModalBody>
          <div>
            <h1>Winner Promoted to Next Round</h1>
            <h3>Next Round will start after all game on round 1 is finished</h3>
            <p>Do not close this popup</p>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        show={gameState.promoted}
        onHide={() => {
          setGameState({ ...gameState, promoted: true });
        }}
      >
        <ModalBody>
          <div>
            <h1>Promoted to Next Round </h1>
            <h3>Next Round will start after all game on round 1 is finished</h3>
            <p>Do not close this popup</p>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        show={gameState.loserPanel}
        onHide={() => {
          setGameState({ ...gameState, loserPanel: true });
        }}
      >
        <ModalBody>
          <div>
            <h1>You Lose this Round </h1>
            <h3>Will redirect to Home page in 1 sec</h3>
            <p>Do not close this popup</p>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        show={gameState.winnerPanel}
        onHide={() => {
          setGameState({ ...gameState, winnerPanel: true });
        }}
      >
        <ModalBody>
          <div>
            <h1>Tournament Final Match Finished</h1>
            <h3>Will redirect to Home page in 1 sec</h3>
            <p>Do not close this popup</p>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default WinterGame;
