import React from "react";
import "./header.css";

function LogoutButton({ text, onClick }) {
  return (
    <button
      type="button"
      className="logout-btn dropdown-item"
      onClick={onClick}
    >
      {text}
    </button>
  );
}

export default LogoutButton;
