import React from "react";
import "./home.css";

function SliderImage({ src, spanOne, spanTwo, headerOne, headerTwo, tile }) {
  return (
    <div className={`home-slider-image tile-${tile}`}>
      <img
        src={src}
        alt="sliderimage"
      />
      <div className="home-tile-info">
        {spanOne && <span>{spanOne}</span>}
        {spanTwo && <span>{spanTwo}</span>}
        {headerOne && <h4>{headerOne}</h4>}
        {headerTwo && <h2>{headerTwo}</h2>}
      </div>
    </div>
  );
}

export default SliderImage;
