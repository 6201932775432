import React from "react";
import { useHistory } from "react-router-dom";
import FacebookLogin, { ReactFacebookLoginInfo } from "react-facebook-login";
import { NotificationManager } from "react-notifications";
// import { FacebookAppID } from "../../config/keys";
import { userInstance } from "../../axios/axiosconfig";
import { FacebookAppID } from "../../config/config";
import Routes from "../../config/routes";

interface FacebookProps {
  btntext: string;
}

function Facebook({ btntext }: FacebookProps) {
  const history = useHistory();
  const tokenparser = (token: string) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        // eslint-disable-next-line comma-dangle
        .join("")
    );
    return JSON.parse(jsonPayload);
  };
  const responseFacebook = async (response: ReactFacebookLoginInfo) => {
    if (response.email) {
      const guser = await userInstance.post("/fblogin", response);
      if (guser.data.code === 200) {
        localStorage.setItem("token", guser.data.token);
        const payload = tokenparser(guser.data.token);
        localStorage.setItem("Userid", payload.userid);
        history.push(Routes.Home);
      } else if (guser && guser.data.code !== 200) {
        NotificationManager.error("No Email in FB Plz login Manually", "Message", 4000);
      }
    }
  };
  if (!FacebookAppID) return <div>Not found</div>;
  return (
    <FacebookLogin
      appId={FacebookAppID}
      autoLoad={false}
      fields="name,email,picture"
      callback={responseFacebook}
      textButton={btntext}
    />
  );
}

export default Facebook;
