import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./userchat.css";
import { Spinner } from "react-bootstrap";
import socket from "../../config/socket";
import { userInstance } from "../../axios/axiosconfig";
import userpic from "../../assets/images/header/user-icon.png";
import adminpic from "../../assets/images/header/adminchat-logo1.png";
import Routes from "../../config/routes";

const Userid = localStorage.getItem("Userid");
const nickName = localStorage.getItem("nickName");

function UserAdminChat() {
  const history = useHistory();
  const [list, setList] = useState([]);
  const [message, setMessage] = useState("");
  const [adminId, setAdmin] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const messages = async () => {
    const admin = localStorage.getItem("admin");
    const payload = {
      adminId: admin,
    };
    const result = await userInstance.post("/fetchMessage", payload);
    setIsLoading(false);
    setList(result.data.data);
    if (result.data.data.length) {
      setTimeout(() => {
        const element = document.getElementById("scrollBottom");
        if (element.lastChild) {
          element.lastChild.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
          });
        }
      }, 1500);
    }
  };

  useEffect(() => {
    const adminDetail = async () => {
      const response = await userInstance.get("/adminDetail");
      if (response.data.code === 200) {
        localStorage.setItem("admin", response.data.admin[0]._id);
        setAdmin(response.data.admin[0]._id);
        messages();
      } else if (response.data.code === 400) {
        localStorage.clear();
        history.push(Routes.Login);
      }
    };
    adminDetail();
  }, []);

  const Scroll = () => {
    // e.preventDefault();

    setTimeout(() => {
      const element = document.getElementById("scrollBottom");
      if (element.lastChild) element.lastChild.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
    }, 500);
  };

  useEffect(() => {
    socket.on("admintouser", (data) => {
      if (Userid === data.to) {
        const arr = list;
        arr.push(data);
        setList([...arr]);
        Scroll();
      }
    });
  }, [list]);

  const handleSend = async (e) => {
    e.preventDefault();
    if (message === "") {
      return false;
    }
    const obj = {
      from: Userid,
      to: adminId,
      content: message,
      nickName,
      updatedAt: new Date().toISOString(),
      createdAt: new Date().toISOString(),
    };
    list.push(obj);
    Scroll();
    socket.emit("userMsg", obj);

    setMessage("");
  };

  return (
    <div className="userchat">
      <div className="container ">
        <div className="chat-box">
          <div>
            {isLoading ? (
              <Spinner animation="border" />
            ) : (
              <ul
                className="chat-log"
                id="scrollBottom"
              >
                {list &&
                  list.length > 0 &&
                  list.map((ele, i) => (
                    <div className="chatting">
                      <li className="chat-date">
                        {i > 0 && ele.updatedAt && list[i - 1].updatedAt.substring(0, 10) !== ele.updatedAt.substring(0, 10)
                          ? new Date(ele.updatedAt).getDay() - new Date().getDay() === 0
                            ? "Today"
                            : new Date(ele.updatedAt).getDay() - new Date().getDay() === 1
                            ? "Yesterday"
                            : ele.updatedAt && ele.updatedAt.substring(0, 10)
                          : i === 0
                          ? new Date(ele.updatedAt).getDay() - new Date().getDay() === 0
                            ? "Today"
                            : new Date(ele.updatedAt).getDay() - new Date().getDay() === 1
                            ? "Yesterday"
                            : ele.updatedAt && ele.updatedAt.substring(0, 10)
                          : ""}
                      </li>

                      <li className={ele.to !== Userid.toString() ? "chat-log__item chat-log__item--own" : "chat-log__item"}>
                        <div className="chat-user">
                          {ele.to !== Userid.toString() ? (
                            <img
                              src={userpic}
                              alt=""
                            />
                          ) : (
                            <img
                              src={adminpic}
                              className="admin-pic"
                              alt=""
                            />
                          )}
                        </div>
                        <div className="chat-log__message">
                          <p>{ele.content}</p>
                          <span>{ele.updatedAt && new Date(ele.updatedAt).toLocaleTimeString()}</span>
                        </div>
                      </li>
                    </div>
                  ))}
              </ul>
            )}
          </div>

          <div className="chat-form">
            <div className="chat-form-type">
              <form onSubmit={handleSend}>
                <input
                  type="text"
                  className="form-control"
                  id=""
                  value={message}
                  onChange={handleChange}
                  placeholder="Message"
                />
                <button
                  type="submit"
                  className="btn-type"
                >
                  <i className="fa fa-angle-right" />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserAdminChat;
